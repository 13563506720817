import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Mobile } from 'utils/responsive';
import Sidebar from './Sidebar';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickClose = () => {
    setIsOpen(false);
  };

  return (
    <Mobile>
      <div>
        <FontAwesomeIcon icon="bars" style={{ fontSize: '22' }} onClick={handleClickOpen} />

        <div id="menu" className={isOpen ? 'opened' : 'closed'}>
          <Sidebar handleClickClose={handleClickClose} handleClickOpen={handleClickOpen} />
        </div>
      </div>
    </Mobile>
  );
};

export default Menu;
