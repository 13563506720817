export const getNotificationIcon = kind => {
  if (kind === 'message') {
    return 'envelope';
  }
  if (kind === 'podcast_claimed') {
    return 'podcast';
  }
  if (kind === 'campaign_approved') {
    return 'bullhorn';
  }

  return 'microphone';
};

export const getIconColor = kind => {
  if (kind === 'message') {
    return '#ff5a5f';
  }
  if (kind === 'podcast_claimed') {
    return '#ffca00';
  }
  if (kind === 'campaign_approved') {
    return '#000';
  }

  return '#007a87';
};
