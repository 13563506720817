import React from 'react';
import PropTypes from 'prop-types';

import { formatPrice } from 'utils/forms';

const SponsorshipPreview = ({ sponsorship }) => {
  return (
    <div className="sponsorship-preview">
      <div>
        <span>Price:</span>
        {formatPrice(sponsorship.price)}
      </div>

      <div>
        <span>Start Date:</span>
        {sponsorship.start_date}
      </div>

      {sponsorship.description && (
        <div>
          <span>Description:</span>
          {sponsorship.description}
        </div>
      )}

      <div>
        <span>Advertiser Copy:</span>
        {sponsorship.advertiser_copy}
      </div>

      <div>
        <span>Ad Types:</span>
        {sponsorship.ad_type_list.join(', ')}
      </div>

      {sponsorship.advertiser_notes && (
        <div>
          <span>Advertiser Notes:</span>
          {sponsorship.advertiser_notes}
        </div>
      )}

      <div>
        <span>Advertiser Promo Url:</span>
        {sponsorship.advertiser_promo_url}
      </div>
    </div>
  );
};

SponsorshipPreview.propTypes = {
  sponsorship: PropTypes.object.isRequired,
};

export default SponsorshipPreview;
