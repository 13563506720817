import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { isInvalid, normalizeInitialValues } from 'utils/forms';
import { updateResource } from 'utils/api';
import { ItineraryContext } from 'contexts/ItineraryContext';

const defaultRequired = 'is required.';

const options = [
  { value: 'Pre-roll', label: 'Pre-roll' },
  { value: 'Mid-roll', label: 'Mid-roll' },
  { value: 'Post-roll', label: 'Post-roll' },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '50px',
    borderRadius: '3px',
    border: state.isFocused ? '1px solid #277288' : '1px solid #ced4da',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),

  container: provided => ({
    ...provided,
    border: 'none',
    borderRadius: '3px',
    outline: 'none',
  }),

  menu: provided => ({
    ...provided,
    zIndex: '10',
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#f1f2f6' : '',
    '&:hover': {
      backgroundColor: '#f1f2f6',
      cursor: 'pointer',
    },
  }),
};

const schema = yup.object({
  advertiser_copy: yup.string().required(defaultRequired),
  advertiser_notes: yup.string().required(defaultRequired),
  advertiser_promo_url: yup
    .string()
    .url('Invalid url')
    .required(defaultRequired),
  ad_type_list: yup.array().required(defaultRequired),
});

const AdvertiserDetailsForm = ({ sponsorship, setSponsorship }) => {
  const { setStep } = useContext(ItineraryContext);

  const handleUpdate = (values, actions) => {
    const ad_type_list = values.ad_type_list.map(t => t.value);

    updateResource({
      type: 'sponsorships',
      id: sponsorship.id,
      data: { sponsorship: { ...values, ad_type_list } },
      successMessage: 'Successfully saved details.',
      ...actions,
    }).then(sponsorship => {
      setSponsorship(sponsorship);
      setStep(3);
    });
  };

  const ad_type_list = sponsorship.ad_type_list.map(type => ({ value: type, label: type }));

  const sponsorshipValues = pick(sponsorship, [
    'advertiser_copy',
    'advertiser_promo_url',
    'advertiser_notes',
  ]);
  const initialValues = { ...normalizeInitialValues(sponsorshipValues), ad_type_list };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={handleUpdate}
        enableReinitialize
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit} className="green-focus style-form">
            <Form.Group className="floating-label">
              <Form.Control
                as="textarea"
                placeholder="Ad Copy..."
                name="advertiser_copy"
                value={values.advertiser_copy}
                onChange={handleChange}
                isInvalid={isInvalid('advertiser_copy', { touched, errors })}
                rows="4"
              />

              <Form.Label column sm={3}>
                Ad Copy
              </Form.Label>

              <Form.Control.Feedback type="invalid">
                {get(errors, 'advertiser_copy')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="floating-label">
              <Form.Control
                type="url"
                placeholder="Will enable tracking..."
                name="advertiser_promo_url"
                value={values.advertiser_promo_url}
                onChange={handleChange}
                isInvalid={isInvalid('advertiser_promo_url', { touched, errors })}
              />

              <Form.Label column sm={3}>
                Promotional URL
              </Form.Label>

              <Form.Control.Feedback type="invalid">
                {get(errors, 'advertiser_promo_url')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group sm={12}>
              <Select
                as="form.control"
                placeholder="Select ad types"
                name="ad_type_list"
                value={values.ad_type_list}
                onChange={type => setFieldValue('ad_type_list', type)}
                isInvalid={isInvalid('ad_type_list', { touched, errors })}
                options={options}
                isMulti
                styles={customStyles}
              />

              <Form.Control.Feedback type="invalid">
                {get(errors, 'ad_type_list')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="floating-label">
              <Form.Control
                as="textarea"
                placeholder="Any comments you want to share with the Podcaster..."
                name="advertiser_notes"
                value={values.advertiser_notes}
                onChange={handleChange}
                isInvalid={isInvalid('advertiser_notes', { touched, errors })}
                rows="4"
              />

              <Form.Label column sm={3}>
                Advertiser Notes
              </Form.Label>

              <Form.Control.Feedback type="invalid">
                {get(errors, 'advertiser_notes')}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="success" className="mt-4" type="submit" block>
              Save Details
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

AdvertiserDetailsForm.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setSponsorship: PropTypes.func.isRequired,
};

export default AdvertiserDetailsForm;
