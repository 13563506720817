import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from 'components/dashboard/DashboardPage.jsx';
import Login from 'components/Login';
import SignUpPage from 'components/SignUpPage';
import CampaignsPage from 'components/campaigns/CampaignsPage';
import SponsorshipsPage from 'components/sponsorships/SponsorshipsPage';
import PodcastsPage from 'components/podcasts/PodcastsPage';
import ForgotPassword from 'components/ForgotPassword';
import SettingsPage from 'components/settings/SettingsPage';
import MarketplacePage from 'components/marketplace/MarketplacePage';
import ConversationsPage from 'components/conversations/ConversationsPage';
import ResendConfirmation from 'components/ResendConfirmation';
import ResetPassword from 'components/ResetPassword';
import ReportingPage from 'components/reporting/ReportingPage';
import Users from 'components/Users';

export const AuthenticatedRoutes = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/campaigns" component={CampaignsPage} />
    <Route path="/sponsorships" component={SponsorshipsPage} />
    <Route path="/podcasts" component={PodcastsPage} />
    <Route path="/marketplace" component={MarketplacePage} />
    <Route path="/settings" component={SettingsPage} />
    <Route path="/conversations" component={ConversationsPage} />
    <Route path="/reporting" component={ReportingPage} />
    <Route path="/users" component={Users} />
  </Switch>
);

export const Routes = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/sign_up" component={SignUpPage} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/resend-confirmation" component={ResendConfirmation} />
  </Switch>
);
