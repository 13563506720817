import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { AuthContext } from 'contexts/';

const useAuthRedirect = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (!isEmpty(user)) {
      history.push(user.role === 'podcaster' ? '/podcasts' : '/campaigns');
    }
  }, [user, history]);
};

export default useAuthRedirect;
