import React from 'react';
import Alert from 'react-bootstrap/Alert';

const WhatNext = () => (
  <Alert variant="warning">
    <Alert.Heading>What happens next?</Alert.Heading>

    <p>
      An Account Manager will review your Campaign details and search for Podcasts within your
      target budget and audience. We may reach out to better understand your goals.
    </p>

    <p>
      Once we find an opportunity, you will be notified of a Sponsorship pending your review. You
      will be connected directly to the Podcaster to finalize Sponsorship copy and dates within our
      platform.
    </p>
  </Alert>
);

export default WhatNext;
