import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { fetchResource } from 'utils/api';
import SponsorshipForm from './Form';

const EditSponsorship = () => {
  const [sponsorship, setSponsorship] = useState(null);
  const [podcast, setPodcast] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchResource({ type: 'sponsorships', id }).then(sponsorship => setSponsorship(sponsorship));
  }, [id]);

  useEffect(() => {
    if (!sponsorship) return;

    const podcastId = sponsorship.podcast_id;
    const campaignId = sponsorship.campaign_id;

    fetchResource({
      type: 'podcasts',
      id: podcastId,
    }).then(podcast => {
      setPodcast([podcast.podcast]);
    });

    fetchResource({
      type: 'campaigns',
      id: campaignId,
    }).then(campaign => {
      setCampaign(campaign);
    });
  }, [sponsorship]);

  if (sponsorship) {
    const start_date = sponsorship.start_date ? moment(sponsorship.start_date) : null;
    const price = sponsorship.price / 100;

    return (
      <SponsorshipForm
        newRecord={false}
        initialValues={{ ...sponsorship, start_date, price }}
        defaultSelectedPodcast={podcast}
        defaultSelectedCampaign={campaign}
      />
    );
  }

  return 'loading';
};

export default EditSponsorship;
