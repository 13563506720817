import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import get from 'lodash/get';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { MobileOnly } from 'utils/responsive';
import ConversationList from './ConversationList';

const ChatSidebar = ({
  conversations,
  setConversations,
  handleSearch,
  handleConversationClick,
  searchConversationsRef,
  activeTab,
  setFilteredConversations,
  changeActiveTab,
  handleClickOpen,
  paginationProps,
}) => {
  return (
    <div className="chat-sidebar">
      <MobileOnly>
        <FontAwesomeIcon
          icon="times"
          onClick={e => {
            e.stopPropagation();
            handleClickOpen();
          }}
          className="close-icon"
        />
      </MobileOnly>

      <Row spacing={1} className="filter">
        <Col className="mb-3" xl={6}>
          <Button
            onClick={() => {
              if (activeTab !== 'Conversation') {
                changeActiveTab('Conversation');
              }
            }}
            variant={activeTab === 'Conversation' ? 'primary' : 'outline-primary'}
            block
          >
            DMs
          </Button>
        </Col>

        <Col xl={6}>
          <Button
            onClick={() => {
              if (activeTab !== 'Sponsorship') {
                changeActiveTab('Sponsorship');
              }
            }}
            variant={activeTab === 'Sponsorship' ? 'primary' : 'outline-primary'}
            block
          >
            Sponsorships
          </Button>
        </Col>
      </Row>

      <InputGroup className="search-input">
        <Form.Control
          ref={searchConversationsRef}
          type="text"
          placeholder="Find a conversation"
          name="search"
          autoComplete="off"
          onChange={e => handleSearch(e.target.value)}
          autoFocus
        />

        <FontAwesomeIcon icon="search" />
      </InputGroup>

      {conversations.length === 0 && get(searchConversationsRef, 'current.value', '').length > 0 && (
        <Alert className="mt-2" variant="info">
          No results are matching your search.
        </Alert>
      )}

      <ConversationList
        conversations={conversations}
        handleConversationClick={handleConversationClick}
        handleClickOpen={handleClickOpen}
        activeTab={activeTab}
        setConversations={setConversations}
        setFilteredConversations={setFilteredConversations}
        paginationProps={paginationProps}
      />
    </div>
  );
};

ChatSidebar.defaultProps = {
  handleClickOpen: null,
};

ChatSidebar.propTypes = {
  conversations: PropTypes.array.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleConversationClick: PropTypes.func.isRequired,
  searchConversationsRef: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  setConversations: PropTypes.func.isRequired,
  setFilteredConversations: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func,
  paginationProps: PropTypes.object.isRequired,
};

export default ChatSidebar;
