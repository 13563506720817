import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';
import swal from 'sweetalert';
import StripeConnect from 'components/settings/StripeConnect';
import AuthorizationGate from 'components/AuthorizationGate';

import { updateResource, createResource } from 'utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItineraryContext } from 'contexts/ItineraryContext';
import { AuthContext } from 'contexts/index';
import { PixelDetailsContext } from '../../contexts/PixelDetailsContext';

const ScheduleSection = ({ sponsorship, setSponsorship }) => {
  const { user } = useContext(AuthContext);
  const { setStep } = useContext(ItineraryContext);
  const { setSponsorship: setPixelSponsorship } = useContext(PixelDetailsContext);
  const history = useHistory();

  const approveSponsorship = () => {
    let data = {};

    if (user.role === 'admin' || user.role === 'account_manager') {
      data = { advertiser_approved: true, podcaster_approved: true };
    } else {
      data[`${user.role}_approved`] = true;
    }

    updateResource({
      data: { sponsorship: { ...data } },
      type: 'sponsorships',
      id: sponsorship.id,
      successMessage: 'Successfully approved sponsorship!',
    }).then(sponsorship => {
      setSponsorship(sponsorship);

      if (sponsorship.status === 'scheduled') {
        setStep(4);
      }
    });
  };

  const declineSponsorship = sponsorship => {
    const { id } = sponsorship;

    swal({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then(result => {
      if (!result) return;

      createResource({
        type: 'sponsorships',
        endpoint: `sponsorships/${id}/reject`,
        successMessage: 'You declined this Sponsorship.',
      }).then(() => {
        setSponsorship(prev => ({ ...prev, status: 'cancelled' }));
        history.push('/sponsorships');
      });
    });
  };

  const approved = get(sponsorship, `${user.role}_approved`, null);

  return (
    <div>
      {(user.role === 'advertiser' || (user.role === 'podcaster' && user.stripe_connected)) &&
        approved === false && (
          <Alert variant="success" className="alert-content">
            <div>
              <Alert.Heading className="text-center">You're close!</Alert.Heading>
              <p>Approve the sponsorship so it can be launched.</p>
            </div>

            <Button onClick={approveSponsorship} variant="success">
              Approve
            </Button>
          </Alert>
        )}

      {user.role !== 'podcaster' &&
        sponsorship.advertiser_approved === true &&
        sponsorship.podcaster_approved === false && (
          <Alert variant="warning" className={sponsorship.status === 'cancelled' ? 'd-none' : ''}>
            <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
            Waiting for Podcaster to approve the Sponsorship.
          </Alert>
        )}

      {user.role !== 'podcaster' &&
        sponsorship.podcaster_approved === false &&
        sponsorship.status === 'cancelled' && (
          <Alert variant="danger">
            <FontAwesomeIcon icon="times" size="lg" className="mr-2" />
            Podcaster declined the Sponsorship.
          </Alert>
        )}

      {user.role !== 'advertiser' &&
        sponsorship.advertiser_approved === false &&
        sponsorship.podcaster_approved === true && (
          <Alert variant="warning">
            <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
            Waiting for Advertiser to approve the Sponsorship.
          </Alert>
        )}

      {sponsorship.advertiser_approved === true && sponsorship.podcaster_approved && (
        <Alert variant="success">
          <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
          Both the Advertiser and the Podcaster approved!
        </Alert>
      )}

      <AuthorizationGate roles="advertiser, admin, account_manager">
        <Alert variant="info" className="pixel-msg">
          <FontAwesomeIcon icon="chart-line" size="lg" className="mr-2" />
          Don't forget to set up your tracking pixel.{' '}
          <span onClick={() => setPixelSponsorship(sponsorship)} className="pixel">
            Click here to get started
          </span>
        </Alert>
      </AuthorizationGate>

      {user.role === 'podcaster' && <StripeConnect sponsorshipId={sponsorship.id} />}

      {user.role !== 'advertiser' && approved === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 26 }}
          className="d-flex align-items-center justify-content-between"
        >
          <div>
            <FontAwesomeIcon icon="times" size="lg" className="mr-2" />
            If you are not interested you can decline the sponsorship.
          </div>

          <Button onClick={() => declineSponsorship(sponsorship)} variant="danger">
            Decline
          </Button>
        </Alert>
      )}
    </div>
  );
};

ScheduleSection.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setSponsorship: PropTypes.func.isRequired,
};

export default ScheduleSection;
