import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import useListenNotesTypeahead from 'hooks/useListenNotesTypeahead';
import { isInvalid, normalizeInitialValues } from 'utils/forms';
import { updateResource } from 'utils/api';
import { AuthContext } from 'contexts';
import { Mobile, Default } from 'utils/responsive';
import { ItineraryContext } from 'contexts/ItineraryContext';
import Steps from './Steps';
import InterestActions from './InterestActions';

const defaultRequired = 'is required.';
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const options = [
  { value: 'Pre-roll', label: 'Pre-roll' },
  { value: 'Mid-roll', label: 'Mid-roll' },
  { value: 'Post-roll', label: 'Post-roll' },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '42.5px',
    borderRadius: '3px',
    border: state.isFocused ? '1px solid #277288' : '1px solid #ced4da',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),

  container: provided => ({
    ...provided,
    border: 'none',
    borderRadius: '3px',
    outline: 'none',
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#f1f2f6' : '',
    '&:hover': {
      backgroundColor: '#f1f2f6',
      cursor: 'pointer',
    },
  }),
};

const schema = yup.object({
  description: yup.string().required(defaultRequired),
  price: yup.number().required(defaultRequired),
  start_date: yup.date('is not a valid date').required(defaultRequired),
  placements: yup.string().required(defaultRequired),
  attachment: yup
    .mixed()
    .test('fileSize', 'File Size is too large', value =>
      value ? value.size <= 10 * 1024 * 1024 : true
    )
    .test('fileType', 'Unsupported File Format', value =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    ),
  ad_type_list: yup.string().required(defaultRequired),
});

const FormDetails = ({ sponsorship, setSponsorship }) => {
  const { user } = useContext(AuthContext);
  const { step, setStep } = useContext(ItineraryContext);
  const history = useHistory();
  const normalizedSponsorship = normalizeInitialValues(sponsorship);

  const handleUpdate = (values, actions) => {
    const price = values.price * 100;
    const data = new FormData(); // eslint-disable-line no-undef

    Object.keys(values)
      .filter(
        key => key !== 'ad_type_list' && key !== 'advertiser_promo_url' && key !== 'attachments'
      )
      .forEach(key => data.set(`sponsorship[${key}]`, values[key]));
    data.set('sponsorship[price]', price);

    values.attachments.forEach(attachment => {
      data.append(`sponsorship[attachments][]`, attachment);
    });

    values.ad_type_list.forEach(type => {
      data.append(`sponsorship[ad_type_list][]`, type.value);
    });

    updateResource({
      type: 'sponsorships',
      id: sponsorship.id,
      data,
      successMessage: 'Successfully updated details.',
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      ...actions,
    }).then(sponsorship => {
      setSponsorship(sponsorship);
      // setStep(2);
    });
  };

  const approveCallback = () => {
    setStep(2);
    setSponsorship(prev => ({ ...prev, status: 'in_development' }));
  };

  const rejectCallback = () => {
    history.push('/sponsorships');
  };

  const { startDateFocused, setStartDateFocused } = useListenNotesTypeahead();

  const start_date = sponsorship.start_date ? moment(sponsorship.start_date) : null;
  const price = sponsorship.price / 100;
  const ad_type_list = sponsorship.ad_type_list.map(type => ({ value: type, label: type }));
  const normalizedInitialValues = normalizeInitialValues(sponsorship);

  return (
    <div className="main-body">
      {step === 1 && (
        <div className="form-container">
          <Formik
            validationSchema={schema}
            onSubmit={handleUpdate}
            enableReinitialize
            initialValues={{
              ...normalizedInitialValues,
              start_date,
              price,
              ad_type_list,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
              <Container>
                <Row noGutters>
                  <Col
                    className={
                      user.role === 'advertiser' || user.role === 'podcaster'
                        ? `disabled-form left-side ${
                            sponsorship.status === 'opportunity' ? 'mt-100' : ''
                          }`
                        : 'left-side'
                    }
                    md={6}
                    xl={5}
                  >
                    <Form noValidate onSubmit={handleSubmit} className="green-focus style-form">
                      <Form.Group className="floating-label">
                        <Form.Control
                          as="textarea"
                          placeholder="Description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={isInvalid('description', { touched, errors })}
                          disabled={user.role === 'podcaster' || user.role === 'advertiser'}
                          className="textarea"
                          rows="4"
                        />

                        <Form.Label>Description</Form.Label>

                        <Form.Control.Feedback type="invalid">
                          {get(errors, 'description')}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="new-details">
                        <Form.Group className="floating-label">
                          <Form.Control
                            type="number"
                            placeholder="Price"
                            name="price"
                            value={values.price}
                            onChange={handleChange}
                            isInvalid={isInvalid('price', { touched, errors })}
                            disabled={user.role === 'podcaster' || user.role === 'advertiser'}
                          />

                          <Form.Label>Price</Form.Label>

                          <Form.Control.Feedback type="invalid">
                            {get(errors, 'price')}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="floating-label">
                          <Form.Control
                            as="textarea"
                            placeholder="Placements"
                            name="placements"
                            value={values.placements}
                            onChange={handleChange}
                            isInvalid={isInvalid('placements', { touched, errors })}
                            disabled={user.role === 'podcaster' || user.role === 'advertiser'}
                            rows="auto"
                          />

                          <Form.Label>Placements</Form.Label>

                          <Form.Control.Feedback type="invalid">
                            {get(errors, 'placements')}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group sm={12}>
                          <Select
                            as="form.control"
                            placeholder="Select ad types"
                            name="ad_type_list"
                            value={values.ad_type_list}
                            onChange={type => setFieldValue('ad_type_list', type)}
                            isInvalid={isInvalid('ad_type_list', { touched, errors })}
                            isDisabled={user.role === 'podcaster' || user.role === 'advertiser'}
                            options={options}
                            isMulti
                            styles={customStyles}
                          />

                          <Form.Control.Feedback type="invalid">
                            {get(errors, 'ad_type_list')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <Alert className="mb-4 content" variant="warning">
                        Price, Placement and Ad types will be finalized after proceeding to "In
                        Development".
                      </Alert>

                      <Form.Group>
                        <Col
                          sm={12}
                          className={`start-date-container ${startDateFocused ? 'focused' : ''} ${
                            isInvalid('start_date', { touched, errors }) ? 'invalid' : ''
                          }`}
                        >
                          <Form.Label column sm={4}>
                            Target Start Date
                          </Form.Label>

                          <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={isInvalid('start_date', { touched, errors })}
                            sm={8}
                          />

                          <div
                            onClick={() => setStartDateFocused(prev => !prev)}
                            className={
                              user.role === 'podcaster' || user.role === 'advertiser'
                                ? 'd-none'
                                : 'calendar'
                            }
                          >
                            <FontAwesomeIcon icon="calendar" />
                          </div>

                          <Default>
                            <SingleDatePicker
                              id="start_date"
                              placeholder="Start Date"
                              date={values.start_date}
                              onDateChange={date => setFieldValue('start_date', date)}
                              focused={startDateFocused}
                              onFocusChange={({ focused }) => setStartDateFocused(focused)}
                              orientation="horizontal"
                              openDirection="up"
                              numberOfMonths={2}
                              disabled={user.role === 'podcaster' || user.role === 'advertiser'}
                              small
                              block
                            />
                          </Default>

                          <Mobile>
                            <SingleDatePicker
                              id="start_date"
                              placeholder="Start Date"
                              date={values.start_date}
                              onDateChange={date => setFieldValue('start_date', date)}
                              focused={startDateFocused}
                              onFocusChange={({ focused }) => setStartDateFocused(focused)}
                              orientation="vertical"
                              openDirection="up"
                              numberOfMonths={1}
                              disabled={user.role === 'podcaster' || user.role === 'advertiser'}
                              small
                              block
                            />
                          </Mobile>

                          <Form.Control.Feedback type="invalid">
                            Is not a valid date
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Button
                        className={
                          (user.role === 'advertiser' || user.role === 'podcaster') && 'd-none'
                        }
                        type="submit"
                        block
                        variant="success"
                      >
                        Update Details
                      </Button>
                    </Form>
                  </Col>

                  <InterestActions
                    sponsorship={sponsorship}
                    approveCallback={approveCallback}
                    rejectCallback={rejectCallback}
                  />
                </Row>
              </Container>
            )}
          </Formik>
        </div>
      )}

      <Steps
        sponsorship={normalizedSponsorship}
        setSponsorship={setSponsorship}
        rejectCallback={rejectCallback}
      />
    </div>
  );
};

FormDetails.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setSponsorship: PropTypes.func.isRequired,
};

export default FormDetails;
