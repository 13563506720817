import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import DropzoneField from 'components/DropzoneField';
import { updateResource, deleteResource } from 'utils/api';
import AuthorizationGate from 'components/AuthorizationGate';
import { AuthContext } from 'contexts/index';

const AttachmentsSidebar = ({ setOpenFiles, setSponsorship, sponsorship }) => {
  const { user } = useContext(AuthContext);

  const getAttachmentIcon = extension => {
    const imageExtensions = ['jpg', 'png', 'jpeg', 'gif'];

    if (imageExtensions.includes(extension.toLowerCase())) {
      return 'file-image';
    }
    if (extension.toLowerCase() === 'pdf') {
      return 'file-pdf';
    }

    return 'file-alt';
  };

  const removeAttachment = id => {
    deleteResource({
      endpoint: `attachments/${id}`,
      message: 'Successfully removed the attachment.',
    }).then(() => {
      setSponsorship(sponsorship => ({
        ...sponsorship,
        attachments: sponsorship.attachments.filter(attachment => attachment.id !== id),
      }));
    });
  };

  const handleUpdate = (values, actions) => {
    const data = new FormData(); // eslint-disable-line no-undef

    values.attachments.forEach(attachment => data.append(`sponsorship[attachments][]`, attachment));

    updateResource({
      type: 'sponsorships',
      id: sponsorship.id,
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      successMessage: 'Successfully uploaded file.',
      ...actions,
    }).then(sponsorship => {
      setSponsorship(sponsorship);
    });
  };

  return (
    <div className="attachments">
      <div className="header d-flex justify-content-between">
        <h5 className="mb-0">Attachments</h5>

        <span onClick={() => setOpenFiles(false)} className="close-icon">
          <FontAwesomeIcon icon="times" />
        </span>
      </div>

      <div className="body">
        {user.role !== 'podcaster' && (
          <Formik onSubmit={handleUpdate} enableReinitialize initialValues={{ attachments: [] }}>
            {({ handleSubmit, values, setFieldValue }) => (
              <Form noValidate onSubmit={handleSubmit} className="green-focus style-form">
                <Form.Group>
                  <DropzoneField values={values} setFieldValue={setFieldValue} />
                </Form.Group>

                <Button variant="success" className="mt-4" type="submit" block>
                  Upload files
                </Button>
              </Form>
            )}
          </Formik>
        )}

        <AuthorizationGate roles="podcaster">
          {sponsorship.attachments.length === 0 && (
            <Alert variant="warning">There are no attachments yet.</Alert>
          )}
        </AuthorizationGate>

        {sponsorship.attachments.length > 0 && (
          <div className={user.role === 'podcaster' ? 'mt-0 selected-files' : 'selected-files'}>
            <span className={user.role !== 'advertiser' ? 'd-none' : ''}>
              <h6>Selected files:</h6>
            </span>

            {sponsorship.attachments.map(attachment => (
              <div className="file" key={attachment.id}>
                <div className="details">
                  <div className="file-img d-flex align-items-center">
                    <FontAwesomeIcon icon={getAttachmentIcon(attachment.extension)} />
                    <span className="name mx-2">{attachment.name}</span>
                  </div>

                  {user.role !== 'podcaster' && (
                    <span onClick={() => removeAttachment(attachment.id)} className="remove">
                      Remove
                    </span>
                  )}
                </div>

                <a href={attachment.url} target="_blank">
                  Click to view attachment
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

AttachmentsSidebar.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setSponsorship: PropTypes.func.isRequired,
  setOpenFiles: PropTypes.func.isRequired,
};

export default AttachmentsSidebar;
