import React from 'react';
import Responsive from 'react-responsive';

export const Desktop = props => <Responsive {...props} minWidth={992} />;
export const TabletOnly = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
export const Tablet = props => <Responsive {...props} minWidth={768} />;
export const TabletAndUp = props => <Responsive {...props} minWidth={426} />;
export const Mobile = props => <Responsive {...props} maxWidth={991} />;
export const MobileOnly = props => <Responsive {...props} maxWidth={425} />;
export const MobileAndUp = props => <Responsive {...props} minWidth={421} />;
export const Default = props => <Responsive {...props} minWidth={992} />;
