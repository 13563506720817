import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// eslint-disable-next-line react/prop-types
const Message = ({ icon, message }) => (
  <span>
    <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} size="lg" />
    {message}
  </span>
);

const getIcon = type => {
  if (type === 'success') {
    return 'check-circle';
  }
  if (type === 'warn') {
    return 'exclamation-triangle';
  }
  if (type === 'success') {
    return 'info-circle';
  }
  return 'exclamation-circle';
};

export default function(type, message, options = {}) {
  const icon = getIcon(type);

  toast[type](<Message icon={icon} message={message} />, options);
}
