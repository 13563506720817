import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MobileOnly } from 'utils/responsive';
import Sidebar from './marketplace/Sidebar';

const MenuMarketplace = ({ searchGenresRef, selectGenre, selectedGenres, removeGenre }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MobileOnly>
      <div style={{ width: ' 100%' }}>
        <div onClick={handleClickOpen}>
          <FontAwesomeIcon icon="chevron-left" style={{ marginRight: '20px' }} size="lg" />
          <span>Genres</span>
        </div>

        <hr style={{ marginTop: '2%' }} />

        <div id="menuMarketplace" className={isOpen ? 'opened' : 'closed'}>
          <div className="sidebar">
            <Sidebar
              searchGenresRef={searchGenresRef}
              selectGenre={selectGenre}
              selectedGenres={selectedGenres}
              removeGenre={removeGenre}
              handleClickOpen={handleClickOpen}
            />
          </div>
        </div>
      </div>
    </MobileOnly>
  );
};

MenuMarketplace.propTypes = {
  searchGenresRef: PropTypes.object.isRequired,
  selectedGenres: PropTypes.array.isRequired,
  selectGenre: PropTypes.func.isRequired,
  removeGenre: PropTypes.func.isRequired,
};

export default MenuMarketplace;
