import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import has from 'lodash/has';

import useListenNotesTypeahead from 'hooks/useListenNotesTypeahead';
import { fetchResources } from 'utils/api';
import { TabletAndUp } from 'utils/responsive';
import Sidebar from './Sidebar';
import PodcastList from './PodcastList';
import 'styles/MarketplacePage.scss';
import 'styles/ConversationsPage.scss';

const MarketplacePage = ({ defaultSelectedGenres, defaultSelectedPodcasts }) => {
  const [podcastMapping, setPodcastMapping] = useState({});
  const {
    podcastResults,
    setPodcastsFocused,
    selectedGenres,
    removeGenre,
    selectGenre,
    searchPodcastsRef,
    searchGenresRef,
    setSelectedPodcasts,
    getPodcasts,
    response,
    loading,
  } = useListenNotesTypeahead(defaultSelectedGenres);

  useEffect(() => {
    setSelectedPodcasts(defaultSelectedPodcasts);
  }, [defaultSelectedPodcasts, setSelectedPodcasts]);

  useEffect(() => {
    const fetchMapping = () =>
      fetchResources({
        endpoint: 'podcasts/verified_price_mapping',
        query: {
          external_podcast_ids: podcastResults.map(p => p.id),
        },
      }).then(mapping => setPodcastMapping(mapping));

    fetchMapping();
  }, [podcastResults, setPodcastMapping]);

  return (
    <Container className="marketplace-page">
      <Row noGutters>
        <TabletAndUp>
          <Col className="sidebar" sm={4}>
            <Sidebar
              selectedGenres={selectedGenres}
              selectGenre={selectGenre}
              removeGenre={removeGenre}
              searchGenresRef={searchGenresRef}
            />
          </Col>
        </TabletAndUp>

        <Col className="podcasts" sm={8}>
          <PodcastList
            podcastResults={podcastResults}
            podcastMapping={podcastMapping}
            searchPodcastsRef={searchPodcastsRef}
            searchGenresRef={searchGenresRef}
            setPodcastsFocused={setPodcastsFocused}
            selectGenre={selectGenre}
            removeGenre={removeGenre}
            selectedGenres={selectedGenres}
            getPodcasts={getPodcasts}
            response={response}
            search={has(response, 'next_offset')}
            loading={loading}
          />
        </Col>
      </Row>
    </Container>
  );
};

MarketplacePage.propTypes = {
  defaultSelectedGenres: PropTypes.array,
  defaultSelectedPodcasts: PropTypes.array,
};

MarketplacePage.defaultProps = {
  defaultSelectedGenres: [],
  defaultSelectedPodcasts: [],
};

export default MarketplacePage;
