import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import qs from 'qs';
import toast from 'utils/toast.jsx';
import axios from 'utils/axios';

import useAuthRedirect from 'hooks/useAuthRedirect';
import { isInvalid } from 'utils/forms';
import { AuthContext } from 'contexts';
import { handleError } from 'utils/api';
import { Tablet, MobileOnly } from 'utils/responsive';
import logo from 'images/logo.png';
import retinaLogo from 'images/logo@2x.png';
import BackgroundImage from './BackgroundImage';

const defaultRequired = 'is required.';

const schema = yup.object({
  password: yup
    .string()
    .required(defaultRequired)
    .min(6, 'Must be at least 6 characters'),
  password_confirmation: yup
    .string()
    .required(defaultRequired)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const initialValues = {
  password: '',
  password_confirmation: '',
};

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { setCurrentUser } = useContext(AuthContext);

  const handleSubmit = values => {
    const { reset_password_token } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (!reset_password_token) {
      toast('warn', 'Invalid reset password token.');
      history.push('/login');
      return;
    }

    axios
      .put(
        '/password',
        { user: { ...values, reset_password_token } },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        setCurrentUser(response.data);
      })
      .catch(error => {
        handleError({ error });
      });
  };

  useAuthRedirect();

  return (
    <Container>
      <Row noGutters>
        <Col>
          <MobileOnly>
            <div className="mobile-logo">
              <img src={logo} srcSet={`${logo} 1x, ${retinaLogo} 2x`} alt="Sponsored Logo" />
            </div>
          </MobileOnly>
        </Col>
      </Row>

      <Row className="row-container" noGutters>
        <Col className="form-container centered main-body" xs={12} md={6} xl={5}>
          <div className="title">
            <h2>Reset password</h2>

            <span>Enter your new password you'd like to use.</span>
          </div>

          <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="my-4">
                <Form.Group className="floating-label">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={isInvalid('password', { touched, errors })}
                  />

                  <Form.Label column sm={4}>
                    Password
                  </Form.Label>

                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="floating-label">
                  <Form.Control
                    type="password"
                    placeholder="Password Confirmation"
                    name="password_confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange}
                    isInvalid={isInvalid('password_confirmation', { touched, errors })}
                  />

                  <Form.Label column sm={4}>
                    Password Confirmation
                  </Form.Label>

                  <Form.Control.Feedback type="invalid">
                    {errors.password_confirmation}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button className="py-2 mt-4" type="submit" block>
                  Send
                </Button>
              </Form>
            )}
          </Formik>

          <div className="bottom-text">
            Back to
            <Link to="/login" className="ml-1">
              Login Page
            </Link>
          </div>
        </Col>

        <Tablet>
          <BackgroundImage />
        </Tablet>
      </Row>
    </Container>
  );
};

export default ResetPassword;
