import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

import { createResource, sweetAlert } from 'utils/api';
import { formatPrice } from 'utils/forms';
import PaginationFooter from 'components/PaginationFooter';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';

const Claims = ({ podcastClaims, setPodcastClaims, claimsProps, isLoading }) => {
  const history = useHistory();
  const { setPodcast } = useContext(PodcastDetailsContext);

  const handleApprove = id => {
    createResource({
      type: 'podcast_claims',
      endpoint: `podcast_claims/${id}/approve`,
      successMessage: 'Successfully approved the podcast!',
    }).then(() => {
      setPodcastClaims(state => {
        return state.filter(value => value.id !== id);
      });
    });
  };

  const handleReject = id => {
    createResource({
      type: 'podcast_claims',
      endpoint: `podcast_claims/${id}/reject`,
      successMessage: 'Successfully rejected the podcast.',
    }).then(() => {
      setPodcastClaims(state => {
        return state.filter(value => value.id !== id);
      });
    });
  };

  return (
    <div className="podcasts">
      {podcastClaims.map(podcastClaim => (
        <div
          className="claimed-podcast chat"
          key={podcastClaim.id}
          style={{ cursor: 'pointer' }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            if (podcastClaim.conversation_id) {
              history.push(`/conversations/${podcastClaim.conversation_id}`);
            }
          }}
        >
          <div className="podcast-details">
            <div className="see-more">
              <img
                src={get(podcastClaim, 'podcast.thumbnail', '')}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (podcastClaim.podcast.thumbnail) {
                    setPodcast(podcastClaim.podcast);
                  }
                }}
                alt="Podcast Thumbnail"
              />

              {podcastClaim.podcast.thumbnail && (
                <span
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    setPodcast(podcastClaim.podcast);
                  }}
                  className="show-more"
                >
                  Show more
                </span>
              )}
            </div>

            <div className="name">
              <span className="font-weight-bold">{get(podcastClaim, 'podcast.title', '')}</span>

              <span>
                {podcastClaim.podcaster_name}
                <span className="email">({podcastClaim.podcaster_email})</span>
              </span>
            </div>
          </div>

          <div className="actions">
            <span>Price: {formatPrice(podcastClaim.price)}</span>

            <div className="icons">
              <span
                onClick={e => {
                  if (podcastClaim.current_podcaster_name) {
                    sweetAlert({
                      title: 'This podcast is already claimed.',
                      text: `It belongs to ${podcastClaim.current_podcaster_name} (${podcastClaim.current_podcaster_email}). Are you sure you want to continue?`,
                      callback: () => handleApprove(podcastClaim.id),
                    });
                  } else {
                    e.preventDefault();
                    e.stopPropagation();

                    handleApprove(podcastClaim.id);
                  }
                }}
                className="approve-icon"
              >
                <OverlayTrigger placement="bottom" overlay={<Tooltip>Approve</Tooltip>}>
                  <FontAwesomeIcon icon="check" />
                </OverlayTrigger>
              </span>

              <span
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleReject(podcastClaim.id);
                }}
                className="decline-icon"
              >
                <OverlayTrigger placement="bottom" overlay={<Tooltip>Reject</Tooltip>}>
                  <FontAwesomeIcon icon="times" />
                </OverlayTrigger>
              </span>
            </div>
          </div>
        </div>
      ))}

      {podcastClaims.length > 0 && (
        <PaginationFooter
          {...claimsProps}
          data={podcastClaims}
          perPage={10}
          perPageOptions={[10]}
          title="Podcasts"
        />
      )}

      {podcastClaims.length === 0 && !isLoading && (
        <Alert variant="info">There are no Podcast Claims.</Alert>
      )}
    </div>
  );
};

Claims.propTypes = {
  podcastClaims: PropTypes.array.isRequired,
  setPodcastClaims: PropTypes.func.isRequired,
  claimsProps: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Claims;
