import {
  faEnvelope,
  faCog,
  faSearch,
  faMicrophone,
  faBullhorn,
  faSignOutAlt,
  faUpload,
  faTimes,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faTrashAlt,
  faCheck,
  faCheckCircle,
  faDollarSign,
  faThumbsUp,
  faThumbsDown,
  faExclamationCircle,
  faExclamationTriangle,
  faRocket,
  faEdit,
  faUserClock,
  faHourglassHalf,
  faBell,
  faUserCircle,
  faCalendar,
  faPodcast,
  faSpinner,
  faHandshake,
  faBars,
  faImage,
  faCopy,
  faChartBar,
  faPaperclip,
  faFilePdf,
  faFileAlt,
  faFileImage,
  faInfoCircle,
  faChartLine,
  faUsers,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

export default {
  faEnvelope,
  faCog,
  faSearch,
  faMicrophone,
  faBullhorn,
  faSignOutAlt,
  faUpload,
  faTimes,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faTrashAlt,
  faCheck,
  faDollarSign,
  faThumbsUp,
  faThumbsDown,
  faExclamationCircle,
  faExclamationTriangle,
  faRocket,
  faEdit,
  faCheckCircle,
  faUserClock,
  faHourglassHalf,
  faBell,
  faUserCircle,
  faCalendar,
  faPodcast,
  faSpinner,
  faHandshake,
  faBars,
  faImage,
  faCopy,
  faChartBar,
  faPaperclip,
  faFilePdf,
  faFileAlt,
  faFileImage,
  faInfoCircle,
  faChartLine,
  faUsers,
  faQuestion,
};
