import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AuthorizationGate from 'components/AuthorizationGate';
import { HasResults } from 'contexts/HasResults';
import podcastCta from 'images/podcast_cta.png';
import podcastCtaRetina from 'images/podcast_cta@2x.png';
import usePaginatedData from 'hooks/usePaginatedData';
import PodcastsClaim from './PodcastsClaim';
import CallToAction from '../CallToAction';
import PodcastClaimList from './PodcastClaimList';
import PodcastList from './PodcastList';
import Claims from './Claims';
import Loader from '../Loader';

const PodcastsPage = () => {
  const { setPodcastsHasResults } = useContext(HasResults);

  const {
    isLoading: podcastsIsLoading,
    data: podcasts,
    setData: setPodcasts,
    ...podcastsProps
  } = usePaginatedData({
    endpoint: '/api/v1/podcasts',
    defaultPerPage: 10,
  });

  const {
    data: podcastClaims,
    setData: setPodcastClaims,
    isLoading: claimsIsLoading,
    ...claimsProps
  } = usePaginatedData({
    endpoint: '/api/v1/podcast_claims',
    defaultPerPage: 10,
  });

  useEffect(() => {
    if (podcastClaims.length > 0 || podcasts.length > 0) {
      setPodcastsHasResults(true);
    } else {
      setPodcastsHasResults(false);
    }
  }, [podcastClaims, podcasts, setPodcastsHasResults]);

  if (podcastsIsLoading || claimsIsLoading) {
    return <Loader />;
  }

  return (
    <Container className="podcasts-page">
      <AuthorizationGate roles="podcaster">
        {podcasts.length === 0 && podcastClaims.length === 0 && (
          <Route path="/podcasts" exact>
            <CallToAction
              title="Claim your Podcast to collect Sponsorships"
              subtitle="Once you claim a Podcast, we will connect you with Advertisers and Sponsorship opportunities."
              imgSrc={podcastCta}
              retinaImg={podcastCtaRetina}
              alt="No Podcasts"
              btnTitle="Claim Podcast"
              path="/podcasts/claim"
              hasButton
            />
          </Route>
        )}

        <Route path="/podcasts/claim">
          <Row noGutters>
            <Col className="main-body">
              <PodcastsClaim setPodcastClaims={setPodcastClaims} />
            </Col>
          </Row>
        </Route>

        <Route path="/podcasts" exact>
          <Row noGutters>
            <Col className="main-body">
              {podcastClaims.length > 0 && <PodcastClaimList podcastClaims={podcastClaims} />}

              {podcasts.length > 0 && (
                <PodcastList
                  podcasts={podcasts}
                  setPodcasts={setPodcasts}
                  podcastsProps={podcastsProps}
                />
              )}
            </Col>
          </Row>
        </Route>
      </AuthorizationGate>

      <AuthorizationGate roles="admin, account_manager">
        <Route path="/podcasts/claim">
          <Row noGutters>
            <Col className="main-body">
              <Claims
                podcastClaims={podcastClaims}
                setPodcastClaims={setPodcastClaims}
                claimsProps={claimsProps}
                isLoading={claimsIsLoading}
              />
            </Col>
          </Row>
        </Route>

        <Route path="/podcasts" exact>
          <Row noGutters>
            <Col className="main-body">
              <PodcastList
                podcasts={podcasts}
                setPodcasts={setPodcasts}
                podcastsProps={podcastsProps}
              />
            </Col>
          </Row>
        </Route>
      </AuthorizationGate>
    </Container>
  );
};

export default PodcastsPage;
