import React, { createContext, useState } from 'react';

export const HasResults = createContext(null);

// eslint-disable-next-line react/prop-types
const HasResultsProvider = ({ children }) => {
  const [campaignsHasResults, setCampaignsHasResults] = useState(false);
  const [sponsorshipsHasResults, setSponsorshipsHasResults] = useState(false);
  const [podcastsHasResults, setPodcastsHasResults] = useState(false);

  return (
    <HasResults.Provider
      value={{
        campaignsHasResults,
        setCampaignsHasResults,
        sponsorshipsHasResults,
        setSponsorshipsHasResults,
        podcastsHasResults,
        setPodcastsHasResults,
      }}
    >
      {children}
    </HasResults.Provider>
  );
};

export default HasResultsProvider;
