import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import usePaginatedData from 'hooks/usePaginatedData';
import PaginationFooter from 'components/PaginationFooter';
import { fetchResources, updateResource } from 'utils/api';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import { Mobile, MobileOnly, Default, Desktop, TabletAndUp } from 'utils/responsive';
import { formatPrice } from 'utils/forms';
import MenuMarketplace from 'components/MenuMarketplace';
import Loader from 'components/Loader';

const PodcastList = ({
  searchPodcastsRef,
  searchGenresRef,
  setPodcastsFocused,
  podcastResults,
  selectedGenres,
  getPodcasts,
  response,
  loading,
  selectGenre,
  removeGenre,
  podcastMapping,
  search,
}) => {
  const [campaigns, setCampaigns] = useState([]);
  const { total: responseTotal = 0, page_number, count } = response;
  const { setPodcast } = useContext(PodcastDetailsContext);
  const history = useHistory();
  const genre_ids = selectedGenres.map(genre => genre.id.toString()).join(',');
  const { page, setPage, perPage, setPerPage, totalCount, setTotalCount } = usePaginatedData({
    effectEnabled: false,
    defaultPerPage: 20,
  });

  const isFocused = useCallback(() => {
    return document.activeElement === searchPodcastsRef.current; // eslint-disable-line no-undef
  }, [searchPodcastsRef]);

  const handleSearch = () => {
    setPerPage(10);
    setPage(1);
    getPodcasts({ genre_ids, offset: 0 });
  };

  const handleClick = (podcast, campaign) => {
    let targeted_podcast_ids = [];
    const removing = campaign.targeted_podcast_ids.includes(podcast.id);

    if (removing) {
      targeted_podcast_ids = campaign.targeted_podcast_ids.filter(id => id !== podcast.id);
    } else {
      targeted_podcast_ids = [...campaign.targeted_podcast_ids, podcast.id];
    }

    updateResource({
      type: 'campaigns',
      id: campaign.id,
      data: {
        campaign: {
          ...campaign,
          targeted_podcast_ids,
        },
      },
      successMessage: removing
        ? 'Successfully removed the podcast from the campaign.'
        : 'Successfully added the podcast to the campaign.',
    }).then(campaign => {
      setCampaigns(prev => prev.map(c => (c.id === campaign.id ? campaign : c)));
    });
  };

  useEffect(() => {
    fetchResources({
      type: 'campaigns',
      query: { per_page: 30 },
    }).then(campaigns => setCampaigns(campaigns));
  }, []);

  useEffect(() => {
    if (search) {
      setPerPage(10);
    } else {
      setPerPage(20);
    }

    if (responseTotal !== totalCount) {
      setTotalCount(responseTotal);
    }
  }, [search, responseTotal, page_number, count, totalCount, setTotalCount, setPerPage]);

  // Search
  useEffect(() => {
    if (isFocused()) return;
    if (!search) return;

    const query = {
      genre_ids,
      offset: (page - 1) * 10,
    };

    getPodcasts(query);
  }, [search, page, genre_ids, getPodcasts, isFocused]);

  // Best podcasts
  useEffect(() => {
    if (search) return;
    if (isFocused()) return;

    const ids = genre_ids.split(',');

    const query = {
      genre_id: ids[ids.length - 1],
      page,
    };

    getPodcasts(query);
  }, [search, page, genre_ids, getPodcasts, isFocused]);

  const debouncedSearch = debounce(handleSearch, 300);

  return (
    <div className="podcast-list">
      <div className="header">
        <MenuMarketplace
          searchGenresRef={searchGenresRef}
          selectGenre={selectGenre}
          selectedGenres={selectedGenres}
          removeGenre={removeGenre}
        />

        <h5>Podcast List</h5>

        <InputGroup className="search-input">
          <Form.Control
            type="text"
            placeholder="Search for Podcasts by name..."
            name="podcast_id_search"
            ref={searchPodcastsRef}
            onChange={debouncedSearch}
            autoComplete="off"
            onFocus={() => setPodcastsFocused(true)}
            onBlur={() => setPodcastsFocused(false)}
            className="mb-2"
          />

          <FontAwesomeIcon icon="search" className="search" />
        </InputGroup>
      </div>

      <div className="body">
        {podcastResults.length === 0 &&
          get(searchPodcastsRef, 'current.value', '').length > 0 &&
          get(searchPodcastsRef, 'current.value', '').length <= 1 && (
            <Alert variant="info">You must enter at least two letters.</Alert>
          )}

        {podcastResults.length === 0 && get(searchPodcastsRef, 'current.value', '').length > 1 && (
          <Alert variant="info">No results are matching your search.</Alert>
        )}

        {get(searchPodcastsRef, 'current.value', '').length === 0 && podcastResults.length > 0 && (
          <span className="subtitle">The most popular Podcasts</span>
        )}

        {loading && <Loader />}

        {podcastResults.length > 0 && !loading && (
          <>
            <div className="podcast-results">
              {podcastResults.map(podcast => (
                <div className="podcast-details" key={podcast.id}>
                  <div className="details">
                    <div className="d-flex justify-content-between">
                      <span className="title">{podcast.title_original || podcast.title}</span>

                      {get(podcastMapping, `${podcast.id}.verified`, false) && (
                        <Badge variant="success">
                          <FontAwesomeIcon icon="check-circle" className="mr-1" />
                          <span>Verified</span>
                        </Badge>
                      )}
                    </div>

                    <div className="content">
                      <img
                        onClick={() => setPodcast(podcast)}
                        src={podcast.thumbnail || podcast.image}
                        alt="Podcast thumbnail"
                      />

                      <div className="description">
                        <TabletAndUp>
                          {(podcast.description_original || podcast.description).length <= 150 && (
                            <p>{podcast.description_original || podcast.description}</p>
                          )}
                        </TabletAndUp>

                        <TabletAndUp>
                          {(podcast.description_original || podcast.description).length > 150 &&
                            (podcast.description_original || podcast.description)
                              .trim()
                              .charAt(0) !== '<' && (
                              <p>
                                {(podcast.description_original || podcast.description).substr(
                                  0,
                                  150
                                )}
                                ...
                                <span onClick={() => setPodcast(podcast)} className="show-more">
                                  show more
                                </span>
                              </p>
                            )}
                        </TabletAndUp>

                        <MobileOnly>
                          {(podcast.description_original || podcast.description).length <= 50 && (
                            <p>{podcast.description_original || podcast.description}</p>
                          )}
                        </MobileOnly>

                        <MobileOnly>
                          {(podcast.description_original || podcast.description).length > 50 &&
                            (podcast.description_original || podcast.description)
                              .trim()
                              .charAt(0) !== '<' && (
                              <p>
                                {(podcast.description_original || podcast.description).substr(
                                  0,
                                  50
                                )}
                                ...
                                <span onClick={() => setPodcast(podcast)} className="show-more">
                                  show more
                                </span>
                              </p>
                            )}
                        </MobileOnly>

                        {(podcast.description_original || podcast.description).trim().charAt(0) ===
                          '<' && (
                          <p>
                            <span onClick={() => setPodcast(podcast)} className="show-more">
                              Click here
                            </span>{' '}
                            to see the full description.
                          </p>
                        )}

                        {campaigns.length > 0 ? (
                          <Dropdown>
                            <Dropdown.Toggle
                              as={Button}
                              variant="success"
                              style={{ width: 'fitContent' }}
                            >
                              <Mobile>Add/Remove</Mobile>
                              <Desktop>Add/Remove Campaign</Desktop>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {campaigns.map(campaign => (
                                <OverlayTrigger
                                  key={campaign.id}
                                  overlay={
                                    campaign.name.length >= 30 ? (
                                      <Tooltip>{campaign.name}</Tooltip>
                                    ) : (
                                      <Tooltip style={{ display: 'none' }} />
                                    )
                                  }
                                >
                                  <Dropdown.Item onSelect={() => handleClick(podcast, campaign)}>
                                    {campaign.targeted_podcast_ids.includes(podcast.id) ? (
                                      <>
                                        <span className="name">{campaign.name}</span>

                                        <span className="check-icon">
                                          <FontAwesomeIcon icon="check" />
                                        </span>
                                      </>
                                    ) : (
                                      <span className="name">{campaign.name}</span>
                                    )}
                                  </Dropdown.Item>
                                </OverlayTrigger>
                              ))}
                            </Dropdown.Menu>

                            {get(podcastMapping, `${podcast.id}.price`, 0) > 0 && (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Sponsorship Price</Tooltip>}
                              >
                                <span className="price">
                                  {formatPrice(get(podcastMapping, `${podcast.id}.price`, 0))}
                                </span>
                              </OverlayTrigger>
                            )}
                          </Dropdown>
                        ) : (
                          <>
                            <Default>
                              <Button
                                variant="success"
                                onClick={() => history.push('/campaigns/new')}
                              >
                                Create a Campaign so you can add the Podcast
                              </Button>
                            </Default>

                            <Mobile>
                              <Button
                                variant="success"
                                onClick={() => history.push('/campaigns/new')}
                              >
                                Create a Campaign
                              </Button>
                            </Mobile>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <PaginationFooter
              data={podcastResults}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              totalCount={totalCount}
              perPageOptions={[20]}
              title="Podcasts"
            />
          </>
        )}
      </div>
    </div>
  );
};

PodcastList.propTypes = {
  searchPodcastsRef: PropTypes.object.isRequired,
  searchGenresRef: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  setPodcastsFocused: PropTypes.func.isRequired,
  podcastResults: PropTypes.array.isRequired,
  getPodcasts: PropTypes.func.isRequired,
  podcastMapping: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedGenres: PropTypes.array,
  selectGenre: PropTypes.func.isRequired,
  removeGenre: PropTypes.func.isRequired,
  search: PropTypes.bool,
};

PodcastList.defaultProps = {
  selectedGenres: [],
  search: false,
};

export default PodcastList;
