import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import AuthorizationGate from 'components/AuthorizationGate';
import sponsorshipCta from 'images/sponsorship_cta.png';
import sponsorshipCtaRetina from 'images/sponsorship_cta@2x.png';

const CallToAction = ({ campaigns, hasResults }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/sponsorships/new');
  };

  return (
    <div className="call-to-action">
      <img
        src={sponsorshipCta}
        srcSet={`${sponsorshipCta} 1x, ${sponsorshipCtaRetina} 2x`}
        alt="No Sponsorships"
      />
      <h4>You have no Sponsorship opportunities yet</h4>

      <AuthorizationGate roles="podcaster">
        <div>
          <Link to="/podcasts/claim" className="create-link">
            Claim a Podcast
          </Link>{' '}
          to create Sponsorship opportunities.
        </div>
      </AuthorizationGate>

      <AuthorizationGate roles="account_manager, advertiser, admin">
        {campaigns.length === 0 ? (
          <div>
            <Link to="/campaigns/new" className="create-link">
              Create a Campaign
            </Link>{' '}
            so we can connect you to Podcast Sponsorships
          </div>
        ) : (
          <p>
            We’ve received your Campaign details and are searching for Sponsorship opportunities.
            <br />
            You will be notified when a Sponsorship is ready for your review.
          </p>
        )}
      </AuthorizationGate>

      {hasResults === false && campaigns.length > 0 && (
        <AuthorizationGate roles="admin, account_manager">
          <Button onClick={handleClick} variant="success" size="lg">
            Create Sponsorship
          </Button>
        </AuthorizationGate>
      )}
    </div>
  );
};

CallToAction.propTypes = {
  campaigns: PropTypes.array.isRequired,
  hasResults: PropTypes.bool.isRequired,
};

export default CallToAction;
