import React, { useEffect, useContext, useState, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isInvalid, normalizeInitialValues } from 'utils/forms';
import { updateResource, fetchResource } from 'utils/api';
import { AuthContext } from 'contexts';

const defaultRequired = 'is required.';
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(defaultRequired),
  first_name: yup.string().required(defaultRequired),
  last_name: yup.string().required(defaultRequired),
  phone: yup.number().required(defaultRequired),
  password: yup.string(),
  role: yup.string(),
  photo: yup
    .mixed()
    .test('fileSize', 'File Size is too large', value =>
      value ? value.size <= 10 * 1024 * 1024 : true
    )
    .test('fileType', 'Unsupported File Format', value =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    ),
});

const initialValues = {
  email: '',
  password: '',
  photo: undefined,
  first_name: '',
  last_name: '',
  phone: '',
  role: '',
};

const ProfileForm = () => {
  const { user: currentUser, setCurrentUser } = useContext(AuthContext);
  const [user, setUser] = useState(currentUser);
  const fileRef = useRef();

  useEffect(() => {
    fetchResource({
      type: 'users',
      id: user.id,
    }).then(user => setUser(user));
  }, [user.id]);

  const handleSubmit = (values, actions) => {
    const data = new FormData(); // eslint-disable-line no-undef
    Object.keys(values).forEach(key => data.set(`user[${key}]`, values[key]));

    updateResource({
      id: user.id,
      type: 'users',
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      successMessage: 'Successfully updated your profile.',
      ...actions,
    }).then(user => {
      setUser(user);
      setCurrentUser(user);
    });
  };

  const handleFileClick = () => fileRef.current.click();

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={normalizeInitialValues({ ...initialValues, ...user })}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Container className="ml-0">
            <Form noValidate onSubmit={handleSubmit} className="my-4 green-focus">
              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  isInvalid={isInvalid('first_name', { touched, errors })}
                  autoFocus
                />

                <Form.Label column sm={4}>
                  First Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'first_name')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  isInvalid={isInvalid('last_name', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Last Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'last_name')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={isInvalid('email', { touched, errors })}
                  required
                />

                <Form.Label column sm={4}>
                  Email
                </Form.Label>

                <Form.Control.Feedback type="invalid">{get(errors, 'email')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="number"
                  placeholder="Phone number"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  isInvalid={isInvalid('phone', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Phone number
                </Form.Label>

                <Form.Control.Feedback type="invalid">{get(errors, 'phone')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="file-wrapper">
                <div className="d-flex">
                  <div onClick={handleFileClick} className="content">
                    <FontAwesomeIcon icon="upload" size="lg" />
                    Choose some photo
                  </div>

                  {values.avatar_url ? (
                    <a href={values.avatar_url} target="_blank">
                      <div className="photo-preview">
                        <img src={values.avatar_url} alt="Profile" width="150" height="auto" />
                      </div>
                    </a>
                  ) : (
                    <div className="photo">Photo</div>
                  )}
                </div>

                <Form.Control
                  ref={fileRef}
                  type="file"
                  name="avatar"
                  onChange={e => {
                    setFieldValue('avatar', e.target.files[0]);
                  }}
                  isInvalid={isInvalid('avatar', { touched, errors })}
                />

                <div className="file-name">{get(values, 'avatar.name', '')}</div>

                <Form.Control.Feedback type="invalid">{get(errors, 'photo')}</Form.Control.Feedback>
              </Form.Group>

              <Button className="py-2 mt-4" type="submit" variant="success" block>
                Update Profile
              </Button>
            </Form>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default ProfileForm;
