import React, { useContext } from 'react';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import get from 'lodash/get';
import ReactGA from 'react-ga';

import { isInvalid } from 'utils/forms';
import { createResource } from 'utils/api';
import { AuthContext } from 'contexts';

const defaultRequired = 'is required.';

const schema = yup.object({
  first_name: yup.string().required(defaultRequired),
  last_name: yup.string().required(defaultRequired),
  company: yup.object({
    name: yup.string().required(defaultRequired),
    url: yup
      .string()
      .matches(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        'Invalid url format'
      )
      .required(defaultRequired),
  }),
  email: yup
    .string()
    .email('Invalid email')
    .required(defaultRequired),
  password: yup
    .string()
    .required(defaultRequired)
    .min(6, 'Must be at least 6 characters'),
});

const initialValues = {
  first_name: '',
  last_name: '',
  company: {
    name: '',
    url: '',
  },
  email: '',
  password: '',
};

const AdvertiserForm = () => {
  const history = useHistory();
  const { setCurrentUser } = useContext(AuthContext);

  const handleSubmit = ({ company, ...values }, actions) => {
    let { url } = company;

    if (url.substring(0, 8) === 'https://') {
      url = url.substring(8);
    }

    if (url.substring(0, 7) === 'http://') {
      url = url.substring(7);
    }

    const fullUrl = `http://${url}`;

    createResource({
      type: 'users',
      endpoint: 'sign_up',
      data: { user: { company_attributes: { ...company, url: fullUrl }, ...values } },
      baseUrl: false,
      successMessage:
        'Welcome! Please confirm your email and get started on creating your first campaign.',
      ...actions,
    }).then(user => {
      setCurrentUser(user);

      ReactGA.event({
        category: 'User',
        action: 'Signed Up',
        label: user.role,
        value: user.id,
      });

      history.push('/campaigns');
    });
  };

  return (
    <>
      <div className="user-btn">
        <NavLink to="/sign_up" exact activeClassName="active">
          <Button variant="primary">I'm an Advertiser</Button>
        </NavLink>

        <NavLink to="/sign_up/podcaster" exact activeClassName="active">
          <Button variant="outline-primary">I'm a Podcaster</Button>
        </NavLink>
      </div>

      <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Container>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  isInvalid={isInvalid('first_name', { touched, errors })}
                  autoFocus
                />

                <Form.Label column sm={4}>
                  First Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  isInvalid={isInvalid('last_name', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Last Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Company Name"
                  name="company.name"
                  value={values.company.name}
                  onChange={handleChange}
                  isInvalid={isInvalid('company.name', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Company Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'company.name')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="url"
                  placeholder="https://example.com"
                  name="company.url"
                  value={values.company.url}
                  onChange={handleChange}
                  isInvalid={isInvalid('company.url', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Company Url
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'company.url')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="email"
                  placeholder="example@example.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={isInvalid('email', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Email
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={isInvalid('password', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Password
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>

              <Button className="py-2 mt-4" type="submit" block>
                Sign Up
              </Button>
            </Form>

            <div className="bottom-text">
              Already a member?
              <Link to="/login">
                <span className="ml-1">Login</span>
              </Link>
            </div>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default AdvertiserForm;
