import { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import axios from 'utils/axios';

const usePaginatedData = ({
  endpoint,
  defaultQuery = {},
  effectEnabled = true,
  defaultPerPage = 15,
  callback = null,
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState(defaultQuery);
  const [hasResults, setHasResults] = useState(false);
  const [isLoading, setIsLoading] = useState(!!effectEnabled);

  const pageCount = Math.ceil(totalCount / perPage);

  const fetchData = useCallback(
    (overrideQuery = {}) => {
      setIsLoading(true);

      const queryString = qs.stringify(
        {
          ...query,
          ...overrideQuery,
          page,
          per_page: perPage,
        },
        { arrayFormat: 'brackets' }
      );

      return axios
        .get(`${endpoint}?${queryString}`)
        .then(({ data, headers, status }) => {
          setData(status === 204 ? [] : data);
          setHasResults(headers['has-results'] === 'true');

          const count = headers['pagination-total-count'] || 0;
          setTotalCount(+count);

          if (callback) callback(data);

          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setData([]);
        });
    },
    [endpoint, page, perPage, query, callback]
  );

  useEffect(() => {
    if (effectEnabled) {
      fetchData();
    }
  }, [query, page, perPage, effectEnabled, fetchData]);

  return {
    data,
    setData,
    page,
    setPage,
    perPage,
    setPerPage,
    totalCount,
    setTotalCount,
    query,
    setQuery,
    hasResults,
    fetchData,
    pageCount,
    isLoading,
  };
};

export default usePaginatedData;
