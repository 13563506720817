import React, { createContext, useState } from 'react';

import PodcastDetails from 'components/campaigns/PodcastDetails';
import 'styles/PodcastDetails.scss';

export const PodcastDetailsContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const PodcastDetailsContextProvider = ({ children }) => {
  const [podcast, setPodcast] = useState(null);

  return (
    <PodcastDetailsContext.Provider value={{ setPodcast }}>
      {children}

      <PodcastDetails podcast={podcast} setPodcast={setPodcast} />
    </PodcastDetailsContext.Provider>
  );
};
