import React, { useContext } from 'react';
import { Link, useHistory, matchPath, useLocation } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import AuthorizationGate from 'components/AuthorizationGate';
import { AuthContext } from 'contexts';
import { deleteResource } from 'utils/api';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import Container from 'react-bootstrap/Container';
import { HasResults } from 'contexts/HasResults';
import { NotificationsContext } from 'contexts/NotificationsContext';
import { MobileOnly, TabletAndUp } from 'utils/responsive';
import Menu from './Menu';
import Notifications from './Notifications';

const Header = () => {
  const { setPodcast } = useContext(PodcastDetailsContext);
  const { user, setCurrentUser } = useContext(AuthContext);
  const { notifications } = useContext(NotificationsContext);
  const { campaignsHasResults, sponsorshipsHasResults, podcastsHasResults } = useContext(
    HasResults
  );
  const history = useHistory();
  const location = useLocation();
  const matchNew = matchPath(location.pathname, { path: '/sponsorships/new' });
  const matchEdit = matchPath(location.pathname, { path: '/sponsorships/:id/edit' });
  const matchSettings = matchPath(location.pathname, { path: '/settings' });
  const matchSponsorships = matchPath(location.pathname, { path: '/sponsorships', exact: true });
  const matchCampaigns = matchPath(location.pathname, { path: '/campaigns', exact: true });
  const matchPodcasts = matchPath(location.pathname, { path: '/podcasts', exact: true });
  const matchDetails = matchPath(location.pathname, { path: '/sponsorships/:id/details' });

  // eslint-disable-next-line no-undef
  const matchUrl = window.location.pathname;

  const capitalizeTitle = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  const subStringBeforeChar = str => {
    const capitalized = capitalizeTitle(str);

    return capitalized.split('/', 1);
  };

  const handleClick = () => {
    if (user.role === 'advertiser') {
      history.push('/settings/company');
    }
    if (user.role === 'podcaster') {
      history.push('/settings/payment');
    }
  };

  const handleLogOut = () => {
    deleteResource({
      type: 'users',
      id: user.id,
      endpoint: 'sign_out',
      baseUrl: false,
      message: 'Successfully logged out.',
    }).then(() => {
      setCurrentUser({});
      setPodcast(null);
      history.push('/login');
    });
  };

  return (
    <Container>
      <Col
        className={
          (matchSponsorships && sponsorshipsHasResults) || matchSettings
            ? 'border-0 header main-header'
            : 'header main-header'
        }
      >
        <div className="left-side">
          <Menu />

          {matchDetails || matchEdit || matchNew ? (
            <Link to="/sponsorships" className="back-to">
              <FontAwesomeIcon icon="chevron-left" className="mr-2" />
              <MobileOnly>Back</MobileOnly>
              <TabletAndUp>Back to Sponsorships</TabletAndUp>
            </Link>
          ) : (
            <h4
              className={
                (matchCampaigns && user.role === 'advertiser') ||
                (matchSponsorships && (user.role === 'admin' || user.role === 'account_manager')) ||
                (matchPodcasts && user.role === 'podcaster')
                  ? 'hide'
                  : 'show'
              }
            >
              {matchUrl === '/' && 'Dashboard'}
              {matchUrl === '/podcasts/claim' ? 'Podcast Claims' : subStringBeforeChar(matchUrl)}
            </h4>
          )}
        </div>

        <div className="right-side">
          {matchUrl === '/podcasts' && user.role === 'podcaster' && podcastsHasResults && (
            <Button onClick={() => history.push('/podcasts/claim')} variant="success">
              Claim Podcast
            </Button>
          )}

          {matchUrl === '/campaigns' && user.role === 'advertiser' && campaignsHasResults && (
            <Button
              onClick={() => history.push('/campaigns/new')}
              className={notifications.length > 0 ? 'mr-3' : 'mr-0'}
              variant="success"
            >
              Create Campaign
            </Button>
          )}

          {matchUrl === '/sponsorships' &&
            (user.role === 'admin' || user.role === 'account_manager') &&
            sponsorshipsHasResults && (
              <Button
                onClick={() => history.push('/sponsorships/new')}
                className={notifications.length > 0 ? 'mr-3' : 'mr-0'}
                variant="success"
              >
                Create Sponsorship
              </Button>
            )}

          <a href="mailto: support@sponsored.us">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Questions? Email support@sponsored.us</Tooltip>}
            >
              <FontAwesomeIcon style={{ marginLeft: 20 }} icon="question" />
            </OverlayTrigger>
          </a>

          <Notifications />

          <Dropdown>
            <Dropdown.Toggle variant="">
              {user.avatar_url ? (
                <Image src={user.avatar_url} roundedCircle className="avatar" />
              ) : (
                <FontAwesomeIcon icon="user-circle" />
              )}

              <div className="user">
                <span>{user.first_name}</span>
                <span>{user.last_name}</span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push('/settings/profile')}>
                Profile
              </Dropdown.Item>

              <AuthorizationGate roles="advertiser, podcaster">
                <Dropdown.Item onClick={handleClick}>Settings</Dropdown.Item>
              </AuthorizationGate>

              <hr />

              <Button as={Dropdown.Item} onClick={handleLogOut}>
                <span>Log Out</span>
              </Button>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
    </Container>
  );
};

export default Header;
