import React, { useContext, useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

import { AuthContext } from 'contexts';
import { fetchResources } from 'utils/api';
import AuthorizationGate from 'components/AuthorizationGate';
import Loader from 'components/Loader';
import TransactionHistory from './TransactionHistory';
import StripeConnect from './StripeConnect';

const PaymentPage = () => {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);

    fetchResources({ type: 'payments' })
      .then(payments => {
        setIsLoading(false);
        setPayments(payments);
      })
      .catch(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {user.role === 'podcaster' && <StripeConnect />}
      {payments.length > 0 && !isLoading && <TransactionHistory payments={payments} />}

      <AuthorizationGate roles="advertiser">
        {payments.length === 0 && !isLoading && (
          <Alert variant="info">There are no payment transactions yet.</Alert>
        )}
      </AuthorizationGate>

      <AuthorizationGate roles="podcaster">
        {payments.length === 0 && user.stripe_connected && (
          <Alert variant="info">There are no payment transactions yet.</Alert>
        )}
      </AuthorizationGate>
    </>
  );
};

export default PaymentPage;
