import get from 'lodash/get';
import qs from 'qs';
import swal from 'sweetalert';
import startCase from 'lodash/startCase';
import axios from 'utils/axios';
import toast from 'utils/toast.jsx';

const API_VERSION = '/api/v1';
const HEADERS = { 'Content-Type': 'application/json' };

const resourceName = type => startCase(type.slice(0, -1));

export const handleError = ({ error, errorMessage, groupErrors = false, setErrors = null }) => {
  const { response } = error;
  const { error: serverError } = response.data;

  if (serverError) {
    toast('error', serverError);
    return;
  }

  if (errorMessage) {
    toast('error', errorMessage);
    return;
  }

  if (response.status === 401) {
    toast('error', 'You are not authorized to perform that action.');
    return;
  }

  if (response.status > 401 && response.status < 500) {
    const { errors } = response.data;

    if (Array.isArray(errors)) {
      if (groupErrors) {
        const fullMessages = errors.map(message => message).join('<br />');
        toast('error', fullMessages);
      } else {
        errors.map(message => toast('error', message));
      }
    } else {
      const keys = Object.keys(errors);

      if (keys) {
        if (setErrors) {
          toast('error', 'Please fix the form errors');
          setErrors(errors);
        } else if (groupErrors) {
          const fullMessages = keys
            .map(key =>
              key === 'base'
                ? errors[key][0]
                : `<strong>${startCase(key)}:</strong> ${errors[key][0]}`
            )
            .join('<br />');

          toast('error', fullMessages);
        } else {
          keys.forEach(key => {
            const message = key === 'base' ? errors[key][0] : `${key} ${errors[key][0]}`;
            toast('error', message);
          });
        }
      } else {
        toast('error', 'There was an error processing your request.');
      }
    }
  }
};

export const createResource = ({
  type,
  data,
  config = { headers: HEADERS },
  endpoint = '',
  successMessage = '',
  errorMessage = '',
  setSubmitting = null,
  setErrors = null,
  groupErrors = false,
  baseUrl = true,
  showSuccessMessage = true,
}) => {
  const resourcePath = endpoint || type;
  const url = `${baseUrl ? API_VERSION : ''}/${resourcePath}`;

  if (setSubmitting) setSubmitting(true);

  return axios
    .post(url, data, config)
    .then(response => {
      const msg = successMessage || `Successfully created ${resourceName(type)}.`;

      if (setSubmitting) setSubmitting(false);
      if (showSuccessMessage) toast('success', msg);

      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (setSubmitting) setSubmitting(false);

      handleError({
        error,
        errorMessage,
        setErrors,
        groupErrors,
      });

      return Promise.reject(error);
    });
};

export const fetchResource = ({ type, id, query, endpoint = '' }) => {
  const resourcePath = endpoint || `${type}/${id}`;
  const baseEndpoint = `${API_VERSION}/${resourcePath}`;
  const queryString = query ? qs.stringify(query) : '';
  const url = queryString ? `${baseEndpoint}?${queryString}` : baseEndpoint;

  return axios
    .get(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const fetchResources = ({ type, query, endpoint = '', headers = {} }) => {
  const resourcePath = endpoint || type;
  const queryString = query ? qs.stringify(query, { arrayFormat: 'brackets' }) : '';
  const url = queryString
    ? `${API_VERSION}/${resourcePath}?${queryString}`
    : `${API_VERSION}/${resourcePath}`;

  return axios
    .get(url, { headers })
    .then(response => {
      const data = response.status === 204 ? [] : response.data;
      return Promise.resolve(data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const updateResource = ({
  id,
  type,
  data,
  config = { headers: HEADERS },
  endpoint = '',
  successMessage = '',
  errorMessage = '',
  setSubmitting = null,
  setErrors = null,
  method = 'PUT',
  groupErrors = false,
  showSuccessMessage = true,
}) => {
  const resourcePath = endpoint || `${type}/${id}`;
  const url = `${API_VERSION}/${resourcePath}`;

  if (setSubmitting) setSubmitting(true);

  return axios({
    url,
    data,
    method,
    ...config,
  })
    .then(response => {
      const msg = successMessage || `Successfully updated ${resourceName(type)}`;

      if (setSubmitting) setSubmitting(false);
      if (showSuccessMessage) toast('success', msg);

      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (setSubmitting) setSubmitting(false);

      handleError({
        error,
        errorMessage,
        setErrors,
        groupErrors,
      });
      return Promise.reject(get(error, 'response.data.errors', error));
    });
};

export const deleteResource = ({ type, id, endpoint = '', baseUrl = true, message = '' }) => {
  const url = endpoint
    ? `${baseUrl ? API_VERSION : ''}/${endpoint}`
    : `${baseUrl ? API_VERSION : ''}/${type}/${id}`;

  return new Promise((resolve, reject) => {
    swal({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then(result => {
      if (!result) return;

      axios
        .delete(url)
        .then(() => {
          const msg = message || `${resourceName(type)} deleted successfully.`;

          toast('success', msg);

          resolve();
        })
        .catch(error => {
          handleError({ error, errorMessage: '' });
          reject(get(error, 'response.data.errors', error));
        });
    });
  });
};

export const confirmationRequest = ({
  title = 'Are you sure?',
  buttons = ['No', 'Yes'],
  icon = 'warning',
  successMessage = '',
  errorMessage = '',
  url,
  method,
  data = {},
}) => dispatch =>
  new Promise((resolve, reject) => {
    swal({
      title,
      text: '',
      icon,
      buttons,
    }).then(result => {
      if (!result) return;

      axios({ url: `${API_VERSION}/${url}`, method, data })
        .then(response => {
          if (successMessage) toast('success', successMessage);
          resolve(response.data);
        })
        .catch(error => {
          handleError({ error, dispatch, errorMessage });
          reject(get(error, 'response.data.errors', error));
        });
    });
  });

export const sweetAlert = ({
  title,
  text = '',
  icon = 'warning',
  callback = null,
  buttons = ['No', 'Yes'],
}) =>
  swal({
    title,
    text,
    icon,
    buttons,
  }).then(result => {
    if (!result) return;

    callback();
  });
