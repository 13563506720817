import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from 'contexts/';

const authPages = [
  '/sign_up',
  '/sign_up/podcaster',
  '/login',
  '/forgot-password',
  '/reset-password',
  '/resend-confirmation',
];

const AuthenticationGate = ({ children, redirect }) => {
  const location = useLocation();
  const authPage = authPages.includes(location.pathname);
  const other = redirect && !authPage ? <Redirect to="/login" /> : null;

  return (
    <AuthContext.Consumer>{({ user }) => (isEmpty(user) ? other : children)}</AuthContext.Consumer>
  );
};

AuthenticationGate.propTypes = {
  children: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
};

AuthenticationGate.defaultProps = {
  redirect: true,
};

export default AuthenticationGate;
