import React, { useContext } from 'react';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga';

import { isInvalid } from 'utils/forms';
import { createResource } from 'utils/api';
import { AuthContext } from 'contexts';

const defaultRequired = 'is required.';

const schema = yup.object({
  first_name: yup.string().required(defaultRequired),
  last_name: yup.string().required(defaultRequired),
  email: yup.string().required(defaultRequired),
  password: yup
    .string()
    .required(defaultRequired)
    .min(6, 'Must be at least 6 characters'),
});

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
};

const PodcasterForm = () => {
  const { setCurrentUser } = useContext(AuthContext);
  const history = useHistory();

  const handleSubmit = (values, actions) => {
    createResource({
      type: 'users',
      data: { user: { ...values, role: 'podcaster' } },
      baseUrl: false,
      endpoint: 'sign_up',
      successMessage: 'Successfully signed up.',
      ...actions,
    }).then(user => {
      setCurrentUser(user);

      ReactGA.event({
        category: 'User',
        action: 'Signed Up',
        label: user.role,
        value: user.id,
      });

      history.push('/podcasts');
    });
  };

  return (
    <>
      <div className="user-btn">
        <NavLink to="/sign_up" exact activeClassName="active">
          <Button variant="outline-primary">I'm an Advertiser</Button>
        </NavLink>

        <NavLink to="/sign_up/podcaster" exact activeClassName="active">
          <Button variant="primary">I'm a Podcaster</Button>
        </NavLink>
      </div>

      <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Container>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  isInvalid={isInvalid('first_name', { touched, errors })}
                  autoFocus
                />
                <Form.Label column sm={4}>
                  First Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  isInvalid={isInvalid('last_name', { touched, errors })}
                />
                <Form.Label column sm={4}>
                  Last Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="example@example.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={isInvalid('email', { touched, errors })}
                />
                <Form.Label column sm={4}>
                  Email
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={isInvalid('password', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Password
                </Form.Label>

                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>

              <Button className="py-2 mt-4" type="submit" block>
                Sign Up
              </Button>
            </Form>

            <div className="bottom-text">
              Already a member?
              <Link to="/login">
                <span className="ml-1">Login</span>
              </Link>
            </div>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default PodcasterForm;
