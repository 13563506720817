import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'react-bootstrap/Badge';

import { updateResource } from 'utils/api';
import { getIconColor, getNotificationIcon } from 'utils/notifications';
import { NotificationsContext } from 'contexts/NotificationsContext';

const Notifications = () => {
  const { notifications, setNotifications } = useContext(NotificationsContext);
  const history = useHistory();

  const removeNotification = id => {
    updateResource({
      type: 'notifications',
      endpoint: `notifications/${id}/mark_as_read`,
      successMessage: 'Successfully marked notification as read.',
    }).then(() => {
      setNotifications(state => state.filter(value => value.id !== id));
    });
  };

  const removeNotificationOnView = id => {
    updateResource({
      type: 'notifications',
      endpoint: `notifications/${id}/mark_as_read`,
      showSuccessMessage: false,
    }).then(() => {
      setNotifications(state => state.filter(value => value.id !== id));
    });
  };

  const getNumOfNotifications = notifications => {
    const numOfNotifications = notifications.length;

    if (numOfNotifications === 0) {
      return null;
    }
    return numOfNotifications;
  };

  return (
    <>
      <Dropdown id="bell" className={notifications.length > 0 && 'mr-n3'}>
        <Dropdown.Toggle variant="">
          <FontAwesomeIcon icon="bell" size="lg" />

          <Badge variant="secondary" className="notif-badge">
            {getNumOfNotifications(notifications)}
          </Badge>
        </Dropdown.Toggle>

        <Dropdown.Menu className={notifications.length === 0 ? 'no-notifications' : ''}>
          {notifications.length === 0 ? (
            <Alert variant="info" className="mb-0 text-center">
              You have no notifications.
            </Alert>
          ) : (
            <>
              {notifications.slice(0, 5).map(notification => (
                <Dropdown.Item className="d-flex flex-column" key={notification.id}>
                  <div>
                    <FontAwesomeIcon
                      icon={getNotificationIcon(notification.kind)}
                      style={{ color: getIconColor(notification.kind) }}
                      className="mr-2"
                    />
                    {notification.action}
                  </div>

                  <div className="d-flex justify-content-between">
                    <span
                      onClick={e => {
                        e.stopPropagation();
                        removeNotification(notification.id);
                      }}
                      className="read"
                    >
                      Mark as Read
                    </span>

                    <span
                      onClick={() => {
                        if (notification.kind === 'message')
                          removeNotificationOnView(notification.id);
                        history.push(notification.link);
                      }}
                      className="read"
                    >
                      View Details
                    </span>
                  </div>
                </Dropdown.Item>
              ))}

              <hr />

              <Dropdown.Item onClick={() => history.push('/')}>
                <span className="text-success">Show more...</span>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Notifications;
