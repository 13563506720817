import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import qs from 'qs';
import get from 'lodash/get';

import usePaginatedData from 'hooks/usePaginatedData';
import { fetchResources } from 'utils/api';
import { Alert } from 'react-bootstrap';
import { TabletAndUp } from 'utils/responsive';
import messagesCta from 'images/messages_cta.png';
import messagesCtaRetina from 'images/messages_cta@2x.png';
import CallToAction from '../CallToAction';
import ChatSidebar from './ChatSidebar';
import ChatBox from './ChatBox';
import 'styles/ConversationsPage.scss';

const defaultCountState = {
  unread_sponsorship_messages_count: 0,
  unread_conversation_messages_count: 0,
};

const ConversationsPage = () => {
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const location = useLocation();
  const { conversation_type } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [activeTab, setActiveTab] = useState(conversation_type || 'Conversation');
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(defaultCountState);
  const history = useHistory();
  const searchConversationsRef = useRef();
  const match = useRouteMatch('/conversations/:id');
  const id = get(match, 'params.id');

  const { ...paginationProps } = usePaginatedData({
    endpoint: '/api/v1/conversations',
    effectEnabled: activeTab === 'Conversation',
    defaultQuery: { conversation_type: 'Conversation' },
    callback: useCallback(
      data => {
        if (activeTab === 'Conversation') {
          setConversations(data);
          setFilteredConversations(data);
        }
      },
      [activeTab, setConversations, setFilteredConversations]
    ),
  });

  const changeActiveTab = tab => {
    setActiveTab(tab);
    setFilteredConversations([]);
    searchConversationsRef.current.value = '';
  };

  const handleSearch = value => {
    const filtered = conversations.filter(c => {
      if (activeTab === 'Conversation') {
        return c.recipient.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      }

      return c.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });

    setFilteredConversations(filtered);
  };

  const handleConversationClick = id => {
    setConversations(prev =>
      prev.map(conversation =>
        conversation.id === id ? { ...conversation, unread_messages_count: 0 } : conversation
      )
    );

    setFilteredConversations(prev =>
      prev.map(conversation =>
        conversation.id === id ? { ...conversation, unread_messages_count: 0 } : conversation
      )
    );

    if (activeTab === 'Conversation') {
      history.push(`/conversations/${id}`);
    } else {
      history.push(`/conversations/${id}?conversation_type=Sponsorship`);
    }
  };

  useEffect(() => {
    if (conversation_type === 'Sponsorship' && activeTab !== 'Sponsorship') {
      setActiveTab('Sponsorship');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchResources({ type: 'conversations/unread_messages_count' }).then(count => {
      setUnreadMessagesCount(count);
    });
  }, []);

  useEffect(() => {
    if (activeTab === 'Conversation' && conversations.length > 0) {
      const { type, id: cId } = conversations[0];

      if ((conversation_type === 'Sponsorship' && type === 'Conversation' && id) || !id) {
        history.push(`/conversations/${cId}`);
      }
    }
  }, [conversations, activeTab, conversation_type, history, id]);

  return (
    <Container className="conversations-page">
      {conversations.length === 0 && activeTab === 'Conversation' ? (
        <Route path="/conversations" exact>
          <CallToAction
            title="You have no Conversations yet"
            imgSrc={messagesCta}
            retinaImg={messagesCtaRetina}
            alt="No Messages"
          />
        </Route>
      ) : (
        <Row noGutters>
          <TabletAndUp>
            <Col className="chat-section" sm={4}>
              <ChatSidebar
                conversations={filteredConversations}
                setConversations={setConversations}
                setFilteredConversations={setFilteredConversations}
                handleSearch={handleSearch}
                handleConversationClick={handleConversationClick}
                searchConversationsRef={searchConversationsRef}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
                unreadMessagesCount={unreadMessagesCount}
                paginationProps={paginationProps}
              />
            </Col>
          </TabletAndUp>

          {conversations.length === 0 && activeTab === 'Sponsorship' && (
            <Col className="chat-box" style={{ padding: '20px 30px' }} sm={8}>
              <Alert variant="warning">You have no Sponsorships yet.</Alert>
            </Col>
          )}

          <Route path="/conversations/:id" exact>
            <Col className="chat-section" sm={8}>
              <ChatBox
                conversations={filteredConversations}
                setConversations={setConversations}
                setFilteredConversations={setFilteredConversations}
                handleSearch={handleSearch}
                handleConversationClick={handleConversationClick}
                searchConversationsRef={searchConversationsRef}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
                unreadMessagesCount={unreadMessagesCount}
                paginationProps={paginationProps}
              />
            </Col>
          </Route>
        </Row>
      )}
    </Container>
  );
};

export default ConversationsPage;
