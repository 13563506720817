import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import { formatPrice } from 'utils/forms';
import { get } from 'lodash';
import PaginationFooter from 'components/PaginationFooter';

const LaunchedSponsorships = ({ launchedSponsorships, ...rest }) => {
  const history = useHistory();

  const getConversionRate = sponsorship => {
    const visits = get(sponsorship, 'total_metrics.pageView', 0);
    const conversions = get(sponsorship, 'total_metrics.conversion', 0);

    if (conversions > 0 && visits > 0) {
      const rate = (conversions / visits) * 100;
      return rate.toFixed(2);
    }

    return 0;
  };

  return (
    <div className="sponsorships-body">
      {launchedSponsorships.length > 0 && (
        <Table className="sponsorships-list" responsive>
          <thead>
            <tr>
              <th>Sponsorship Name</th>
              <th>Campaign Name</th>
              <th>Price</th>
              <th>Visits</th>
              <th>Conversions</th>
              <th>Conversion rate</th>
            </tr>
          </thead>

          <tbody className="table-body">
            {launchedSponsorships.map(sponsorship => (
              <tr
                onClick={() => history.push(`/reporting/${sponsorship.id}/details`)}
                className="table-row"
                key={sponsorship.id}
              >
                <td>{sponsorship.name}</td>
                <td>{sponsorship.campaign_name}</td>
                <td>{formatPrice(sponsorship.price)}</td>
                <td>{get(sponsorship, 'total_metrics.pageView', 0)}</td>
                <td>{get(sponsorship, 'total_metrics.conversion', 0)}</td>
                <td>{getConversionRate(sponsorship)}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <PaginationFooter data={launchedSponsorships} title="Sponsorships" {...rest} />
    </div>
  );
};

LaunchedSponsorships.propTypes = {
  launchedSponsorships: PropTypes.array.isRequired,
};

export default LaunchedSponsorships;
