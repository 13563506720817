import React, { Fragment, useEffect, useState, useRef, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import qs from 'qs';
import startCase from 'lodash/startCase';

import { fetchResources, fetchResource, createResource } from 'utils/api';
import { AuthContext } from 'contexts';
import { getStatusColor } from 'utils/conversations';
import MenuMsg from 'components/MenuMsg';
import Loader from 'components/Loader';
import Message from './Message';

const ChatBox = ({ paginationProps, ...rest }) => {
  const { id } = useParams();
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const inputRef = useRef();
  const bodyRef = useRef();
  const unreadMessagesRef = useRef();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { conversation_type } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    fetchResource({ type: 'conversations', id, query: { conversation_type } }).then(conversation =>
      setConversation(conversation)
    );

    fetchResources({
      type: 'messages',
      endpoint: `conversations/${id}/messages`,
      query: { conversation_type },
    }).then(messages => {
      setMessages(messages);
      setMessagesLoaded(true);
    });
  }, [id, conversation_type]);

  const createMessage = () => {
    createResource({
      type: 'conversations',
      endpoint: `conversations/${id}/messages?conversation_type=${conversation_type}`,
      showSuccessMessage: false,
      data: { body: inputRef.current.value },
    }).then(message => {
      setMessages(prev => [...prev, message]);
      inputRef.current.value = '';
    });
  };

  useEffect(() => {
    if (unreadMessagesRef.current) {
      unreadMessagesRef.current.scrollIntoView(true);
      return;
    }

    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [messages, messagesLoaded]);

  useEffect(() => {
    setMessagesLoaded(false);
  }, [id, conversation_type]);

  const status = get(conversation, 'status', '');

  return (
    <div className="chat-box">
      <div className="header">
        <MenuMsg {...rest} paginationProps={paginationProps} />

        {conversation_type === 'Sponsorship' ? (
          <div style={{ width: '100%' }}>
            <div className="d-flex justify-content-between">
              <span>
                Sponsorship:
                <span className="spons-name"> {get(conversation, 'name', '')}</span>
              </span>

              <Badge className="spons-status" variant={getStatusColor(status)}>
                {startCase(status)}
              </Badge>
            </div>

            {conversation && conversation.users && (
              <div className="users">
                {conversation.users
                  .split(', ')
                  .filter(name => name !== user.name)
                  .map(name => (
                    <span key={name}>
                      {name}
                      {conversation.users.length > 1 && ', '}
                    </span>
                  ))}
                You
              </div>
            )}
          </div>
        ) : (
          <div>
            Chat with: <strong>{get(conversation, 'recipient.name', '')}</strong>
          </div>
        )}
      </div>

      <div
        className={conversation_type === 'Sponsorship' ? 'body body-spons' : 'body'}
        ref={bodyRef}
      >
        {messagesLoaded ? (
          messages.map((message, index) =>
            get(conversation, 'unread_messages_count', 0) > 0 &&
            index === messages.length - get(conversation, 'unread_messages_count', 0) ? (
              <Fragment key={index}>
                <span ref={unreadMessagesRef} className="text-center pt-2">
                  See the unread messages below
                </span>
                <hr />
                <Message
                  user={user}
                  message={message}
                  showRole={conversation_type === 'Sponsorship'}
                />
              </Fragment>
            ) : (
              <Message
                user={user}
                message={message}
                showRole={conversation_type === 'Sponsorship'}
                key={index}
              />
            )
          )
        ) : (
          <Loader />
        )}
      </div>

      <div className="send">
        <Form.Group as={Col} md={10}>
          <Form.Control
            ref={inputRef}
            type="text"
            as="textarea"
            placeholder="Type in your message..."
            name="search"
            autoComplete="off"
            autoFocus
            onKeyDown={e => {
              if (e.keyCode === 13 && status !== 'cancelled' && !e.shiftKey) {
                createMessage();
              }
            }}
            disabled={status === 'cancelled'}
          />
        </Form.Group>

        <Button variant="success" onClick={createMessage} disabled={status === 'cancelled'} block>
          Send
        </Button>
      </div>
    </div>
  );
};

ChatBox.propTypes = {
  paginationProps: PropTypes.object.isRequired,
};

export default ChatBox;
