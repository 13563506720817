import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useListenNotesGenres from 'hooks/useListeNotesGenres';
import { MobileOnly } from 'utils/responsive';

const defaultGenres = [
  { id: 127, name: 'Technology', parent_id: 67 },
  { id: 139, name: 'VR & AR', parent_id: 127 },
  { id: 140, name: 'Web Design', parent_id: 127 },
  { id: 143, name: 'Programming', parent_id: 127 },
  { id: 163, name: 'AI & Data Science', parent_id: 127 },
  { id: 136, name: 'Crypto & Blockchain', parent_id: 127 },
  { id: 164, name: 'Apple', parent_id: 127 },
  { id: 130, name: 'Gadgets', parent_id: 127 },
  { id: 157, name: 'Startup', parent_id: 93 },
];

const Sidebar = ({
  searchGenresRef,
  selectGenre,
  selectedGenres,
  removeGenre,
  handleClickOpen,
}) => {
  const genres = useListenNotesGenres();
  const [filteredGenres, setFilteredGenres] = useState([]);

  const handleSearch = value => {
    const filtered = genres.filter(g => g.name.toLowerCase().indexOf(value.toLowerCase()) > -1);

    setFilteredGenres(filtered);
  };

  useEffect(() => {
    handleSearch('');
    selectGenre(defaultGenres[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="genres">
      <MobileOnly>
        <FontAwesomeIcon
          icon="times"
          onClick={e => {
            e.stopPropagation();
            handleClickOpen();
          }}
          className="close-icon"
        />
      </MobileOnly>

      <InputGroup className="search-input">
        <Form.Control
          type="text"
          placeholder="Search by Genres"
          name="genres"
          ref={searchGenresRef}
          onChange={e => handleSearch(e.target.value)}
          autoComplete="off"
          autoFocus
        />

        <FontAwesomeIcon icon="search" className="search" />
      </InputGroup>

      {selectedGenres.length > 0 && <span className="subtitle">Selected Genres:</span>}

      {selectedGenres.length === 0 && filteredGenres.length === 0 && (
        <Alert variant="info" className="mt-2">
          No results are matching your search.
        </Alert>
      )}

      {selectedGenres.length > 0 && filteredGenres.length === 0 && (
        <Alert variant="info" className="mt-3">
          No results are matching your search.
        </Alert>
      )}

      <div className="selected-genres">
        {selectedGenres.length > 0 &&
          selectedGenres.map(genre => (
            <div className="selected-genre" key={genre.id}>
              <Badge variant="success">
                {genre.name}
                <FontAwesomeIcon icon="times" onClick={() => removeGenre(genre)} />
              </Badge>
            </div>
          ))}
      </div>

      <div className="genre-list">
        {get(searchGenresRef, 'current.value', '').length === 0
          ? defaultGenres
              .filter(genre => !selectedGenres.includes(genre))
              .map(genre => (
                <div className="genre" key={genre.id} onClick={() => selectGenre(genre)}>
                  {genre.name}
                </div>
              ))
          : filteredGenres
              .filter(genre => !selectedGenres.includes(genre))
              .slice(0, 10)
              .map(genre => (
                <div className="genre" key={genre.id} onClick={() => selectGenre(genre)}>
                  {genre.name}
                </div>
              ))}
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  handleClickOpen: null,
};

Sidebar.propTypes = {
  searchGenresRef: PropTypes.object.isRequired,
  selectedGenres: PropTypes.array.isRequired,
  selectGenre: PropTypes.func.isRequired,
  removeGenre: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func,
};

export default Sidebar;
