const { location } = window; // eslint-disable-line no-undef
const { host, protocol } = location;
const url = `${protocol}//${host}`;

export const getPageViewPixel = id => {
  return `<script
  src="${url}/analytics.js"
  data-advertiser-id="${id}"
  data-event-type="pageView"
  data-sponsored-script
></script>`;
};

export const getConversionPixel = id => {
  return `<script
  src="${url}/analytics.js"
  data-advertiser-id="${id}"
  data-event-type="conversion"
  data-sponsored-script
></script>`;
};
