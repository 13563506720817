import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import get from 'lodash/get';
import { AuthContext } from 'contexts/';

const AuthorizationGate = ({ roles, as: Wrapper, redirect, children }) => {
  const normalizedRoles = Array.isArray(roles) ? roles : roles.split(', ');
  const show = Wrapper ? <Wrapper>{children}</Wrapper> : children;
  const hide = redirect ? <Redirect to="/users/home" /> : null;

  return (
    <AuthContext.Consumer>
      {({ user }) => {
        if (normalizedRoles.includes(get(user, 'role'))) {
          return show;
        }

        return hide;
      }}
    </AuthContext.Consumer>
  );
};

AuthorizationGate.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  redirect: PropTypes.bool,
};

AuthorizationGate.defaultProps = {
  as: null,
  redirect: false,
};

export default AuthorizationGate;
