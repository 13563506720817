import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from 'contexts';

const DropzoneField = ({ values, setFieldValue }) => {
  const { user } = useContext(AuthContext);

  return (
    <Dropzone
      onDrop={acceptedFiles => setFieldValue('attachments', acceptedFiles)}
      disabled={user.role === 'podcaster'}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />

            <div className="upload-icon">
              <FontAwesomeIcon icon="upload" size="lg" />
            </div>

            {values.attachments.length === 0 ? (
              <span>Drag 'n' drop some files here, or click to select files</span>
            ) : (
              <span className="files">
                {values.attachments.map(attachment => attachment.path).join(', ')}
              </span>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

DropzoneField.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default DropzoneField;
