import React, { createContext } from 'react';

import usePaginatedData from 'hooks/usePaginatedData';
import ActionCable from 'components/ActionCable/Cable';

export const NotificationsContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const NotificationsContextProvider = ({ children }) => {
  const { data: notifications, setData: setNotifications, ...rest } = usePaginatedData({
    endpoint: '/api/v1/notifications',
  });

  const onReceived = notification => {
    setNotifications(state => [...state, notification]);
  };

  return (
    <>
      <ActionCable
        channel={{
          channel: 'NotificationChannel',
        }}
        onReceived={onReceived}
      />

      <NotificationsContext.Provider value={{ notifications, setNotifications, ...rest }}>
        {children}
      </NotificationsContext.Provider>
    </>
  );
};
