import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

import { formatPrice } from 'utils/forms';

const TransactionHistory = ({ payments }) => {
  return (
    <>
      <h5 style={{ marginTop: 10, marginBottom: 0, fontWeight: 600 }}>Transaction History</h5>

      <hr className="mb-0" />

      <Table responsive>
        <thead>
          <tr>
            <th>No.</th>
            <th>Date</th>
            <th>Sponsorship</th>
            <th>Amount</th>
          </tr>
        </thead>

        <tbody className="table-body">
          {payments.map((payment, index) => (
            <tr className="table-row" key={index}>
              <td>{index + 1}</td>
              <td>{moment(payment.created_at).format('Do MMM, YYYY HH:mm')}</td>
              <td>{payment.sponsorship_name}</td>
              <td>{formatPrice(payment.amount)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

TransactionHistory.propTypes = {
  payments: PropTypes.array.isRequired,
};

export default TransactionHistory;
