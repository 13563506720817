import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import startCase from 'lodash/startCase';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useListenNotesGenres from 'hooks/useListeNotesGenres';
import usePaginatedData from 'hooks/usePaginatedData';
import { deleteResource, updateResource, sweetAlert } from 'utils/api';
import CallToAction from 'components/CallToAction';
import PaginationFooter from 'components/PaginationFooter';
import { HasResults } from 'contexts/HasResults';
import campaignCta from 'images/campaign_cta.png';
import campaignCtaRetina from 'images/campaign_cta@2x.png';
import Loader from 'components/Loader';
import AutorizationGate from '../AuthorizationGate';
import 'styles/CampaignsList.scss';
import WhatNext from './WhatNext';

const CampaignsList = () => {
  const [open, setOpen] = useState(null);
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState('');
  const { setCampaignsHasResults } = useContext(HasResults);
  const history = useHistory();
  const currentActiveKey = useAccordionToggle();
  const searchCampaignRef = useRef();
  const genres = useListenNotesGenres();

  const { data: campaigns, setData: setCampaigns, setQuery, isLoading, ...rest } = usePaginatedData(
    {
      endpoint: '/api/v1/campaigns',
    }
  );

  const getStatusColor = status => {
    if (status === 'in_review') {
      return 'text-info';
    }
    if (status === 'active' || status === 'completed') {
      return 'text-success';
    }
    return 'text-danger';
  };

  const filterStatus = status => {
    setStatus(status);
    setFilter(true);
  };

  const getCampaignGenres = campaign => {
    return genres.filter(genre => campaign.category_ids.includes(genre.id.toString()));
  };

  const removeCampaign = campaign => {
    deleteResource({
      type: 'campaigns',
      id: campaign.id,
      message: 'Successfully deleted campaign.',
    }).then(() => {
      setCampaigns(state => {
        const newState = state.filter(value => value !== campaign);

        if (newState.length === 0) {
          setCampaignsHasResults(false);
        }

        return newState;
      });
    });
  };

  const handleSearchChange = () => {
    const inputValue = searchCampaignRef.current.value;
    setSearch(inputValue);
  };

  const approveCampaign = id => {
    updateResource({
      data: { campaign: { status: 'active' } },
      type: 'campaigns',
      id,
      successMessage: 'Successfully approved Campaign!',
    }).then(campaign => {
      setCampaigns(campaigns => {
        if (status === null) {
          return campaigns.map(c => (c.id === campaign.id ? { ...c, ...campaign } : c));
        }

        return campaigns.filter(c => c.id !== campaign.id);
      });
    });
  };

  const rejectCampaign = campaign => {
    updateResource({
      data: { campaign: { status: 'closed' } },
      type: 'campaigns',
      id: campaign.id,
      successMessage: 'Successfully rejected the Campaign.',
    }).then(campaign => {
      if (status === null) {
        setCampaigns(campaigns =>
          campaigns.map(c => (c.id === campaign.id ? { ...c, ...campaign } : c))
        );
      }

      return setCampaigns(campaigns => campaigns.filter(c => c.id !== campaign.id));
    });
  };

  const setCompleted = id => {
    sweetAlert({
      title: 'Are you sure?',
      callback: () => {
        updateResource({
          type: 'campaigns',
          id,
          data: { campaign: { status: 'completed' } },
          successMessage: 'Successfully marked campaign as completed.',
        }).then(campaign => {
          if (status === null || status === 'active') {
            setCampaigns(campaigns =>
              campaigns.map(c => (c.id === campaign.id ? { ...c, ...campaign } : c))
            );
          }

          return setCampaigns(campaigns => campaigns.filter(c => c.id !== campaign.id));
        });
      },
    });
  };

  const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 300));

  useEffect(() => {
    setQuery(query => ({ ...query, status, q: search }));
  }, [status, setQuery, search]);

  useEffect(() => {
    if (campaigns.length > 0) {
      setCampaignsHasResults(true);
    }
  }, [campaigns, setCampaignsHasResults]);

  if (campaigns.length === 0 && filter === false && search === '' && !isLoading) {
    return (
      <CallToAction
        title="Create a Campaign to find Sponsorships"
        subtitle="Once you create a Campaign, we will connect you to Podcasters to approve Sponsorships"
        hasButton
        btnTitle="Create Campaign"
        imgSrc={campaignCta}
        retinaImg={campaignCtaRetina}
        alt="Create Campaign"
        path="/campaigns/new"
      />
    );
  }

  return (
    <div className="campaigns-body">
      <div className="filter">
        <Form.Group className="search-input">
          <Form.Control
            type="text"
            placeholder="Search by name"
            name="search"
            ref={searchCampaignRef}
            onChange={debouncedHandleSearchChange}
            autoComplete="off"
            autoFocus
          />

          <FontAwesomeIcon icon="search" />
        </Form.Group>

        <Dropdown>
          <Dropdown.Toggle variant="outline-primary">
            {status ? startCase(status) : 'All'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey={null} onSelect={filterStatus}>
              All
            </Dropdown.Item>
            <Dropdown.Item eventKey="in_review" onSelect={filterStatus}>
              In Review
            </Dropdown.Item>
            <Dropdown.Item eventKey="active" onSelect={filterStatus}>
              Active
            </Dropdown.Item>
            <Dropdown.Item eventKey="completed" onSelect={filterStatus}>
              Completed
            </Dropdown.Item>
            <Dropdown.Item eventKey="closed" onSelect={filterStatus}>
              Closed
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {isLoading && <Loader />}

      {campaigns.length === 0 && !isLoading && (
        <Alert variant="info">No results are matching your search.</Alert>
      )}

      {campaigns.map(campaign => (
        <Accordion
          key={campaign.id}
          onClick={() => setOpen(prev => (prev === campaign.id ? null : campaign.id))}
        >
          <Card className="campaigns-list">
            <Accordion.Toggle
              as={Card.Header}
              eventKey={campaign.id}
              className={campaign.id === currentActiveKey ? 'active' : ''}
            >
              <div className="campaign-header" key="campaign.id">
                <div className="heading">
                  {campaign.name}

                  <AutorizationGate roles="admin, account_manager">
                    <span style={{ display: 'block', fontSize: '14px' }}>
                      {campaign.company_name} <br />
                      {campaign.advertiser_name} - {campaign.advertiser_email}
                    </span>
                  </AutorizationGate>
                </div>

                <span className={`${getStatusColor(campaign.status)} heading`}>
                  {startCase(campaign.status)}
                </span>

                <span className="heading">Budget: {campaign.budget}</span>

                <div className="campaign-icons">
                  <span className="icon">
                    <FontAwesomeIcon icon={open === campaign.id ? 'chevron-up' : 'chevron-down'} />
                  </span>

                  {campaign.status === 'in_review' && (
                    <AutorizationGate roles="admin, account_manager">
                      <span
                        className="approve-icon text-success"
                        onClick={e => {
                          e.stopPropagation();
                          approveCampaign(campaign.id);
                        }}
                      >
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Approve</Tooltip>}>
                          <FontAwesomeIcon icon="check" />
                        </OverlayTrigger>
                      </span>

                      <span
                        className="decline-icon text-danger"
                        onClick={e => {
                          e.stopPropagation();
                          rejectCampaign(campaign);
                        }}
                      >
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Reject</Tooltip>}>
                          <FontAwesomeIcon icon="times" />
                        </OverlayTrigger>
                      </span>
                    </AutorizationGate>
                  )}

                  {campaign.status === 'active' && (
                    <AutorizationGate roles="advertiser">
                      <span
                        className="approve-icon text-success"
                        onClick={e => {
                          e.stopPropagation();
                          setCompleted(campaign.id);
                        }}
                      >
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Mark campaign as completed.</Tooltip>}
                        >
                          <FontAwesomeIcon icon="check" />
                        </OverlayTrigger>
                      </span>
                    </AutorizationGate>
                  )}

                  <span
                    onClick={e => {
                      e.stopPropagation();
                      removeCampaign(campaign);
                    }}
                    className="icon"
                  >
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Delete</Tooltip>}>
                      <FontAwesomeIcon icon="trash-alt" />
                    </OverlayTrigger>
                  </span>
                </div>
              </div>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={campaign.id}>
              <Card.Body onClick={e => e.stopPropagation()}>
                <Container>
                  <Row className="campaign-body" noGutters>
                    <Col className="spons-card">
                      <div className="sponsorships card">
                        <h4>Sponsorships</h4>

                        <span className="subtitle">
                          We're searching for Podcast Sponsorship for your Campaign. You will be
                          notified when there is a new Opportunity.
                        </span>

                        <ul>
                          <li>
                            Opportunities:
                            <span>{get(campaign, 'sponsorship_mappings.opportunity', 0)}</span>
                          </li>

                          <li>
                            In Development:
                            <span>{get(campaign, 'sponsorship_mappings.in_development', 0)}</span>
                          </li>

                          <li>
                            Scheduled:
                            <span>{get(campaign, 'sponsorship_mappings.scheduled', 0)}</span>
                          </li>

                          <li>
                            Live: <span>{get(campaign, 'sponsorship_mappings.launched', 0)}</span>
                          </li>
                        </ul>

                        <Button
                          onClick={() =>
                            history.push({
                              pathname: '/sponsorships',
                              state: { campaign_id: campaign.id, campaign_name: campaign.name },
                            })
                          }
                          variant="outline-success"
                        >
                          View Sponsorships
                        </Button>
                      </div>
                    </Col>

                    <Col className="camp-card">
                      <div className="details card" key={campaign.id}>
                        <h4 className="text-center">Details</h4>

                        <ul>
                          <li>
                            Budget: <span>{campaign.budget}</span>
                          </li>

                          <li>
                            <span className="mr-1">Categories:</span>
                            {getCampaignGenres(campaign).map(genre => (
                              <Badge key={genre.id} variant="success">
                                {genre.name}
                              </Badge>
                            ))}
                          </li>

                          <li>
                            Start Date: <span>{campaign.start_date}</span>
                          </li>

                          <li>
                            Description:
                            <span className="description-field">{campaign.description}</span>
                          </li>

                          {campaign.notes && <li>Notes: {campaign.notes}</li>}
                        </ul>

                        <Button
                          onClick={() => history.push(`/campaigns/${campaign.id}/edit`)}
                          variant="success"
                        >
                          Edit Campaign
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}

      {campaigns.length > 0 &&
        campaigns.length <= 4 &&
        !isLoading &&
        !['completed', 'closed'].includes(status) && <WhatNext />}

      <PaginationFooter data={campaigns} title="Campaigns" {...rest} />
    </div>
  );
};

export default CampaignsList;
