import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';

import { isInvalid } from 'utils/forms';
import useListenNotesTypeahead from 'hooks/useListenNotesTypeahead';
import usePaginatedData from 'hooks/usePaginatedData';
import { createResource, updateResource } from 'utils/api';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import { Mobile, Default } from 'utils/responsive';
import { AuthContext } from 'contexts';
import 'react-dates/lib/css/_datepicker.css';

const defaultRequired = 'is required.';

const options = [
  { value: 'Pre-roll', label: 'Pre-roll' },
  { value: 'Mid-roll', label: 'Mid-roll' },
  { value: 'Post-roll', label: 'Post-roll' },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '50px',
    borderRadius: '3px',
    border: state.isFocused ? '1px solid #277288' : '1px solid #ced4da',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),

  container: provided => ({
    ...provided,
    border: 'none',
    borderRadius: '3px',
    outline: 'none',
  }),

  menu: provided => ({
    ...provided,
    zIndex: '10',
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#f1f2f6' : '',
    '&:hover': {
      backgroundColor: '#f1f2f6',
      cursor: 'pointer',
    },
  }),
};

const schema = yup.object({
  name: yup.string().required(defaultRequired),
  description: yup.string().required(defaultRequired),
  price: yup.number().required(defaultRequired),
  start_date: yup.date('is not a valid date').required(defaultRequired),
  placements: yup.string().required(defaultRequired),
  podcast_id: yup.lazy(value =>
    typeof value === 'number'
      ? yup.number().required(defaultRequired)
      : yup.string().required(defaultRequired)
  ),
  campaign_id: yup.number().required(defaultRequired),
  ad_type_list: yup.array().required(defaultRequired),
});

const SponsorshipForm = ({
  newRecord,
  initialValues,
  defaultSelectedPodcast,
  defaultSelectedCampaign,
}) => {
  const [campaignResults, setCampaignResults] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignsFocused, setCampaignsFocused] = useState(false);
  const [search, setSearch] = useState('');
  const { user } = useContext(AuthContext);

  const { setPodcast } = useContext(PodcastDetailsContext);
  const { data: campaigns, setQuery } = usePaginatedData({
    endpoint: '/api/v1/campaigns',
    defaultQuery: { status: 'active' },
  });
  const searchCampaignRef = useRef();
  const history = useHistory();

  const {
    selectedPodcasts,
    setSelectedPodcasts,
    removePodcast,
    podcastResults,
    podcastsFocused,
    setPodcastsFocused,
    startDateFocused,
    setStartDateFocused,
    debouncedGetPodcasts,
    searchPodcastsRef,
  } = useListenNotesTypeahead();

  const getCampaigns = () => {
    const inputValue = searchCampaignRef.current.value;

    setSearch(inputValue);
  };

  const memoizedGetCampaigns = useCallback(getCampaigns);

  const selectCampaign = (campaign, setFieldValue) => {
    setSelectedCampaign(campaign);
    setFieldValue('campaign_id', campaign.id);
  };

  const removeCampaign = setFieldValue => {
    setSelectedCampaign(null);
    setFieldValue('campaign_id', null);
  };

  const selectPodcast = (podcast, setFieldValue) => {
    setSelectedPodcasts([podcast]);
    setFieldValue('podcast_id', podcast.id);
  };

  const handleCreate = (values, actions) => {
    const price = values.price * 100;
    const listen_notes_podcast_id = values.podcast_id;
    const ad_type_list = values.ad_type_list.map(t => t.value);

    createResource({
      type: 'sponsorships',
      data: { sponsorship: { ...values, price, listen_notes_podcast_id, ad_type_list } },
      successMessage: 'Successfully created a new sponsorship!',
      ...actions,
    }).then(() => {
      setPodcast(null);

      ReactGA.event({
        category: 'Sponsorship',
        action: 'Created Sponsorship',
        value: user.id,
      });

      history.push({
        pathname: '/sponsorships',
        state: { create: true },
      });
    });
  };

  const handleUpdate = (values, actions) => {
    const podcast_id = selectedPodcasts[0].id;
    const campaign_id = selectedCampaign.id;
    const price = values.price * 100;
    const ad_type_list = values.ad_type_list.map(t => t.value);

    updateResource({
      type: 'sponsorships',
      id: initialValues.id,
      data: { sponsorship: { ...values, podcast_id, campaign_id, price, ad_type_list } },
      successMessage: 'Successfully updated the sponsorship.',
      ...actions,
    }).then(() => {
      setPodcast(null);

      history.push({
        pathname: '/sponsorships',
        state: { update: true },
      });
    });
  };

  useEffect(() => {
    setSelectedPodcasts(defaultSelectedPodcast);
  }, [defaultSelectedPodcast, setSelectedPodcasts]);

  useEffect(() => {
    setSelectedCampaign(defaultSelectedCampaign);
  }, [defaultSelectedCampaign, setSelectedCampaign]);

  useEffect(() => {
    setQuery(query => ({ ...query, q: search }));
  }, [setQuery, search]);

  useEffect(() => {
    setCampaignResults(campaigns);
  }, [campaigns]);

  return (
    <div className="form-container">
      <Formik
        validationSchema={schema}
        onSubmit={newRecord ? handleCreate : handleUpdate}
        enableReinitialize
        initialValues={{ ...initialValues }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Container className="ml-0">
            <Form noValidate onSubmit={handleSubmit} className="green-focus style-form">
              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Sponsorship Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={isInvalid('name', { touched, errors })}
                  autoFocus
                />

                <Form.Label column sm={4}>
                  Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">{get(errors, 'name')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isInvalid={isInvalid('description', { touched, errors })}
                  rows="4"
                />

                <Form.Label column sm={4}>
                  Description
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'description')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>$</InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    isInvalid={isInvalid('price', { touched, errors })}
                  />
                </InputGroup>

                <Form.Control.Feedback type="invalid">{get(errors, 'price')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group sm={12}>
                <Select
                  as="form.control"
                  placeholder="Select ad types"
                  name="ad_type_list"
                  value={values.ad_type_list}
                  onChange={type => setFieldValue('ad_type_list', type)}
                  isInvalid={isInvalid('ad_type_list', { touched, errors })}
                  options={options}
                  isMulti
                  styles={customStyles}
                />

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'ad_type_list')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Col
                  sm={12}
                  className={`start-date-container ${startDateFocused ? 'focused' : ''} ${
                    isInvalid('start_date', { touched, errors }) ? 'invalid' : ''
                  }`}
                >
                  <Form.Label column sm={4}>
                    Start Date
                  </Form.Label>

                  <Form.Control
                    style={{ display: 'none' }}
                    isInvalid={isInvalid('start_date', { touched, errors })}
                    sm={8}
                  />

                  <div onClick={() => setStartDateFocused(prev => !prev)} className="calendar">
                    <FontAwesomeIcon icon="calendar" />
                  </div>

                  <Default>
                    <SingleDatePicker
                      id="start_date"
                      placeholder="Start Date"
                      date={values.start_date}
                      onDateChange={date => setFieldValue('start_date', date)}
                      focused={startDateFocused}
                      onFocusChange={({ focused }) => setStartDateFocused(focused)}
                      orientation="horizontal"
                      openDirection="up"
                      numberOfMonths={2}
                      small
                      block
                    />
                  </Default>

                  <Mobile>
                    <SingleDatePicker
                      id="start_date"
                      placeholder="Start Date"
                      date={values.start_date}
                      onDateChange={date => setFieldValue('start_date', date)}
                      focused={startDateFocused}
                      onFocusChange={({ focused }) => setStartDateFocused(focused)}
                      orientation="vertical"
                      openDirection="up"
                      numberOfMonths={1}
                      small
                      block
                    />
                  </Mobile>

                  <Form.Control.Feedback type="invalid">Is not a valid date</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  as="textarea"
                  placeholder="Placements"
                  name="placements"
                  value={values.placements}
                  onChange={handleChange}
                  isInvalid={isInvalid('placements', { touched, errors })}
                  rows="4"
                />

                <Form.Label column sm={4}>
                  Placements
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'placements')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Podcast Name"
                  name="podcast_id_search"
                  ref={searchPodcastsRef}
                  onChange={() => debouncedGetPodcasts()}
                  isInvalid={isInvalid('podcast_id', { touched, errors })}
                  autoComplete="off"
                  onFocus={() => setPodcastsFocused(true)}
                  onBlur={() => setPodcastsFocused(false)}
                  className="mb-2"
                />

                <Form.Label column sm={4}>
                  Search Podcast
                </Form.Label>

                <FontAwesomeIcon icon="search" className="search" />

                {podcastResults.length === 0 &&
                  get(searchPodcastsRef, 'current.value', '').length > 0 &&
                  get(searchPodcastsRef, 'current.value', '').length <= 1 && (
                    <Alert variant="info">You must enter at least two letters.</Alert>
                  )}

                {selectedPodcasts.length > 0 && <span>Selected Podcast:</span>}

                <Form.Control
                  style={{ display: 'none' }}
                  isInvalid={isInvalid('podcast_id', { touched, errors })}
                />

                <Form.Control.Feedback type="invalid">
                  You must select a podcast from the list.
                </Form.Control.Feedback>

                {podcastResults.length > 0 && podcastsFocused && (
                  <div className="podcast-results">
                    {podcastResults
                      .filter(result => result !== selectedPodcasts[0])
                      .map(podcast => (
                        <div
                          className="podcast-result"
                          key={podcast.id}
                          onMouseDown={() => selectPodcast(podcast, setFieldValue)}
                        >
                          <img src={podcast.thumbnail} alt="Podcast thumbnail" />
                          <div className="podcast-name">{podcast.title_original}</div>
                        </div>
                      ))}
                  </div>
                )}

                {selectedPodcasts.map(podcast => (
                  <div
                    key={podcast.id}
                    className="selected-podcast"
                    onClick={() => setPodcast(podcast)}
                  >
                    <img src={podcast.thumbnail} alt="Podcast thumbnail" />
                    <div className="podcast-name">{podcast.title_original || podcast.title}</div>

                    <div className="podcast-actions">
                      <span onClick={() => setPodcast(podcast)} className="view-details">
                        View Details
                      </span>

                      <span
                        onClick={e => {
                          e.stopPropagation();
                          removePodcast(podcast);
                          setFieldValue('podcast_id', null);
                          setPodcast(null);
                        }}
                        className="remove"
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                ))}
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Campaign Name"
                  name="campaign_id_search"
                  ref={searchCampaignRef}
                  onChange={memoizedGetCampaigns}
                  isInvalid={isInvalid('campaign_id', { touched, errors })}
                  autoComplete="off"
                  onFocus={() => setCampaignsFocused(true)}
                  onBlur={() => setCampaignsFocused(false)}
                  className="mb-2"
                />

                <Form.Label column sm={4}>
                  Search Campaign
                </Form.Label>

                <FontAwesomeIcon icon="search" className="search" />

                <Form.Control
                  style={{ display: 'none' }}
                  isInvalid={isInvalid('campaign_id', { touched, errors })}
                />

                {selectedCampaign && <span>Selected Campaign:</span>}

                {campaignResults.length === 0 &&
                  get(searchCampaignRef, 'current.value', '').length > 0 && (
                    <Alert variant="info">No results are matching your search.</Alert>
                  )}

                <Form.Control.Feedback type="invalid">
                  You must select a campaign from the list.
                </Form.Control.Feedback>

                {campaignResults.length > 0 &&
                  campaignsFocused &&
                  searchCampaignRef.current.value.length > 0 && (
                    <div className="campaign-results">
                      {campaignResults
                        .filter(result => result !== selectedCampaign)
                        .map(campaign => (
                          <div
                            className="campaign-result"
                            key={campaign.id}
                            onMouseDown={() => selectCampaign(campaign, setFieldValue)}
                          >
                            <div className="campaign-info">
                              <div>
                                Campaign Name:<span>{campaign.name}</span>
                              </div>

                              <div>
                                Advertiser Name:<span>{campaign.advertiser_name}</span>
                              </div>

                              <div>
                                Company:<span>{campaign.company_name}</span>
                              </div>

                              <div>
                                Email:<span>{campaign.advertiser_email}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                {selectedCampaign && (
                  <div key={selectedCampaign.id} className="selected-campaign">
                    <div className="campaign-info">
                      <div>
                        Campaign Name:<span>{selectedCampaign.name}</span>
                      </div>

                      <div>
                        Advertiser Name:<span>{selectedCampaign.advertiser_name}</span>
                      </div>

                      <div>
                        Company:<span>{selectedCampaign.company_name}</span>
                      </div>

                      <div>
                        Email:<span>{selectedCampaign.advertiser_email}</span>
                      </div>
                    </div>

                    <span
                      onClick={e => {
                        e.stopPropagation();
                        removeCampaign(setFieldValue);
                      }}
                      className="remove-icon"
                    >
                      <FontAwesomeIcon icon="times" />
                    </span>
                  </div>
                )}
              </Form.Group>

              <Button className="py-2 mt-4" type="submit" variant="success" block>
                {newRecord ? 'Create' : 'Update'}
              </Button>
            </Form>
          </Container>
        )}
      </Formik>
    </div>
  );
};

SponsorshipForm.propTypes = {
  newRecord: PropTypes.bool,
  initialValues: PropTypes.object,
  defaultSelectedPodcast: PropTypes.array,
  defaultSelectedCampaign: PropTypes.object,
};

SponsorshipForm.defaultProps = {
  newRecord: true,
  initialValues: {
    name: '',
    description: '',
    price: '',
    start_date: null,
    placements: '',
    podcast_id: '',
    campaign_id: '',
    ad_type_list: [],
  },
  defaultSelectedPodcast: [],
  defaultSelectedCampaign: null,
};

export default SponsorshipForm;
