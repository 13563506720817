import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { isInvalid } from 'utils/forms';
import { createResource } from 'utils/api';
import useAuthRedirect from 'hooks/useAuthRedirect';
import BackgroundImage from 'components/BackgroundImage';
import { Tablet, MobileOnly } from 'utils/responsive';
import logo from 'images/logo.png';
import retinaLogo from 'images/logo@2x.png';

const defaultRequired = 'is required.';

const schema = yup.object({
  email: yup
    .string()
    .email('Invalid email')
    .required(defaultRequired),
});

const initialValues = {
  email: '',
};

const ForgotPassword = () => {
  const handleSubmit = ({ email }, actions) => {
    createResource({
      type: 'password',
      data: { user: { email } },
      baseUrl: false,
      successMessage: 'We just sent you a reset password link. Please check your email.',
      ...actions,
    }).then(() => {
      actions.resetForm();
    });
  };

  useAuthRedirect();

  return (
    <Container>
      <Row noGutters>
        <Col>
          <MobileOnly>
            <div className="mobile-logo">
              <img src={logo} srcSet={`${logo} 1x, ${retinaLogo} 2x`} alt="Sponsored Logo" />
            </div>
          </MobileOnly>
        </Col>
      </Row>

      <Row className="row-container" noGutters>
        <Col className="form-container centered main-body" xs={12} md={6} xl={5}>
          <div className="title">
            <h2>Forgot password?</h2>

            <span>Give us your email address, and we will send you further instructions.</span>
          </div>

          <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="my-4">
                <Form.Group className="floating-label">
                  <Form.Control
                    type="email"
                    placeholder="example@example.com"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={isInvalid('email', { touched, errors })}
                    autoFocus
                  />

                  <Form.Label column sm={4}>
                    E-mail
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <Button className="py-2 mt-4" type="submit" block>
                  Send
                </Button>
              </Form>
            )}
          </Formik>

          <div className="bottom-text">
            Back to
            <Link to="/login" className="ml-1">
              Login Page
            </Link>
          </div>
        </Col>

        <Tablet>
          <BackgroundImage />
        </Tablet>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
