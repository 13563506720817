import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';
import startCase from 'lodash/startCase';

import { getInitials, getRoleColor } from 'utils/conversations';

const Message = ({ user, message, showRole }) => (
  <div className="msg-box">
    <div className="msg-avatar">
      {message.user.avatar_url ? (
        <Image
          className={user.id === message.user_id ? 'avatar d-none' : 'avatar'}
          src={message.user.avatar_url}
          alt="Avatar"
        />
      ) : (
        <div className={user.id === message.user_id ? 'initials-avatar d-none' : 'initials-avatar'}>
          {getInitials(message.user.name)}
        </div>
      )}
    </div>

    <div
      className={user.id === message.user_id ? 'msg-right msg-body' : 'msg-left msg-body'}
      key={message.id}
    >
      <div className="user-info">
        <div className="user-name">
          <span className="mr-2">{message.user.name}</span>
          <span className="date">{moment(message.created_at).format('Do MMM, YYYY HH:mm')}</span>
        </div>

        {showRole && user.id !== message.user_id && (
          <Badge variant={getRoleColor(message.user.role)}>
            <strong>{startCase(message.user.role)}</strong>
          </Badge>
        )}
      </div>

      <div className="content">{message.body}</div>
    </div>
  </div>
);

Message.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  showRole: PropTypes.bool,
};

Message.defaultProps = {
  showRole: false,
};

export default Message;
