import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { updateResource } from 'utils/api';
import notificationsCta from 'images/campaign_cta.png';
import notificationsCtaRetina from 'images/campaign_cta@2x.png';
import { NotificationsContext } from 'contexts/NotificationsContext';
import usePaginatedData from 'hooks/usePaginatedData';
import Loader from 'components/Loader';
import Notifications from './Notifications';
import CallToAction from '../CallToAction';
import 'styles/DashboardPage.scss';

const DashboardPage = () => {
  const { notifications, setNotifications, ...rest } = useContext(NotificationsContext);
  const { isLoading } = usePaginatedData('/notifications');

  const removeNotification = id => {
    updateResource({
      type: 'notifications',
      endpoint: `notifications/${id}/mark_as_read`,
      successMessage: 'Successfully marked notification as read.',
    }).then(() => {
      setNotifications(state => {
        return state.filter(value => value.id !== id);
      });
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className="dashboard-page">
      {notifications.length === 0 && !isLoading ? (
        <CallToAction
          title="You have no Notifications"
          imgSrc={notificationsCta}
          retinaImg={notificationsCtaRetina}
          alt="No Notifications"
        />
      ) : (
        <Row noGutters>
          <Col className="main-body">
            <Notifications
              notifications={notifications}
              removeNotification={removeNotification}
              {...rest}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default DashboardPage;
