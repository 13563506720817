import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// eslint-disable-next-line react/prop-types
const Loader = ({ style = {}, size = '2x' }) => {
  return (
    <FontAwesomeIcon
      icon="spinner"
      spin
      size={size}
      style={{ display: 'block', margin: '100px auto', color: '#ff5a5f', ...style }}
    />
  );
};

export default Loader;
