import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';

import { AuthContext } from 'contexts';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import { updateResource } from 'utils/api';
import { formatPrice } from 'utils/forms';
import AuthorizationGate from 'components/AuthorizationGate';
import PaginationFooter from 'components/PaginationFooter';

const PodcastList = ({ podcasts, setPodcasts, podcastsProps }) => {
  const history = useHistory();
  const [price, setPrice] = useState({});
  const [updatePrice, setUpdatePrice] = useState({});
  const { user } = useContext(AuthContext);
  const { setPodcast } = useContext(PodcastDetailsContext);

  const handleUpdate = id => {
    const priceValue = get(price, id, '');

    updateResource({
      type: 'podcasts',
      id,
      data: { podcast: { price: priceValue * 100 } },
      successMessage: 'Successfully updated price!',
    }).then(podcast => {
      setPodcasts(prev =>
        prev.map(p => (p.id === podcast.id ? { ...p, price: podcast.price } : p))
      );
      setUpdatePrice(false);
    });
  };

  useEffect(() => {
    const prices = {};

    podcasts.forEach(podcast => {
      prices[podcast.id] = podcast.price / 100;
    });

    setPrice(prices);
  }, [podcasts]);

  const isAdmin = user.role === 'admin' || user.role === 'account_manager';

  return (
    <div className="podcasts">
      {podcasts.map(podcast => (
        <div
          className={user.role === 'podcaster' ? 'claimed-podcast' : 'claimed-podcast chat'}
          key={podcast.podcast.id}
          style={isAdmin ? { cursor: 'pointer' } : {}}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            if (isAdmin && podcast.conversation_id) {
              history.push(`/conversations/${podcast.conversation_id}`);
            }
          }}
        >
          <div className="podcast-details">
            <div className="see-more">
              <img
                src={podcast.podcast.thumbnail}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (podcast.podcast.thumbnail) {
                    setPodcast(podcast.podcast);
                  }
                }}
                alt="Podcast Thumbnail"
              />

              {podcast.podcast && (
                <span
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    setPodcast(podcast.podcast);
                  }}
                  className="show-more"
                >
                  Show more
                </span>
              )}
            </div>

            <div className="ml-2">
              <span className="font-weight-bold">{podcast.podcast.title}</span>

              <AuthorizationGate roles="admin, account_manager">
                <div>
                  <span>{podcast.podcaster_name}</span>
                  <span> ({podcast.podcaster_email})</span>
                </div>
              </AuthorizationGate>
            </div>
          </div>

          {updatePrice[podcast.id] ? (
            <>
              <InputGroup as={Col} sm={5}>
                <Form.Label className="label">Price:</Form.Label>

                <Form.Control
                  type="number"
                  name="price"
                  value={get(price, podcast.id, '')}
                  onChange={e => {
                    const price = e.target.value;
                    setPrice(prev => ({ ...prev, [podcast.id]: price }));
                  }}
                  placeholder="Price"
                />
              </InputGroup>

              <Button variant="success" onClick={() => handleUpdate(podcast.id)}>
                Update Price
              </Button>
            </>
          ) : (
            <div className="price" key={podcast.key}>
              <div className="avg-price">
                Average price per Sponsorship:{' '}
                <span style={{ fontWeight: 700 }}>{formatPrice(podcast.price)}</span>
              </div>

              <AuthorizationGate roles="podcaster">
                <span
                  onClick={() => setUpdatePrice({ [podcast.id]: true })}
                  className="update-price"
                >
                  Update price?
                </span>
              </AuthorizationGate>
            </div>
          )}
        </div>
      ))}

      <PaginationFooter
        {...podcastsProps}
        data={podcasts}
        perPage={10}
        perPageOptions={[10]}
        title="Podcasts"
      />
    </div>
  );
};

PodcastList.propTypes = {
  podcasts: PropTypes.array.isRequired,
  setPodcasts: PropTypes.func.isRequired,
  podcastsProps: PropTypes.object.isRequired,
};

export default PodcastList;
