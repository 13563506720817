/* eslint-disable react/static-property-placement, react/sort-comp, react/destructuring-assignment, react/prop-types */
import React, { Component } from 'react';
import { getWebSocketUrl } from 'constants/utils';
import CableContext from './Context';

const ActionCable = require('actioncable');

const url = getWebSocketUrl();

class Provider extends Component {
  UNSAFE_componentWillMount() {
    this.cable = ActionCable.createConsumer(url);
  }

  componentWillUnmount() {
    if (this.cable) {
      this.cable.disconnect();
    }
  }

  render() {
    return <CableContext.Provider value={this.cable}>{this.props.children}</CableContext.Provider>;
  }
}

export default Provider;
