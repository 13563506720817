import React from 'react';
import Col from 'react-bootstrap/Col';

import background from '../images/background.png';
import backgroundRetina from '../images/background@2x.png';

const BackgroundImage = () => (
  <Col style={{ position: 'fixed', right: 0, top: 0, bottom: 0 }} md={6} xl={7}>
    <img
      src={background}
      srcSet={(background, backgroundRetina)}
      alt="Background of microphones"
      style={{ objectFit: 'cover', width: '100%', height: '100%' }}
    />
  </Col>
);

export default BackgroundImage;
