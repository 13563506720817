import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import usePaginatedData from 'hooks/usePaginatedData';
import CallToAction from 'components/CallToAction';
import sponsorshipCta from 'images/sponsorship_cta.png';
import sponsorshipCtaRetina from 'images/sponsorship_cta@2x.png';
import Loader from 'components/Loader';
import { AuthContext } from 'contexts';
import LaunchedSponsorships from './LaunchedSponsorships';
import SponsorshipReports from './SponsorshipReports';

const ReportingPage = () => {
  const { user } = useContext(AuthContext);

  const { data: launchedSponsorships, isLoading, ...rest } = usePaginatedData({
    endpoint: '/api/v1/sponsorships',
    defaultQuery: {
      status: 'launched',
      metrics: true,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className="reporting-page">
      {launchedSponsorships.length === 0 && !isLoading && (
        <CallToAction
          title="You have no Launched Sponsorships yet"
          imgSrc={sponsorshipCta}
          retinaImg={sponsorshipCtaRetina}
          alt="No Launched Sponsorships"
          hasButton={user.role === 'advertiser'}
          path="/settings/pixel"
          btnTitle="Set Up Tracking Pixel"
        />
      )}

      <Row noGutters>
        <Switch>
          <Route path="/reporting" exact>
            <Col className="main-body">
              <LaunchedSponsorships launchedSponsorships={launchedSponsorships} {...rest} />
            </Col>
          </Route>

          <Route path="/reporting/:id/details">
            <Col className="main-body">
              <SponsorshipReports />
            </Col>
          </Route>
        </Switch>
      </Row>
    </Container>
  );
};

export default ReportingPage;
