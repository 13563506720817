import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconColor, getNotificationIcon } from 'utils/notifications';
import PaginationFooter from 'components/PaginationFooter';

const Notifications = ({ notifications, removeNotification, ...rest }) => {
  const history = useHistory();

  return (
    <div className="notification-list">
      {notifications.map(notification => (
        <div className="notification" key={notification.id}>
          <div>
            <FontAwesomeIcon
              icon={getNotificationIcon(notification.kind)}
              style={{ color: getIconColor(notification.kind) }}
              className="mr-2"
            />
            <span>{notification.action}</span>
          </div>

          <div className="actions">
            <Button onClick={() => history.push(notification.link)} variant="outline-success">
              View Details
            </Button>

            <Button
              onClick={e => {
                e.stopPropagation();
                removeNotification(notification.id);
              }}
              variant="success"
            >
              Mark as Read
            </Button>
          </div>
        </div>
      ))}

      <PaginationFooter data={notifications} title="Notifications" {...rest} />
    </div>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  removeNotification: PropTypes.func.isRequired,
};

export default Notifications;
