import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { fetchResource, fetchResources } from 'utils/api';
import useListenNotesGenres from 'hooks/useListeNotesGenres';
import CampaignForm from './Form';

const EditCampaign = () => {
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [podcasts, setPodcasts] = useState([]);
  const genres = useListenNotesGenres();

  useEffect(() => {
    fetchResource({ type: 'campaigns', id }).then(campaign => setCampaign(campaign));
  }, [id]);

  useEffect(() => {
    if (!campaign) return;

    const ids = campaign.targeted_podcast_ids;

    if (ids.length === 0) return;

    ids.forEach(id => {
      fetchResources({
        type: 'listen_notes',
        query: {
          endpoint: `podcasts/${id}`,
        },
      }).then(podcast => {
        setPodcasts(prevPodcasts => [...prevPodcasts, podcast]);
      });
    });
  }, [campaign]);

  if (campaign) {
    const start_date = campaign.start_date ? moment(campaign.start_date) : null;
    const defaultSelectedGenres = genres.filter(genre =>
      campaign.category_ids.includes(genre.id.toString())
    );

    return (
      <CampaignForm
        newRecord={false}
        initialValues={{ ...campaign, start_date }}
        defaultSelectedGenres={defaultSelectedGenres}
        defaultSelectedPodcasts={podcasts}
      />
    );
  }

  return 'loading';
};

export default EditCampaign;
