import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import EditCampaign from './Edit';
import CampaignForm from './Form';
import CampaignsList from './CampaignsList';
import 'styles/Form.scss';
import 'styles/CampaignsPage.scss';

const CampaignsPage = () => {
  return (
    <Container className="campaigns-page">
      <Row noGutters>
        <Route path="/campaigns" exact>
          <Col className="main-body">
            <CampaignsList />
          </Col>
        </Route>
      </Row>

      <Switch>
        <Route path="/campaigns/new" exact>
          <CampaignForm />
        </Route>

        <Route path="/campaigns/:id/edit" exact>
          <EditCampaign />
        </Route>
      </Switch>
    </Container>
  );
};

export default CampaignsPage;
