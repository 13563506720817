import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { normalizeInitialValues } from 'utils/forms';
import { ItineraryContext } from 'contexts/ItineraryContext';
import 'styles/ItinerarySection.scss';

const Itinerary = ({ sponsorship }) => {
  const normalizedSponsorship = normalizeInitialValues(sponsorship);
  const { setStep, step } = useContext(ItineraryContext);
  const {
    status,
    advertiser_copy,
    advertiser_promo_url,
    advertiser_approved,
    podcaster_approved,
  } = normalizedSponsorship;

  const defaultStep = () => {
    if (status === 'opportunity') {
      return 1;
    }

    if (
      status === 'in_development' &&
      advertiser_copy.length === 0 &&
      advertiser_promo_url.length === 0
    ) {
      return 2;
    }

    if (
      (status === 'in_development' &&
        advertiser_copy.length > 0 &&
        advertiser_promo_url.length > 0) ||
      status === 'cancelled'
    ) {
      return 3;
    }

    return 4;
  };

  const handleStep = currentStep => {
    if (currentStep <= defaultStep()) setStep(currentStep);
  };

  useEffect(() => {
    setStep(defaultStep());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="itinerary-section">
      <div className="steps">
        <div className={step === 1 || status !== 'opportunity' ? 'finished-step step' : 'step'}>
          <Button
            onClick={() => handleStep(1)}
            className={step === 1 ? 'active' : 'circle hover'}
            variant="success"
          >
            {status !== 'opportunity' ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className={step === 1 && 'd-none'}>View this step</Tooltip>}
              >
                <FontAwesomeIcon icon="check" />
              </OverlayTrigger>
            ) : (
              1
            )}
          </Button>
          <span>Confirm Interest</span>
        </div>

        <div className={step === 2 || status !== 'opportunity' ? 'finished-step step' : 'step'}>
          <Button
            onClick={() => handleStep(2)}
            className={step === 2 ? 'active' : 'circle'}
            variant="success"
          >
            {status !== 'opportunity' &&
            advertiser_copy.length > 0 &&
            advertiser_promo_url.length > 0 ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className={step === 2 && 'd-none'}>View this step</Tooltip>}
              >
                <FontAwesomeIcon icon="check" />
              </OverlayTrigger>
            ) : (
              2
            )}
          </Button>
          <span>Details</span>
        </div>

        <div
          className={
            step === 3 || (advertiser_copy.length > 0 && advertiser_promo_url.length > 0)
              ? 'finished-step step'
              : 'step'
          }
        >
          <Button
            onClick={() => handleStep(3)}
            className={step === 3 ? 'active' : 'circle'}
            variant="success"
          >
            {advertiser_approved && podcaster_approved ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className={step === 3 && 'd-none'}>View this step</Tooltip>}
              >
                <FontAwesomeIcon icon="check" />
              </OverlayTrigger>
            ) : (
              3
            )}
          </Button>
          <span>Schedule</span>
        </div>

        <div
          className={
            step === 4 || (advertiser_approved && podcaster_approved)
              ? 'finished-step step'
              : 'step'
          }
        >
          <Button
            onClick={() => handleStep(4)}
            className={step === 4 ? 'active no-border' : 'circle no-border'}
            variant="success"
          >
            {status === 'launched' ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className={step === 4 && 'd-none'}>View this step</Tooltip>}
              >
                <FontAwesomeIcon icon="rocket" size="lg" />
              </OverlayTrigger>
            ) : (
              4
            )}
          </Button>

          <span>Launch</span>
        </div>
      </div>
    </div>
  );
};

Itinerary.propTypes = {
  sponsorship: PropTypes.object.isRequired,
};

export default Itinerary;
