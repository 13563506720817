import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from 'images/pink-logo.svg';
import { Mobile } from 'utils/responsive';
import AuthorizationGate from './AuthorizationGate';
import 'styles/Sidebar.scss';

const Sidebar = ({ handleClickOpen, handleClickClose }) => {
  return (
    <aside>
      <Mobile>
        <FontAwesomeIcon
          icon="times"
          onClick={e => {
            e.stopPropagation();
            handleClickOpen();
          }}
          className="close-icon"
        />
      </Mobile>

      <ul className="aside-links">
        <NavLink exact to="/" activeClassName="active">
          <li className="logo-field" onClick={handleClickClose}>
            <img src={logo} alt="Sponsored logo" width={19} />
            <h4 className="logo-text">Sponsored</h4>
          </li>
        </NavLink>

        <AuthorizationGate roles="advertiser, account_manager, admin">
          <NavLink to="/campaigns" activeClassName="active">
            <li onClick={handleClickClose}>
              <FontAwesomeIcon icon="bullhorn" size="lg" />
              <span>Campaigns</span>
            </li>
          </NavLink>
        </AuthorizationGate>

        <AuthorizationGate roles="podcaster, account_manager, admin">
          <NavLink to="/podcasts" activeClassName="active" exact>
            <li onClick={handleClickClose}>
              <FontAwesomeIcon icon="podcast" size="lg" />
              <span>Podcasts</span>
            </li>
          </NavLink>
        </AuthorizationGate>

        <AuthorizationGate roles="account_manager, admin">
          <NavLink to="/podcasts/claim" activeClassName="active">
            <li onClick={handleClickClose}>
              <FontAwesomeIcon icon="podcast" size="lg" />
              <span>Podcast Claims</span>
            </li>
          </NavLink>
        </AuthorizationGate>

        <NavLink to="/sponsorships" activeClassName="active">
          <li onClick={handleClickClose}>
            <FontAwesomeIcon icon="microphone" size="lg" />
            <span>Sponsorships</span>
          </li>
        </NavLink>

        <AuthorizationGate roles="advertiser, admin">
          <NavLink to="/marketplace" activeClassName="active">
            <li onClick={handleClickClose}>
              <FontAwesomeIcon icon="search" size="lg" />
              <span>Marketplace</span>
            </li>
          </NavLink>
        </AuthorizationGate>

        <NavLink to="/conversations" activeClassName="active">
          <li onClick={handleClickClose}>
            <FontAwesomeIcon icon="envelope" size="lg" />
            <span>Messages</span>
          </li>
        </NavLink>

        <NavLink to="/reporting" activeClassName="active">
          <li onClick={handleClickClose}>
            <FontAwesomeIcon icon="chart-bar" size="lg" />
            <span>Reporting</span>
          </li>
        </NavLink>

        <AuthorizationGate roles="admin, account_manager">
          <NavLink to="/users" activeClassName="active">
            <li onClick={handleClickClose}>
              <FontAwesomeIcon icon="users" size="lg" />
              <span>Users</span>
            </li>
          </NavLink>
        </AuthorizationGate>

        <NavLink to="/settings" activeClassName="active">
          <li onClick={handleClickClose}>
            <FontAwesomeIcon icon="cog" size="lg" />
            <span>Settings</span>
          </li>
        </NavLink>
      </ul>
    </aside>
  );
};

Sidebar.defaultProps = {
  handleClickOpen: null,
  handleClickClose: null,
};

Sidebar.propTypes = {
  handleClickOpen: PropTypes.func,
  handleClickClose: PropTypes.func,
};

export default Sidebar;
