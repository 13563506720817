import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import moment from 'moment';

import { fetchResources, createResource } from 'utils/api';
import { AuthContext } from 'contexts';
import { getInitials } from 'utils/conversations';
import 'styles/ConversationsPage.scss';

const SponsorshipChat = ({ sponsorship, setOpenChat }) => {
  const [messages, setMessages] = useState([]);
  const inputRef = useRef();
  const bodyRef = useRef();
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchResources({
      endpoint: `conversations/${id}/messages`,
      query: { conversation_type: 'Sponsorship' },
    }).then(messages => {
      setMessages(messages);
    });
  }, [id]);

  const createMessage = () => {
    createResource({
      type: 'conversations',
      endpoint: `conversations/${id}/messages?conversation_type=Sponsorship`,
      showSuccessMessage: false,
      data: { body: inputRef.current.value },
    }).then(message => {
      setMessages(prev => [...prev, message]);
      inputRef.current.value = '';
    });
  };

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Container className="conversations-page">
      <Row noGutters>
        <Col className="chat-section">
          <div className="chat-box" style={{ borderLeft: 0 }}>
            <div className="header d-flex justify-content-between">
              <span>
                Sponsorship: <strong>{sponsorship.name}</strong>
                {sponsorship.users && (
                  <div className="users">
                    {sponsorship.users
                      .split(', ')
                      .filter(name => name !== user.name)
                      .map(name => (
                        <span key={name}>
                          {name}
                          {sponsorship.users.length > 1 && ','}
                        </span>
                      ))}
                    You
                  </div>
                )}
              </span>

              <span
                onClick={() => setOpenChat(false)}
                className="close-icon"
                style={{ marginBottom: 'auto' }}
              >
                <FontAwesomeIcon icon="times" />
              </span>
            </div>

            <div
              className="body"
              style={{ height: 'calc(100vh - 166px)', overflowY: 'auto' }}
              ref={bodyRef}
            >
              {messages.map(message => (
                <div className="msg-box" key={message.id}>
                  <div className="msg-avatar">
                    {message.user.avatar_url ? (
                      <Image
                        className={user.id === message.user_id ? 'avatar d-none' : 'avatar'}
                        src={message.user.avatar_url}
                        alt="Avatar"
                      />
                    ) : (
                      <div
                        className={
                          user.id === message.user_id ? 'initials-avatar d-none' : 'initials-avatar'
                        }
                      >
                        {getInitials(message.user.name)}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      user.id === message.user_id ? 'msg-right msg-body' : 'msg-left msg-body'
                    }
                    key={message.id}
                    style={{ padding: '10px' }}
                  >
                    <div className="user-info">
                      <div className="user-name">
                        <span className="mr-2">{message.user.name}</span>
                        <span className="date">
                          {moment(message.created_at).format('Do MMM, YYYY HH:mm')}
                        </span>
                      </div>
                    </div>

                    <div className="content">{message.body}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="send">
              <Form.Group as={Col} sm={10}>
                <Form.Control
                  ref={inputRef}
                  type="text"
                  as="textarea"
                  placeholder="Type in your message..."
                  name="search"
                  autoComplete="off"
                  autoFocus
                  onKeyDown={e => {
                    if (e.keyCode === 13 && sponsorship.status !== 'cancelled' && !e.shiftKey) {
                      createMessage();
                    }
                  }}
                  disabled={sponsorship.status === 'cancelled'}
                />
              </Form.Group>

              <Button
                variant="success"
                onClick={createMessage}
                disabled={sponsorship.status === 'cancelled'}
                block
              >
                Send
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

SponsorshipChat.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setOpenChat: PropTypes.func.isRequired,
};

export default SponsorshipChat;
