import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import qs from 'qs';

import { getInitials, getStatusColor, getRoleColor } from 'utils/conversations';
import { TabletAndUp, MobileOnly } from 'utils/responsive';
import { AuthContext } from 'contexts';
import usePaginatedData from 'hooks/usePaginatedData';
import PaginationFooter from 'components/PaginationFooter';
import Loader from 'components/Loader';

const renderConversation = conversation => (
  <>
    {conversation.recipient.avatar_url ? (
      <Image className="avatar" src={conversation.recipient.avatar_url} roundedCircle />
    ) : (
      <div className="initials-avatar">{getInitials(conversation.recipient.name)}</div>
    )}

    <div className="recipient-info">
      <span>{conversation.recipient.name}</span>

      <Badge variant={getRoleColor(conversation.recipient.role)}>
        {startCase(conversation.recipient.role)}
      </Badge>
    </div>

    {get(conversation, 'unread_messages_count', 0) > 0 && (
      <Badge className="unread-count notif-badge" variant="secondary">
        {conversation.unread_messages_count}
      </Badge>
    )}
  </>
);

const renderSponsorship = (sponsorship, user) => (
  <>
    <div className="recipient-info">
      <span>{sponsorship.name}</span>
      <Badge variant={getStatusColor(sponsorship.status)}>{startCase(sponsorship.status)}</Badge>

      {sponsorship.users && (
        <div className="users">
          {sponsorship.users
            .split(', ')
            .filter(name => name !== user.name)
            .map(name => (
              <span key={name}>
                {name}
                {sponsorship.users.length > 1 && ', '}
              </span>
            ))}
          You
        </div>
      )}
    </div>

    {get(sponsorship, 'unread_messages_count', 0) > 0 && (
      <Badge className="unread-count notif-badge" variant="secondary">
        {sponsorship.unread_messages_count}
      </Badge>
    )}
  </>
);

const ConversationList = ({
  handleClickOpen,
  conversations,
  handleConversationClick,
  activeTab,
  setConversations,
  setFilteredConversations,
  paginationProps,
}) => {
  const history = useHistory();
  const match = useRouteMatch('/conversations/:id');
  const id = get(match, 'params.id');
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { conversation_type } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { page, pages, perPage, totalCount, isLoading, ...rest } = usePaginatedData({
    endpoint: '/api/v1/conversations',
    effectEnabled: activeTab === 'Sponsorship',
    defaultQuery: { conversation_type: 'Sponsorship' },
    callback: useCallback(
      data => {
        if (activeTab === 'Sponsorship') {
          setConversations(data);
          setFilteredConversations(data);

          if (data.length === 0) {
            history.push('/conversations');
            return;
          }

          if (conversation_type !== 'Sponsorship') {
            history.push(`/conversations/${data[0].id}?conversation_type=Sponsorship`);
          }
        }
      },
      [activeTab, history, setConversations, setFilteredConversations, conversation_type]
    ),
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <TabletAndUp>
        <div className="conversation-list">
          {conversations.map(conversation => (
            <div
              onClick={() => {
                handleConversationClick(conversation.id);
              }}
              key={conversation.id}
              className={+id === conversation.id ? 'active conversation' : 'conversation'}
            >
              {conversation.status
                ? renderSponsorship(conversation, user)
                : renderConversation(conversation)}
            </div>
          ))}
        </div>
      </TabletAndUp>

      <MobileOnly>
        <div className="conversation-list">
          {conversations.map(conversation => (
            <div
              onClick={() => {
                handleConversationClick(conversation.id);
                handleClickOpen();
              }}
              key={conversation.id}
              className={+id === conversation.id ? 'active conversation' : 'conversation'}
            >
              {conversation.status
                ? renderSponsorship(conversation, user)
                : renderConversation(conversation)}
            </div>
          ))}
        </div>
      </MobileOnly>

      {activeTab === 'Sponsorship' ? (
        <PaginationFooter
          {...rest}
          page={page}
          perPage={perPage}
          totalCount={totalCount}
          title="Sponsorships"
          data={conversations}
          smallPagination
        />
      ) : (
        <PaginationFooter
          {...paginationProps}
          title="Direct msgs"
          data={conversations}
          smallPagination
        />
      )}
    </>
  );
};

ConversationList.propTypes = {
  conversations: PropTypes.array.isRequired,
  setConversations: PropTypes.func.isRequired,
  setFilteredConversations: PropTypes.func.isRequired,
  handleConversationClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  handleClickOpen: PropTypes.func,
  paginationProps: PropTypes.object.isRequired,
};

ConversationList.defaultProps = {
  handleClickOpen: null,
};

export default ConversationList;
