import React, { useContext, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import get from 'lodash/get';

import { isInvalid } from 'utils/forms';
import { createResource } from 'utils/api';
import { AuthContext } from 'contexts';
import useAuthRedirect from 'hooks/useAuthRedirect';
import { Tablet, MobileOnly } from 'utils/responsive';
import logo from 'images/logo.png';
import retinaLogo from 'images/logo@2x.png';
import BackgroundImage from './BackgroundImage';

const defaultRequired = 'is required.';

const schema = yup.object({
  email: yup.string().required(defaultRequired),
  password: yup
    .string()
    .required(defaultRequired)
    .min(6, 'Must be at least 6 characters'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const [showConfirmationLink, setShowConfirmationLink] = useState(false);
  const { setCurrentUser } = useContext(AuthContext);
  const history = useHistory();
  const emailRef = useRef();

  useAuthRedirect();

  const handleSubmit = (values, actions) => {
    createResource({
      type: 'users',
      data: { user: values },
      endpoint: 'sign_in',
      baseUrl: false,
      successMessage: 'Successfully logged in.',
      errorMessage: 'Incorrect email or password.',
      ...actions,
    })
      .then(user => {
        setCurrentUser(user);
        history.push(user.role === 'podcaster' ? '/podcasts' : '/campaigns');
      })
      .catch(error => {
        const msg = get(error, 'response.data.error');

        if (msg === 'You have to confirm your email address before continuing.') {
          setShowConfirmationLink(true);
        }
      });
  };

  const resendConfirmationInstructions = () => {
    createResource({
      endpoint: 'confirmations',
      baseUrl: false,
      data: { user: { email: emailRef.current.value } },
      successMessage: 'Successfully re-sent confirmation instructions.',
    }).then(() => setShowConfirmationLink(false));
  };

  return (
    <Container>
      <Row noGutters>
        <Col>
          <MobileOnly>
            <div className="mobile-logo">
              <img src={logo} srcSet={`${logo} 1x, ${retinaLogo} 2x`} alt="Sponsored Logo" />
            </div>
          </MobileOnly>
        </Col>
      </Row>

      <Row noGutters>
        <Col md={6} xl={5}>
          {showConfirmationLink && (
            <Alert variant="success" className="text-center" style={{ zIndex: 9 }}>
              Didn't receive confirmation instructions?{' '}
              <span id="resend-confirmation-link" onClick={resendConfirmationInstructions}>
                Click here to resend
              </span>
            </Alert>
          )}
        </Col>
      </Row>

      <Row className="row-container" noGutters>
        <Col className="form-container centered main-body" md={6} xl={5}>
          <div className="title">
            <h2> Already a member? Login</h2>

            <span>Login and make new cooperation on the Sponsored platform.</span>
          </div>

          <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="floating-label">
                  <Form.Control
                    ref={emailRef}
                    type="text"
                    placeholder="name@gmail.com"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={isInvalid('email', { touched, errors })}
                    autoFocus
                  />
                  <Form.Label column sm={4}>
                    Email
                  </Form.Label>

                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="floating-label">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={isInvalid('password', { touched, errors })}
                  />
                  <Form.Label column sm={4}>
                    Password
                  </Form.Label>

                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox" className="form-footer">
                  <Form.Check type="checkbox" label="Remember me" custom />
                  <Link to="/forgot-password">I forgot my password</Link>
                </Form.Group>

                <Button className="mt-4" type="submit" block>
                  Login
                </Button>
              </Form>
            )}
          </Formik>

          <div className="bottom-text">
            Don't have an account yet?
            <Link to="/sign_up">
              <span className="ml-1">Sign up!</span>
            </Link>
          </div>
        </Col>

        <Tablet>
          <BackgroundImage />
        </Tablet>
      </Row>
    </Container>
  );
};

export default Login;
