import React, { useEffect, useContext, useState, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isInvalid, normalizeInitialValues } from 'utils/forms';
import { updateResource, fetchResource } from 'utils/api';
import { AuthContext } from 'contexts';

const defaultRequired = 'is required.';
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const schema = yup.object({
  name: yup.string().required(defaultRequired),
  url: yup.string().required(defaultRequired),
  photo: yup
    .mixed()
    .test('fileSize', 'File Size is too large', value =>
      value ? value.size <= 10 * 1024 * 1024 : true
    )
    .test('fileType', 'Unsupported File Format', value =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    ),
  description: yup.string(),
  phone: yup.number().required(defaultRequired),
  address: yup.string().required(defaultRequired),
  monthly_budget: yup.string().required(defaultRequired),
  target_audience: yup.string().required(defaultRequired),
});

const initialValues = {
  name: '',
  url: '',
  photo: undefined,
  description: '',
  phone: '',
  address: '',
  monthly_budget: '',
  target_audience: '',
};

const CompanyForm = () => {
  const [company, setCompany] = useState(null);
  const { user } = useContext(AuthContext);
  const fileRef = useRef();

  useEffect(() => {
    fetchResource({
      type: 'companies',
      id: user.company_id,
    }).then(company => setCompany(company));
  }, [user.company_id]);

  const handleSubmit = (values, actions) => {
    const data = new FormData(); // eslint-disable-line no-undef
    Object.keys(values).forEach(key => data.set(`company[${key}]`, values[key]));

    updateResource({
      id: company.id,
      type: 'companies',
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      successMessage: 'Successfully updated your company.',
      ...actions,
    }).then(company => {
      setCompany(company);
    });
  };

  const handleFileClick = () => fileRef.current.click();

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={normalizeInitialValues({ ...initialValues, ...company })}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <Container className="ml-0">
            <Form noValidate onSubmit={handleSubmit} className="my-4 green-focus">
              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Company Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={isInvalid('name', { touched, errors })}
                  autoFocus
                />

                <Form.Label column sm={4}>
                  Company Name
                </Form.Label>

                <Form.Control.Feedback type="invalid">{get(errors, 'name')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Description..."
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  isInvalid={isInvalid('url', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Company URL
                </Form.Label>

                <Form.Control.Feedback type="invalid">{get(errors, 'url')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="file-wrapper">
                <div className="d-flex">
                  <div onClick={handleFileClick} className="content">
                    <FontAwesomeIcon icon="upload" size="lg" />
                    Choose some photo
                  </div>

                  {values.photo_url ? (
                    <a href={values.photo_url} target="_blank">
                      <div className="photo-preview">
                        <img src={values.photo_url} alt="Profile" width="150" height="auto" />
                      </div>
                    </a>
                  ) : (
                    <div className="photo">Photo</div>
                  )}
                </div>

                <Form.Control
                  ref={fileRef}
                  type="file"
                  name="photo"
                  onChange={e => {
                    setFieldValue('photo', e.target.files[0]);
                  }}
                  isInvalid={isInvalid('photo', { touched, errors })}
                />

                <div className="file-name">{get(values, 'photo.name', '')}</div>

                <Form.Control.Feedback type="invalid">{get(errors, 'photo')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label textarea">
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  rows="4"
                />

                <Form.Label column sm={4}>
                  Description
                </Form.Label>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="number"
                  placeholder="Phone number"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  isInvalid={isInvalid('phone', { touched, errors })}
                />

                <Form.Label column sm={4}>
                  Phone number
                </Form.Label>

                <Form.Control.Feedback type="invalid">{get(errors, 'phone')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  isInvalid={isInvalid('address', { touched, errors })}
                />
                <Form.Label column sm={4}>
                  Address
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'address')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  as="select"
                  name="monthly_budget"
                  value={values.monthly_budget}
                  onChange={handleChange}
                  isInvalid={isInvalid('monthly_budget', { touched, errors })}
                  style={{ cursor: 'pointer' }}
                >
                  <option value="">Select your monthly budget</option>
                  <option value="$1,000-$5,000">$1,000-$5,000</option>
                  <option value="$5,000-$10,000">$5,000-$10,000</option>
                  <option value="$10,000-$50,000">$10,000-$50,000</option>
                  <option value="$50,000-$100,000">$50,000-$100,000</option>
                  <option value="$100,000+">$100,000+</option>
                </Form.Control>

                <Form.Label column sm={4}>
                  Monthly Budget
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'monthly_budget')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="floating-label">
                <Form.Control
                  type="text"
                  as="select"
                  name="target_audience"
                  value={values.target_audience}
                  onChange={handleChange}
                  isInvalid={isInvalid('target_audience', { touched, errors })}
                  style={{ cursor: 'pointer' }}
                >
                  <option value=""> Select your target target audience</option>
                  <option value="Software Engineering">Software Engineering</option>
                  <option value="DevOps (IaaS/PaaS)"> DevOps (IaaS/PaaS)</option>
                  <option value="Cloud Security">Cloud Security</option>
                  <option value="Product Management">Product Management</option>
                  <option value="Product Design">Product Design</option>
                  <option value="SaaS">SaaS</option>
                  <option value="Other">Other</option>
                </Form.Control>

                <Form.Label column sm={4}>
                  Target Audience
                </Form.Label>

                <Form.Control.Feedback type="invalid">
                  {get(errors, 'target_audience')}
                </Form.Control.Feedback>
              </Form.Group>

              <Button className="py-2 mt-4" type="submit" variant="success" block>
                Update Information
              </Button>
            </Form>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default CompanyForm;
