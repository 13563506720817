import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';

import useListenNotesTypeahead from 'hooks/useListenNotesTypeahead';
import { AuthContext } from 'contexts';
import { createResource } from 'utils/api';
import 'styles/PodcastPage.scss';

const PodcastsClaim = ({ setPodcastClaims }) => {
  const [price, setPrice] = useState({});
  const [invalid, setInvalid] = useState({});
  const { podcastResults, searchPodcastsRef, debouncedGetPodcasts } = useListenNotesTypeahead();
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const handleCreate = podcast => {
    const priceValue = get(price, podcast.id, '');

    if (priceValue === '' || priceValue <= 0) {
      setInvalid({ [podcast.id]: true });

      return;
    }

    createResource({
      type: 'podcast_claims',
      data: {
        podcast_claim: {
          external_id: podcast.id,
          price: priceValue * 100,
        },
      },
      successMessage: 'Successfully created a claim! We will notify you when it is approved.',
    }).then(podcastClaim => {
      setPodcastClaims(prev => [
        ...prev,
        { ...podcastClaim, podcast: { ...podcast, title: podcast.title_original } },
      ]);

      ReactGA.event({
        category: 'Podcast',
        action: 'Claimed Podcast',
        value: user.id,
      });

      history.push('/podcasts');
    });
  };

  return (
    <Container className="podcasts">
      <Row noGutters>
        <Col className="podcast-list" xl={8}>
          <h5>Claim your Podcast: </h5>

          <InputGroup className="search-input mb-3">
            <Form.Control
              type="text"
              placeholder="Find your Podcast..."
              name="podcast_id_search"
              ref={searchPodcastsRef}
              onChange={() => debouncedGetPodcasts()}
              autoComplete="off"
              autoFocus
              required
            />

            <FontAwesomeIcon icon="search" className="search" />
          </InputGroup>

          {podcastResults.length === 0 &&
            get(searchPodcastsRef, 'current.value', '').length > 0 &&
            get(searchPodcastsRef, 'current.value', '').length <= 1 && (
              <Alert variant="info">You must enter at least two letters.</Alert>
            )}

          {podcastResults.length === 0 &&
            get(searchPodcastsRef, 'current.value', '').length > 1 && (
              <Alert variant="info">No results are matching your search.</Alert>
            )}

          {podcastResults.length > 0 && get(searchPodcastsRef, 'current.value', '').length > 0 && (
            <>
              <div className="podcast-results">
                {podcastResults.map(currentPodcast => (
                  <div className="podcast-result" key={currentPodcast.id}>
                    <div className="podcast-details">
                      <div>
                        <img src={currentPodcast.thumbnail} alt="Podcast thumbnail" />
                      </div>

                      <span>{currentPodcast.title_original}</span>
                    </div>

                    <InputGroup as={Col} sm={6}>
                      <Form.Label>Average price per Sponsorship:</Form.Label>

                      <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>

                        <Form.Control
                          type="number"
                          name="price"
                          value={get(price, currentPodcast.id, '')}
                          onChange={e => setPrice({ [currentPodcast.id]: e.target.value })}
                          placeholder="Price"
                          isInvalid={get(invalid, currentPodcast.id, false)}
                        />
                      </InputGroup>

                      {get(price, currentPodcast.id, '') === '' && (
                        <Form.Control.Feedback type="invalid">
                          You must enter the price.
                        </Form.Control.Feedback>
                      )}

                      {get(price, currentPodcast.id, '') <= 0 &&
                        get(price, currentPodcast.id, '') !== '' && (
                          <Form.Control.Feedback type="invalid">
                            The price must be greater than 0.
                          </Form.Control.Feedback>
                        )}
                    </InputGroup>

                    <Button variant="success" onClick={() => handleCreate(currentPodcast)}>
                      Claim
                    </Button>
                  </div>
                ))}
              </div>
            </>
          )}
        </Col>

        {podcastResults.length !== 0 && get(searchPodcastsRef, 'current.value', '').length > 0 && (
          <Col className="price-explanation" xl={4}>
            <Alert variant="warning">
              <p>
                Prices are not final. These are estimates to help you get connected to ideal
                Advertisers.
              </p>
              <p>
                Upon submission, an Account Manager will reach out to verify your Podcast claim.
              </p>
            </Alert>
          </Col>
        )}
      </Row>
    </Container>
  );
};

PodcastsClaim.propTypes = {
  setPodcastClaims: PropTypes.func.isRequired,
};

export default PodcastsClaim;
