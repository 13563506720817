import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import startCase from 'lodash/startCase';
import get from 'lodash/get';

import useListenNotesGenres from 'hooks/useListeNotesGenres';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import { fetchResource } from 'utils/api';
import { ItineraryContextProvider } from 'contexts/ItineraryContext';
import { PixelDetailsContext } from 'contexts/PixelDetailsContext';
import AuthorizationGate from 'components/AuthorizationGate';
import Loader from 'components/Loader';
import FormDetails from './FormDetails';
import Itinerary from './Itinerary';
import SponsorshipChat from './SponsorshipChat';
import AttachmentsSidebar from './AttachmentsSidebar';
import 'styles/SponsorshipDetails.scss';

const SponsorshipDetails = () => {
  const [sponsorship, setSponsorship] = useState(null);
  const [podcast, setSelectedPodcast] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const { setSponsorship: setPixelSponsorship } = useContext(PixelDetailsContext);
  const { setPodcast } = useContext(PodcastDetailsContext);
  const genres = useListenNotesGenres();
  const { id } = useParams();

  useEffect(() => {
    fetchResource({
      type: 'sponsorships',
      id,
    }).then(sponsorship => {
      const id = sponsorship.podcast_id;

      fetchResource({
        type: 'podcasts',
        id,
      }).then(podcast => {
        setSelectedPodcast(podcast);
        setSponsorship(sponsorship);
      });
    });
  }, [id, setSponsorship, setSelectedPodcast]);

  if (!sponsorship) {
    return <Loader />;
  }

  if (!podcast) {
    return <div className="sponsorship-details" />;
  }

  const genreIds = get(podcast, 'podcast.genre_ids', []);
  const filteredGenres = genres.filter(genre => genreIds.includes(genre.id));

  return (
    <Container className="sponsorship-details-page">
      {sponsorship && (
        <>
          <Row className="header" noGutters onClick={() => setSponsorship(sponsorship)}>
            <div className="details">
              <div className="img-field">
                <img
                  src={podcast.podcast.thumbnail}
                  onClick={() => (podcast.podcast.thumbnail ? setPodcast(podcast.podcast) : '')}
                  alt="Podcast thumbnail"
                />

                {podcast.podcast.thumbnail && (
                  <span onClick={() => setPodcast(podcast.podcast)}>Learn more</span>
                )}
              </div>

              <div className="title">
                <span className="font-weight-bold">{sponsorship.name}</span>

                <div className="status">
                  <Badge variant="success">{startCase(sponsorship.status)}</Badge>
                </div>

                <div className="podcast-name">
                  Podcast: <span>{podcast.podcast.title}</span>
                </div>
              </div>
            </div>

            {filteredGenres.length > 0 && (
              <div className="genres mb-auto">
                Genres:
                <div>
                  {filteredGenres.map(genre => (
                    <Badge key={genre.id} variant="success">
                      {genre.name}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            <div className="header-icons">
              <div onClick={() => setOpenChat(prevState => !prevState)} className="messages">
                <FontAwesomeIcon icon="envelope" />

                <span className="title">Messages</span>

                {sponsorship.unread_messages_count > 0 && (
                  <Badge variant="secondary" className="notif-badge">
                    {sponsorship.unread_messages_count}
                  </Badge>
                )}
              </div>

              <div onClick={() => setOpenFiles(prevState => !prevState)} className="attachments">
                <FontAwesomeIcon icon="paperclip" />
                <span className="title">Attachments</span>

                {sponsorship.attachments.length > 0 && (
                  <span variant="secondary" className="num-of-files ml-1">
                    ({sponsorship.attachments.length})
                  </span>
                )}
              </div>

              {sponsorship.status !== 'opportunity' && sponsorship.advertiser_copy && (
                <AuthorizationGate roles="advertiser, admin">
                  <div onClick={() => setPixelSponsorship(sponsorship)} className="pixel-details">
                    <FontAwesomeIcon icon="chart-line" />
                    <span className="title">Pixel Details</span>
                  </div>
                </AuthorizationGate>
              )}
            </div>
          </Row>
        </>
      )}

      <ItineraryContextProvider>
        <Row noGutters style={{ borderBottom: '1px solid #dae1e5', marginBottom: '10px' }}>
          <Col className="itinerary" xs={12} xl={8}>
            {sponsorship && <Itinerary sponsorship={sponsorship} />}
          </Col>
        </Row>

        <Row noGutters>
          <Col className="body" xs={12}>
            {sponsorship && (
              <FormDetails setSponsorship={setSponsorship} sponsorship={sponsorship} />
            )}
          </Col>
        </Row>
      </ItineraryContextProvider>

      {openChat === true && (
        <div className={openChat ? 'opened' : ''}>
          <SponsorshipChat sponsorship={sponsorship} setOpenChat={setOpenChat} />
        </div>
      )}

      {openFiles === true && (
        <div className={openFiles ? 'opened' : ''}>
          <AttachmentsSidebar
            setOpenFiles={setOpenFiles}
            sponsorship={sponsorship}
            setSponsorship={setSponsorship}
          />
        </div>
      )}
    </Container>
  );
};

export default SponsorshipDetails;
