import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthorizationGate from 'components/AuthorizationGate';
import { createResource } from 'utils/api';
import { AuthContext } from 'contexts/index';

const InterestActions = ({ sponsorship, approveCallback, rejectCallback }) => {
  const { user } = useContext(AuthContext);
  const match = useRouteMatch('/sponsorships/:id/details');

  const handleApproveInterest = id => {
    createResource({
      type: 'sponsorships',
      endpoint: `sponsorships/${id}/approve_interest`,
      successMessage: 'You are now interested in this Sponsorship.',
    }).then(approveCallback);
  };

  const handleRejectInterest = selectedSponsorship => {
    const { id } = selectedSponsorship;

    createResource({
      type: 'sponsorships',
      endpoint: `sponsorships/${id}/reject`,
      successMessage: 'You declined this Sponsorship.',
    }).then(() => rejectCallback(id));
  };

  return (
    <Col className="right-side" sm={6}>
      <div className="interested">
        <AuthorizationGate roles="advertiser">
          {sponsorship.status !== 'opportunity' && (
            <Alert variant="success">
              <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
              You are interested in this Sponsorship.
            </Alert>
          )}
        </AuthorizationGate>

        <AuthorizationGate roles="podcaster, admin, account_manager">
          {sponsorship.status === 'opportunity' ? (
            <Alert variant="info">
              <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
              Waiting for Advertiser to approve the interest.
            </Alert>
          ) : (
            <Alert variant="success">
              <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
              Advertiser approved the interest!
            </Alert>
          )}
        </AuthorizationGate>

        {user.role === 'advertiser' && sponsorship.status === 'opportunity' && (
          <div className="advertiser-box">
            {match && (
              <div>
                <h3>Interested?</h3>
                <span>You will not be charged immediately</span>
              </div>
            )}

            <div className="d-flex justify-content-center">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  handleApproveInterest(sponsorship.id);
                }}
                className="thumbs-up-button"
                variant="success"
              >
                <FontAwesomeIcon icon="thumbs-up" />
              </Button>

              <Button
                onClick={e => {
                  e.stopPropagation();
                  handleRejectInterest(sponsorship);
                }}
                className="thumbs-down-button ml-2"
                variant="danger"
              >
                <FontAwesomeIcon icon="thumbs-down" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Col>
  );
};

InterestActions.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  approveCallback: PropTypes.func.isRequired,
  rejectCallback: PropTypes.func.isRequired,
};

export default InterestActions;
