import React, { useRef, useCallback, useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import usePaginatedData from 'hooks/usePaginatedData';
import { deleteResource } from 'utils/api';
import { formatPrice } from 'utils/forms';
import AuthorizationGate from 'components/AuthorizationGate';
import PaginationFooter from 'components/PaginationFooter';
import Loader from 'components/Loader';
import { AuthContext } from 'contexts/index';
import CallToAction from './CallToAction';
import InterestActions from './InterestActions';

const SponsorshipsList = ({
  hasResults,
  status,
  sponsorships,
  setSponsorships,
  setStatus,
  fetchData,
  filterByCampaign,
  setFilterByCampaign,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { data: campaigns, isLoading: areCampaignsLoading } = usePaginatedData({
    endpoint: '/api/v1/campaigns',
    effectEnabled: user.role !== 'podcaster',
  });
  const [search, setSearch] = useState('');
  const history = useHistory();
  const searchCampaignRef = useRef();

  const handleSearchChange = () => {
    const inputValue = searchCampaignRef.current.value;
    setSearch(inputValue);
  };

  const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 300));

  const approveCallback = () => {
    setStatus('in_development');
  };

  const rejectCallback = id => {
    setSponsorships(prev => prev.filter(sponsorship => sponsorship.id !== id));
  };

  const removeSponsorship = sponsorship => {
    deleteResource({
      type: 'sponsorships',
      id: sponsorship.id,
      message: 'Successfully deleted Sponsorship.',
    }).then(() => {
      setSponsorships(state => {
        return state.filter(value => value !== sponsorship);
      });
    });
  };

  const handleStatusMessage = () => {
    switch (status) {
      case 'opportunity':
        return <Alert variant="info">You have no Opportunities yet.</Alert>;
      case 'in_development':
        return <Alert variant="info">You have no Sponsorships in development yet.</Alert>;
      case 'scheduled':
        return <Alert variant="info">You have no scheduled Sponsorships yet.</Alert>;
      case 'launched':
        return <Alert variant="info">You have no launched Sponsorships yet.</Alert>;
      default:
        return null;
    }
  };

  const handleApprove = sponsorship => {
    const { advertiser_approved } = sponsorship;
    const { podcaster_approved } = sponsorship;

    if (advertiser_approved && podcaster_approved) {
      return <span>Approved by Podcaster and Advertiser!</span>;
    }
    if (!advertiser_approved && podcaster_approved) {
      return (
        <span>Waiting for {user.role === 'advertiser' ? 'you' : 'Advertiser'} to approve.</span>
      );
    }

    if (advertiser_approved && !podcaster_approved) {
      return <span>Waiting for {user.role === 'podcaster' ? 'you' : 'Podcaster'} to approve.</span>;
    }

    return (
      <span>
        Waiting for {user.role === 'advertiser' ? 'you' : 'Advertiser'} and{' '}
        {user.role === 'podcaster' ? 'you' : 'Podcaster'} to approve.
      </span>
    );
  };

  useEffect(() => {
    const locationId = get(location, 'state.campaign_id');
    const campaignId = filterByCampaign && locationId ? locationId : null;

    setIsLoading(true);

    fetchData({
      campaign_id: campaignId,
      q: search,
      status,
    })
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [location, fetchData, search, status, filterByCampaign]);

  if (!hasResults && !areCampaignsLoading && !isLoading) {
    return <CallToAction campaigns={campaigns} hasResults={hasResults} />;
  }

  const campaignName = get(location, 'state.campaign_name');
  const campaignId = get(location, 'state.campaign_id');

  return (
    <div className="sponsorships-body">
      {sponsorships.length === 0 && search.length === 0 && handleStatusMessage()}

      {status === 'opportunity' && sponsorships.length > 0 && (
        <>
          {user.role !== 'podcaster' ? (
            <Alert variant="warning">
              Approving an Opportunity will move the Sponsorship to "In Development", where{' '}
              {user.role === 'advertiser' ? 'you' : 'advertiser'} will finalize ad copy and launch
              dates with the Podcaster.
            </Alert>
          ) : (
            <Alert variant="warning">
              Waiting for Advertiser to confirm interest. Please contact your Account Manager with
              any questions.
            </Alert>
          )}
        </>
      )}

      <div className="filter">
        {campaignId && campaignName && filterByCampaign && (
          <div className="result-by-campaign">
            <div>
              <p>
                Filter by Campaign: <strong>{campaignName}</strong>
              </p>
              <span>ID: {campaignId}</span>
            </div>

            <Button onClick={() => setFilterByCampaign(false)}>Show all</Button>
          </div>
        )}

        <Form.Group className="search-input">
          <Form.Control
            type="text"
            placeholder="Search by Campaign/Sponsorship"
            name="search"
            ref={searchCampaignRef}
            onChange={debouncedHandleSearchChange}
            autoComplete="off"
            autoFocus
          />

          <FontAwesomeIcon icon="search" />
        </Form.Group>
      </div>

      {isLoading && <Loader />}

      {search.length > 0 && sponsorships.length === 0 && (
        <Alert variant="info">No results are matching your search.</Alert>
      )}

      {sponsorships.length > 0 && (
        <Table className="sponsorships-list" responsive>
          <thead>
            <tr>
              <th>Sponsorship Name</th>
              <th>Campaign Name</th>
              <th>Price</th>
              {status === 'opportunity' && <th>Placements</th>}
              <th>Start Date</th>

              <AuthorizationGate roles="advertiser">
                {status === 'opportunity' && <th className="text-center">Interested?</th>}
              </AuthorizationGate>

              <AuthorizationGate roles="advertiser, podcaster">
                {status === 'in_development' && <th>Actions</th>}
              </AuthorizationGate>

              <AuthorizationGate roles="admin, account_manager">
                <th className="text-center">Actions</th>
              </AuthorizationGate>
            </tr>
          </thead>

          <tbody className="table-body">
            {sponsorships.map((sponsorship, index) => (
              <tr
                onClick={() => history.push(`/sponsorships/${sponsorship.id}/details`)}
                className="table-row"
                key={sponsorship.id}
              >
                <td>{sponsorship.name}</td>
                <td>{sponsorship.campaign_name}</td>
                <td>{formatPrice(sponsorship.price)}</td>
                {status === 'opportunity' && <td>{sponsorship.placements}</td>}
                <td>{sponsorship.start_date}</td>

                <AuthorizationGate roles="advertiser">
                  {sponsorship.status === 'opportunity' && (
                    <td className="interest-btns">
                      <InterestActions
                        sponsorship={sponsorship}
                        approveCallback={approveCallback}
                        rejectCallback={rejectCallback}
                      />
                    </td>
                  )}
                </AuthorizationGate>

                <AuthorizationGate roles="advertiser, podcaster">
                  {sponsorship.status === 'in_development' && (
                    <td>
                      <Link to="#">
                        <span className="alert-icon ml-3">
                          <OverlayTrigger
                            placement={
                              index === sponsorships.indexOf(sponsorships[sponsorships.length - 1])
                                ? 'top'
                                : 'bottom'
                            }
                            overlay={
                              <Tooltip>
                                {(sponsorship.advertiser_copy || '').length === 0 &&
                                  (sponsorship.advertiser_promo_url || '').length === 0 &&
                                  user.role === 'advertiser' && (
                                    <ul>
                                      You must add the following:
                                      <li className="text-left">&ndash; Ad Copy</li>
                                      <li className="text-left">&ndash; Promotional URL</li>
                                    </ul>
                                  )}

                                {(sponsorship.advertiser_copy || '').length === 0 &&
                                  (sponsorship.advertiser_promo_url || '').length === 0 &&
                                  user.role === 'podcaster' &&
                                  'Advertiser must add Ad Copy and Promotional URL.'}

                                {(sponsorship.advertiser_copy || '').length > 0 &&
                                  (sponsorship.advertiser_promo_url || '').length > 0 &&
                                  handleApprove(sponsorship)}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                (sponsorship.advertiser_copy || '').length === 0 &&
                                (sponsorship.advertiser_promo_url || '').length === 0
                                  ? 'exclamation-circle'
                                  : 'user-clock'
                              }
                            />
                          </OverlayTrigger>
                        </span>
                      </Link>
                    </td>
                  )}
                </AuthorizationGate>

                <AuthorizationGate roles="admin, account_manager">
                  <td className="admin-actions">
                    <span
                      className="edit"
                      onClick={e => {
                        e.stopPropagation();
                        history.push(`/sponsorships/${sponsorship.id}/edit`);
                      }}
                    >
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Edit</Tooltip>}>
                        <FontAwesomeIcon icon="edit" size="lg" />
                      </OverlayTrigger>
                    </span>

                    <span
                      className="delete"
                      onClick={e => {
                        e.stopPropagation();
                        removeSponsorship(sponsorship);
                      }}
                    >
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Delete</Tooltip>}>
                        <FontAwesomeIcon icon="trash-alt" size="lg" />
                      </OverlayTrigger>
                    </span>
                  </td>
                </AuthorizationGate>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <PaginationFooter data={sponsorships} title="Sponsorships" {...rest} />
    </div>
  );
};

SponsorshipsList.propTypes = {
  hasResults: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  sponsorships: PropTypes.array.isRequired,
  setSponsorships: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  setFilterByCampaign: PropTypes.func.isRequired,
  filterByCampaign: PropTypes.bool.isRequired,
};

export default SponsorshipsList;
