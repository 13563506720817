import React, { useContext } from 'react';
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  matchPath,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AuthorizationGate from 'components/AuthorizationGate';
import { AuthContext } from 'contexts/';
import PixelDetails from 'components/sponsorships/PixelDetails';
import CompanyForm from './CompanyForm';
import ProfileForm from './ProfileForm';
import PaymentPage from './PaymentPage';
import 'styles/SettingsPage.scss';

const SettingsPage = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const match = useRouteMatch('/settings/payment');
  const matchPixel = useRouteMatch('/settings/pixel');
  const settings = matchPath(location.pathname, { path: '/settings', exact: true });

  const titles = [
    { title: 'Company', value: '/settings/company' },
    { title: 'Profile', value: '/settings/profile' },
    { title: 'Payment', value: '/settings/payment' },
  ];

  const getRoles = title => {
    if (title === 'Company') {
      return 'advertiser';
    }
    if (title === 'Payment') {
      return 'advertiser, podcaster';
    }
    return 'advertiser, podcaster, admin, account_manager';
  };

  if (settings) {
    if (user.role === 'advertiser') {
      return <Redirect to="/settings/company" />;
    }

    return <Redirect to="/settings/profile" />;
  }

  return (
    <Container className="settings-page">
      <Row noGutters>
        <ul className="header-links">
          {titles.map((title, index) => (
            <AuthorizationGate roles={getRoles(title.title)} key={index}>
              <NavLink to={title.value} activeClassName="active" className="settings-link" exact>
                <li>{title.title}</li>
              </NavLink>
            </AuthorizationGate>
          ))}

          <AuthorizationGate roles="advertiser">
            <NavLink to="/settings/pixel">
              <li>Pixel</li>
            </NavLink>
          </AuthorizationGate>
        </ul>
      </Row>

      {match || matchPixel ? (
        <Row noGutters>
          <Col className="main-body" sm={12}>
            <Route path="/settings/payment" component={PaymentPage} />
            <Route path="/settings/pixel" component={PixelDetails} />
          </Col>
        </Row>
      ) : (
        <Row noGutters>
          <Col className="main-body settings-body" xs={12} sm={8} xl={6}>
            <Switch>
              <Route path="/settings/company" component={CompanyForm} />
              <Route path="/settings/profile" component={ProfileForm} />
            </Switch>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default SettingsPage;
