import has from 'lodash/has';

export const isInvalid = (name, { touched, errors }) => {
  const isTouched = has(touched, name);
  const hasError = has(errors, name);

  return isTouched && hasError;
};

export const formatPrice = price => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price / 100);
};

export const normalizeInitialValues = values => {
  const normalized = {};

  Object.entries(values).forEach(([key, value]) => {
    let normalizedValue = value;

    if (normalizedValue === null || normalizedValue === undefined) {
      normalizedValue = '';
    }

    normalized[key] = normalizedValue;
  });

  return normalized;
};
