/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { fetchResources } from 'utils/api';

const useListenNotesGenres = () => {
  let defaultState = [];

  try {
    defaultState = JSON.parse(localStorage.getItem('listen_notes_genres')) || [];
  } catch (err) {
    defaultState = [];
  }

  const [genres, setGenres] = useState(defaultState);

  useEffect(() => {
    if (genres.length > 0) return;

    fetchResources({
      type: 'listen_notes',
      query: {
        endpoint: 'genres',
      },
    }).then(({ genres }) => {
      try {
        localStorage.setItem('listen_notes_genres', JSON.stringify(genres));
      } catch (err) {
        // Local storage not enabled
      }

      setGenres(genres);
    });
  }, [genres, setGenres]);

  return genres;
};

export default useListenNotesGenres;
