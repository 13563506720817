import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from 'contexts';
import { stripeClientId } from 'utils/constants';
import stripePayment from 'images/stripe_payment.png';
import stripePaymentRetina from 'images/stripe_payment@2x.png';
import 'styles/StripeConnect.scss';

const StripeConnect = ({ sponsorshipId }) => {
  const { user } = useContext(AuthContext);

  const url = `https://connect.stripe.com/express/oauth/authorize?client_id=${stripeClientId}&suggested_capabilities[]=transfers&stripe_user[email]=${user.email}`;

  useEffect(() => {
    if (sponsorshipId && !user.stripe_connected) {
      // eslint-disable-next-line no-undef
      document.cookie = `connect_sponsorship_id=${sponsorshipId}; path=/`;
    }
  }, [sponsorshipId, user.stripe_connected]);

  if (user.stripe_connected) {
    return (
      <Alert variant="success">
        <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
        Your integration with Stripe is successful.
      </Alert>
    );
  }

  return (
    <div className="call-to-action">
      <img
        src={stripePayment}
        srcSet={`${stripePayment} 1x, ${stripePaymentRetina} 2x`}
        alt="No Sponsorships"
        width="230"
      />
      <h5 className="mb-4 mt-3">
        You must connect to Stripe to approve the Sponsorship and receive payments
      </h5>

      <a href={url} className="stripe-connect">
        <Button variant="success" size="lg" style={{ fontWeight: '600' }}>
          Connect with Stripe
        </Button>
      </a>
    </div>
  );
};

StripeConnect.propTypes = {
  sponsorshipId: PropTypes.string,
};

StripeConnect.defaultProps = {
  sponsorshipId: null,
};

export default StripeConnect;
