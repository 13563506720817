import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SingleDatePicker } from 'react-dates';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';

import { isInvalid } from 'utils/forms';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import { AuthContext } from 'contexts';
import useListenNotesTypeahead from 'hooks/useListenNotesTypeahead';
import { createResource, updateResource } from 'utils/api';
import { Mobile, Default } from 'utils/responsive';
import WhatNext from './WhatNext';
import 'react-dates/lib/css/_datepicker.css';

const defaultRequired = 'is required.';

const schema = yup.object({
  name: yup.string().required(defaultRequired),
  description: yup.string().required(defaultRequired),
  budget: yup.string().required(defaultRequired),
  start_date: yup.date('is not a valid date').required(defaultRequired),
  category_ids: yup.array(),
  targeted_podcast_ids: yup.array(),
});

const CampaignForm = ({
  initialValues,
  newRecord,
  defaultSelectedPodcasts,
  defaultSelectedGenres,
}) => {
  const { setPodcast } = useContext(PodcastDetailsContext);
  const { user } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const history = useHistory();

  const {
    podcastResults,
    podcastsFocused,
    setPodcastsFocused,
    genresFocused,
    setGenresFocused,
    selectedGenres,
    startDateFocused,
    setStartDateFocused,
    debouncedGetPodcasts,
    debouncedGetGenres,
    removePodcast,
    removeGenre,
    selectPodcast,
    selectGenre,
    searchPodcastsRef,
    searchGenresRef,
    genreResults,
    selectedPodcasts,
    setSelectedPodcasts,
  } = useListenNotesTypeahead(defaultSelectedGenres);

  const handleCreate = (values, actions) => {
    const targeted_podcast_ids = selectedPodcasts.map(podcast => podcast.id);
    const category_ids = selectedGenres.map(genre => genre.id);

    createResource({
      type: 'campaigns',
      data: { campaign: { ...values, targeted_podcast_ids, category_ids } },
      successMessage: 'Successfully created a new campaign!',
      ...actions,
    }).then(() => {
      setPodcast(null);

      ReactGA.event({
        category: 'Campaign',
        action: 'Created Campaign',
        value: user.id,
      });

      history.push({
        pathname: '/campaigns',
        state: { create: true },
      });
    });
  };

  const handleUpdate = (values, actions) => {
    const targeted_podcast_ids = selectedPodcasts.map(podcast => podcast.id);
    const category_ids = selectedGenres.map(genre => genre.id);

    updateResource({
      type: 'campaigns',
      id: initialValues.id,
      data: { campaign: { ...values, targeted_podcast_ids, category_ids } },
      successMessage: 'Successfully updated the campaign.',
      ...actions,
    }).then(() => {
      setPodcast(null);

      history.push({
        pathname: '/campaigns',
        state: { update: true },
      });
    });
  };

  const validateStepOne = values => {
    if (
      values.name.length > 0 &&
      values.description.length > 0 &&
      values.budget.length > 0 &&
      values.start_date
    ) {
      return true;
    }

    return false;
  };

  const goToStep = (step, values) => {
    const valid = step === 2 ? validateStepOne(values) : true;

    if (valid) {
      setStep(2);
    }
  };

  useEffect(() => {
    setSelectedPodcasts(defaultSelectedPodcasts);
  }, [defaultSelectedPodcasts, setSelectedPodcasts]);

  useEffect(() => {
    return () => {
      setPodcast(null);
    };
  }, [setPodcast]);

  return (
    <Row noGutters>
      <Col className="main-body" xs={12} md={8} xl={6}>
        <div className="form-container">
          <div className="steps">
            <div className={step === 1 || step === 2 ? 'active-step' : 'step'}>
              <Button
                className={step === 1 ? 'active' : 'circle'}
                onClick={() => setStep(1)}
                variant="success"
              >
                {step === 2 ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip>View this step</Tooltip>}>
                    <FontAwesomeIcon icon="check" />
                  </OverlayTrigger>
                ) : (
                  1
                )}
              </Button>

              <span>Summary</span>
            </div>

            <div className={step === 2 ? 'active-step' : 'step'}>
              <Button className={step === 2 ? 'active' : 'circle'} variant="success">
                2
              </Button>

              <span>Targeting</span>
            </div>
          </div>

          <Formik
            validationSchema={schema}
            onSubmit={newRecord ? handleCreate : handleUpdate}
            enableReinitialize
            initialValues={{ ...initialValues }}
          >
            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
              <Container className="ml-0">
                <Form noValidate onSubmit={handleSubmit} className="green-focus style-form">
                  {step === 1 ? (
                    <div className="step-one-content">
                      <Alert variant="warning" className="mb-4">
                        Provide basic details on your budget, and timeline so we can learn more
                        about your goals. These details can be refined with your Account Manager
                        over time.
                      </Alert>

                      <Form.Group className="floating-label">
                        <Form.Control
                          type="text"
                          placeholder="Campaign Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={isInvalid('name', { touched, errors })}
                          autoFocus
                        />

                        <Form.Label column sm={4}>
                          Campaign Name
                        </Form.Label>

                        <Form.Control.Feedback type="invalid">
                          {get(errors, 'name')}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="floating-label">
                        <Form.Control
                          as="textarea"
                          placeholder="Describe your Brand and Campaign goals to Podcasters..."
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={isInvalid('description', { touched, errors })}
                          rows="4"
                        />

                        <Form.Label column sm={4}>
                          Description
                        </Form.Label>

                        <Form.Control.Feedback type="invalid">
                          {get(errors, 'description')}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="floating-label">
                        <Form.Control
                          as="select"
                          type="text"
                          name="budget"
                          value={values.budget}
                          onChange={handleChange}
                          isInvalid={isInvalid('budget', { touched, errors })}
                        >
                          <option value="" label="Select your monthly budget" />
                          <option value="$1,000-$5,000" label="$1,000-$5,000" />
                          <option value="$5,000-$10,000" label="$5,000-$10,000" />
                          <option value="$10,000-$50,000" label="$10,000-$50,000" />
                          <option value="$50,000-$100,000" label="$50,000-$100,000" />
                          <option value="$100,000+" label="$100,000+" />
                        </Form.Control>

                        <Form.Label>Monthly Budget</Form.Label>

                        <Form.Control.Feedback type="invalid">
                          {get(errors, 'budget')}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Col
                          sm={12}
                          className={`start-date-container ${(startDateFocused ? 'focused' : '',
                          'floating-label')} ${
                            isInvalid('start_date', { touched, errors }) ? 'invalid' : ''
                          }`}
                        >
                          <Form.Label column sm={4}>
                            Target Start Date
                          </Form.Label>

                          <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={isInvalid('start_date', { touched, errors })}
                            sm={8}
                          />

                          <div
                            onClick={() => setStartDateFocused(prev => !prev)}
                            className="calendar"
                          >
                            <FontAwesomeIcon icon="calendar" />
                          </div>

                          <Default>
                            <SingleDatePicker
                              id="start_date"
                              placeholder="Start Date"
                              date={values.start_date}
                              onDateChange={date => setFieldValue('start_date', date)}
                              focused={startDateFocused}
                              onFocusChange={({ focused }) => setStartDateFocused(focused)}
                              orientation="horizontal"
                              numberOfMonths={2}
                              small
                              block
                            />
                          </Default>

                          <Mobile>
                            <SingleDatePicker
                              id="start_date"
                              placeholder="Start Date"
                              date={values.start_date}
                              onDateChange={date => setFieldValue('start_date', date)}
                              focused={startDateFocused}
                              onFocusChange={({ focused }) => setStartDateFocused(focused)}
                              orientation="vertical"
                              numberOfMonths={1}
                              small
                              block
                            />
                          </Mobile>

                          <Form.Control.Feedback type="invalid">
                            Is not a valid date
                          </Form.Control.Feedback>
                        </Col>

                        <Button
                          onClick={() => goToStep(2, values)}
                          className="py-2 mt-4"
                          type="submit"
                          variant="success"
                          block
                        >
                          Continue to Targeting
                        </Button>
                      </Form.Group>
                    </div>
                  ) : (
                    <div className="step-two-content">
                      <Alert variant="warning">
                        Let us know about your target audience so we can connect you to the right
                        Podcasters.
                      </Alert>

                      <div className="mb-3">
                        What types of Podcasts are you interested in targeting?
                        <span className="option ml-1">(Optional)</span>
                      </div>

                      <Form.Group className="floating-label">
                        <Form.Control
                          type="text"
                          placeholder="Search by Genres"
                          name="category_ids"
                          ref={searchGenresRef}
                          onChange={debouncedGetGenres}
                          autoComplete="off"
                          onFocus={() => setGenresFocused(true)}
                          onBlur={() => setGenresFocused(false)}
                          isInvalid={isInvalid('category_ids', { touched, errors })}
                          autoFocus
                        />

                        <Form.Label column sm={4}>
                          Genres
                        </Form.Label>

                        <Form.Control.Feedback type="invalid">
                          {get(errors, 'category_ids')}
                        </Form.Control.Feedback>

                        <FontAwesomeIcon icon="search" className="search" />

                        {genreResults.length > 0 && genresFocused && (
                          <div className="genre-results">
                            {genreResults
                              .filter(result => !selectedGenres.find(sg => sg.id === result.id))
                              .map(genre => (
                                <div
                                  className="genre-result"
                                  key={genre.id}
                                  onMouseDown={() => selectGenre(genre)}
                                >
                                  <div className="genre-name">{genre.name}</div>
                                </div>
                              ))}
                          </div>
                        )}

                        {selectedGenres.length > 0 && <div>Targeted Genres:</div>}

                        {genreResults.length === 0 &&
                          get(searchGenresRef, 'current.value', '').length > 0 && (
                            <Alert variant="info">No results are matching your search.</Alert>
                          )}

                        {selectedGenres.map(genre => (
                          <Badge key={genre.id} className="selected-genre" variant="success">
                            {genre.name}
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                removeGenre(genre);
                              }}
                              className="remove-icon"
                            >
                              <FontAwesomeIcon icon="times" />
                            </span>
                          </Badge>
                        ))}
                      </Form.Group>

                      <div className="mb-3">
                        Are there specific Podcasts that you want to Sponsor?
                        <span className="option ml-1">(Optional)</span>
                      </div>

                      <Form.Group className="floating-label">
                        <Form.Control
                          type="text"
                          placeholder="Search by Podcast Name"
                          name="targeted_podcast_ids"
                          ref={searchPodcastsRef}
                          onChange={() => debouncedGetPodcasts()}
                          autoComplete="off"
                          onFocus={() => setPodcastsFocused(true)}
                          onBlur={() => setPodcastsFocused(false)}
                          isInvalid={isInvalid('targeted_podcast_ids', { touched, errors })}
                        />

                        <Form.Label column sm={4}>
                          Podcast Name
                        </Form.Label>

                        <Form.Control.Feedback type="invalid">
                          {get(errors, 'targeted_podcast_ids')}
                        </Form.Control.Feedback>

                        <FontAwesomeIcon icon="search" className="search" />

                        {podcastResults.length === 0 &&
                          get(searchPodcastsRef, 'current.value', '').length > 0 &&
                          get(searchPodcastsRef, 'current.value', '').length <= 1 && (
                            <Alert variant="info">You must enter at least two letters.</Alert>
                          )}

                        {podcastResults.length > 0 &&
                          podcastsFocused &&
                          searchPodcastsRef.current.value.length > 0 && (
                            <div className="podcast-results">
                              {podcastResults
                                .filter(result => !selectedPodcasts.find(sp => sp.id === result.id))
                                .map(podcast => (
                                  <div
                                    className="podcast-result"
                                    key={podcast.id}
                                    onMouseDown={() => selectPodcast(podcast)}
                                  >
                                    <img src={podcast.thumbnail} alt="Podcast thumbnail" />
                                    <div className="podcast-name">{podcast.title_original}</div>
                                  </div>
                                ))}
                            </div>
                          )}

                        {selectedPodcasts.length > 0 && <div>Targeted Podcasts:</div>}

                        {selectedPodcasts.map(podcast => (
                          <div key={podcast.id} className="selected-podcast">
                            <img src={podcast.thumbnail} alt="Podcast thumbnail" />
                            <div className="podcast-name">
                              {podcast.title_original || podcast.title}
                            </div>

                            <div className="podcast-actions">
                              <span onClick={() => setPodcast(podcast)} className="view-details">
                                View Details
                              </span>

                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  removePodcast(podcast);
                                  setPodcast(null);
                                }}
                                className="remove"
                              >
                                Remove
                              </span>
                            </div>
                          </div>
                        ))}
                      </Form.Group>

                      <Button className="py-2 mt-4" type="submit" variant="success" block>
                        {newRecord ? 'Create a Campaign' : 'Update Campaign'}
                      </Button>
                    </div>
                  )}
                </Form>
              </Container>
            )}
          </Formik>
        </div>
      </Col>

      {step === 2 && (
        <Col className="main-body what-next" xs={12} md={8} xl={6}>
          <WhatNext />
        </Col>
      )}
    </Row>
  );
};

CampaignForm.propTypes = {
  newRecord: PropTypes.bool,
  initialValues: PropTypes.object,
  defaultSelectedPodcasts: PropTypes.array,
  defaultSelectedGenres: PropTypes.array,
};

CampaignForm.defaultProps = {
  newRecord: true,
  initialValues: {
    name: '',
    description: '',
    budget: '',
    start_date: null,
    category_ids: [],
    targeted_podcast_ids: [],
  },
  defaultSelectedPodcasts: [],
  defaultSelectedGenres: [],
};

export default CampaignForm;
