import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';

import { formatPrice } from 'utils/forms';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';

const PodcastClaimList = ({ podcastClaims }) => {
  const { setPodcast } = useContext(PodcastDetailsContext);

  return (
    <div className="podcasts">
      {podcastClaims.map(podcastClaim => (
        <React.Fragment key={podcastClaim.id}>
          <div className="claimed-podcast">
            <div className="podcast-details">
              <div className="see-more">
                <img
                  src={get(podcastClaim, 'podcast.thumbnail', '')}
                  onClick={() =>
                    podcastClaim.podcast.thumbnail ? setPodcast(podcastClaim.podcast) : ''
                  }
                  alt="Podcast Thumbnail"
                />

                {podcastClaim.podcast.thumbnail && (
                  <span onClick={() => setPodcast(podcastClaim.podcast)} className="show-more">
                    Show more
                  </span>
                )}
              </div>

              <span className="ml-2">{get(podcastClaim, 'podcast.title', '')}</span>
            </div>

            <div className="price">
              <div>
                Average price per Sponsorship:{' '}
                <span style={{ fontWeight: 700 }}>{formatPrice(podcastClaim.price)}</span>
              </div>
              <Badge variant="secondary">Waiting for approval</Badge>
            </div>
          </div>

          <Alert variant="warning" className="message">
            An Account Manager will be in touch shortly to verify your Podcast claim.
          </Alert>
        </React.Fragment>
      ))}
    </div>
  );
};

PodcastClaimList.propTypes = {
  podcastClaims: PropTypes.array.isRequired,
};

export default PodcastClaimList;
