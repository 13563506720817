import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { AuthContext } from 'contexts';
import { ItineraryContext } from 'contexts/ItineraryContext';
import successLaunch from 'images/success_launch.png';
import successLaunchRetina from 'images/success_launch@2x.png';
import successPayment from 'images/success_payment.png';
import successPaymentRetina from 'images/success_payment@2x.png';
import AuthorizationGate from 'components/AuthorizationGate';
import { PixelDetailsContext } from 'contexts/PixelDetailsContext';
import AdvertiserDetailsForm from './AdvertiserDetailsForm';
import SponsorshipPreview from './SponsorshipPreview';
import ScheduleSection from './ScheduleSection';
import LaunchSection from './LaunchSection';

const Steps = ({ sponsorship, setSponsorship }) => {
  const { user } = useContext(AuthContext);
  const { step, setStep } = useContext(ItineraryContext);
  const { setSponsorship: setPixelSponsorship } = useContext(PixelDetailsContext);

  const isLaunched = moment().diff(`${sponsorship.start_date}`, 'days') > 0;

  return (
    <Container>
      {step === 2 && user.role !== 'advertiser' && (
        <Row className="d-flex justify-content-between" noGutters>
          <Col md={6} xl={5}>
            <SponsorshipPreview sponsorship={sponsorship} />
          </Col>

          <Col sm={6}>
            {sponsorship.advertiser_copy.length === 0 &&
            sponsorship.advertiser_promo_url.length === 0 ? (
              <Alert variant="warning">
                <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
                Waiting for advertiser to finalize advertising details.
              </Alert>
            ) : (
              <Alert variant="success">
                <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                Advertiser finalized advertising details.
              </Alert>
            )}
          </Col>
        </Row>
      )}

      {step === 2 && user.role === 'advertiser' && (
        <Row className="advertiser-details-form d-flex justify-content-between" noGutters>
          {sponsorship.advertiser_copy.length === 0 &&
          sponsorship.advertiser_promo_url.length === 0 ? (
            <Col md={6} xl={5}>
              <AdvertiserDetailsForm setSponsorship={setSponsorship} sponsorship={sponsorship} />
            </Col>
          ) : (
            <>
              <Col md={6} xl={5}>
                <SponsorshipPreview sponsorship={sponsorship} />
              </Col>

              <Col sm={6}>
                <Alert variant="success">
                  <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                  {user.role !== 'advertiser'
                    ? 'Advertising details are finalized.'
                    : 'You have finalized details.'}
                </Alert>
              </Col>
            </>
          )}
        </Row>
      )}

      {step === 3 && (
        <Row className="d-flex justify-content-between" noGutters>
          <Col md={6} xl={5}>
            <SponsorshipPreview sponsorship={sponsorship} />
          </Col>

          <Col className="schedule-section" md={6}>
            <ScheduleSection sponsorship={sponsorship} setSponsorship={setSponsorship} />
          </Col>
        </Row>
      )}

      {step === 4 && (
        <Row className="d-flex justify-content-between" noGutters>
          <Col md={6} xl={5}>
            <SponsorshipPreview sponsorship={sponsorship} />
          </Col>

          {user.role === 'advertiser' && sponsorship.status === 'scheduled' && (
            <Col className="launch-section" sm={6}>
              <LaunchSection sponsorship={sponsorship} setStep={setStep} />

              <AuthorizationGate roles="advertiser, admin, account_manager">
                <Alert variant="info" className="pixel-msg">
                  <FontAwesomeIcon icon="chart-line" size="lg" className="mr-2" />
                  Don't forget to set up your tracking pixel.{' '}
                  <span onClick={() => setPixelSponsorship(sponsorship)} className="pixel">
                    Click here to get started
                  </span>
                </Alert>
              </AuthorizationGate>
            </Col>
          )}

          {user.role !== 'advertiser' && sponsorship.status === 'scheduled' && (
            <Col className="payment" sm={6}>
              <Alert variant="warning">
                <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
                Waiting for advertiser to complete the payment.
              </Alert>

              <img
                src={successPayment}
                srcSet={`${successPayment} 1x, ${successPaymentRetina} 2x`}
                width="300"
                alt="Success Payment"
              />
            </Col>
          )}

          {sponsorship.status === 'launched' && (
            <Col className="payment" sm={6}>
              {!isLaunched && (
                <Alert variant="success">
                  <Alert.Heading>Congratulations!</Alert.Heading>
                  <FontAwesomeIcon icon="handshake" size="lg" className="mr-2" />
                  The terms and payment were confirmed.
                </Alert>
              )}

              {isLaunched ? (
                <Alert variant="success">
                  <Alert.Heading>Sponsorship is officially launched!</Alert.Heading>
                  <FontAwesomeIcon icon="rocket" size="lg" className="mr-2" />
                  Keep an eye on the{' '}
                  <Link className="go-to" to={`/reporting/${sponsorship.id}/details`}>
                    Reporting page
                  </Link>{' '}
                  to view the sponsorship performance.
                </Alert>
              ) : (
                <Alert variant="success">
                  <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
                  Sponsorship is ready for launch and will be live on{' '}
                  <strong>{sponsorship.start_date}</strong>.
                </Alert>
              )}

              <AuthorizationGate roles="advertiser, admin, account_manager">
                <Alert variant="info" className="pixel-msg">
                  <FontAwesomeIcon icon="chart-line" size="lg" className="mr-2" />
                  Don't forget to set up your tracking pixel.{' '}
                  <span onClick={() => setPixelSponsorship(sponsorship)} className="pixel">
                    Click here to get started
                  </span>
                </Alert>
              </AuthorizationGate>

              <img
                src={successLaunch}
                srcSet={`${successLaunch} 1x, ${successLaunchRetina} 2x`}
                width="300"
                alt="Success"
              />
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
};

Steps.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setSponsorship: PropTypes.func.isRequired,
};

export default Steps;
