import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import get from 'lodash/get';

import AuthorizationGate from 'components/AuthorizationGate';
import usePaginatedData from 'hooks/usePaginatedData';
import { HasResults } from 'contexts/HasResults';
import { PixelDetailsContextProvider } from 'contexts/PixelDetailsContext';
import { fetchResources } from 'utils/api';
import SponsorshipForm from './Form';
import EditSponsorship from './Edit';
import SponsorshipsList from './SponsorshipsList';
import SponsorshipDetails from './SponsorshipDetails';
import 'styles/SponsorshipsPage.scss';
import 'styles/Form.scss';

const SponsorshipsPage = () => {
  const [status, setStatus] = useState('opportunity');
  const [sponsorshipsCount, setSponsorshipsCount] = useState({});
  const [filterByCampaign, setFilterByCampaign] = useState(true);
  const { setSponsorshipsHasResults } = useContext(HasResults);
  const matchNew = useRouteMatch('/sponsorships/new');
  const matchEdit = useRouteMatch('/sponsorships/:id/edit');
  const matchDetails = useRouteMatch('/sponsorships/:id/details');
  const location = useLocation();
  const campaignId = get(location, 'state.campaign_id');

  const titles = [
    { title: 'Opportunities', value: 'opportunity' },
    { title: 'In Development', value: 'in_development' },
    { title: 'Scheduled', value: 'scheduled' },
    { title: 'Launched', value: 'launched' },
  ];

  const { data: sponsorships, setData: setSponsorships, hasResults, ...rest } = usePaginatedData({
    endpoint: '/api/v1/sponsorships',
    defaultQuery: {
      status,
    },
    effectEnabled: false,
  });

  useEffect(() => {
    const query = campaignId && filterByCampaign ? { campaign_id: campaignId } : {};

    fetchResources({
      endpoint: 'sponsorships/count_by_status',
      query,
    }).then(sponsorships => {
      setSponsorshipsCount(sponsorships);
    });
  }, [sponsorships.length, campaignId, filterByCampaign]);

  useEffect(() => {
    setSponsorshipsHasResults(hasResults);
  }, [hasResults, setSponsorshipsHasResults]);

  return (
    <Container>
      {!matchEdit && !matchNew && !matchDetails && hasResults && (
        <ul className="header-links sponsorships-links">
          {titles.map((title, index) => (
            <li
              key={index}
              onClick={() => setStatus(title.value)}
              className={status === title.value ? 'active' : ''}
            >
              {title.title}
              {get(sponsorshipsCount, title.value, 0) > 0 && (
                <Badge
                  variant="success"
                  className="notif-badge"
                  style={{ marginLeft: '5px', position: 'relative' }}
                >
                  {sponsorshipsCount[title.value]}
                </Badge>
              )}
            </li>
          ))}
        </ul>
      )}

      <Row noGutters>
        <Switch>
          <Route path="/sponsorships" exact>
            <Col className="main-body">
              <SponsorshipsList
                sponsorships={sponsorships}
                setSponsorships={setSponsorships}
                status={status}
                hasResults={hasResults}
                setStatus={setStatus}
                filterByCampaign={filterByCampaign}
                setFilterByCampaign={setFilterByCampaign}
                {...rest}
              />
            </Col>
          </Route>

          <Route path="/sponsorships/:id/details">
            <Col>
              <PixelDetailsContextProvider>
                <SponsorshipDetails />
              </PixelDetailsContextProvider>
            </Col>
          </Route>
        </Switch>
      </Row>

      <AuthorizationGate roles="admin, account_manager">
        <Switch>
          <Route path="/sponsorships/new" exact>
            <Row noGutters>
              <Col className="main-body" xs={12} md={8} lg={6}>
                <SponsorshipForm />
              </Col>
            </Row>
          </Route>

          <Route path="/sponsorships/:id/edit" exact>
            <Row noGutters>
              <Col className="main-body" xs={12} md={8} lg={6}>
                <EditSponsorship />
              </Col>
            </Row>
          </Route>
        </Switch>
      </AuthorizationGate>
    </Container>
  );
};

export default SponsorshipsPage;
