import React, { createContext, useState } from 'react';

export const ItineraryContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const ItineraryContextProvider = ({ children, defaultStep }) => {
  const [step, setStep] = useState(defaultStep);

  return (
    <ItineraryContext.Provider value={{ setStep, step }}>{children}</ItineraryContext.Provider>
  );
};
