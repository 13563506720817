import React from 'react';
import { AuthenticatedRoutes, Routes } from 'Routes';
import AuthenticationGate from 'components/AuthenticationGate';
import ScrollToTop from 'components/ScrollToTop';
import Header from 'components/Header';
import HasResultsProvider from 'contexts/HasResults';
import { NotificationsContextProvider } from 'contexts/NotificationsContext';

const App = () => (
  <ScrollToTop>
    <AuthenticationGate>
      <HasResultsProvider>
        <NotificationsContextProvider>
          <Header />
          <AuthenticatedRoutes />
        </NotificationsContextProvider>
      </HasResultsProvider>
    </AuthenticationGate>

    <Routes />
  </ScrollToTop>
);

export default App;
