import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const CallToAction = ({ title, subtitle, imgSrc, retinaImg, alt, hasButton, btnTitle, path }) => {
  const history = useHistory();

  return (
    <Row noGutters>
      <Col className="main-body">
        <div className="call-to-action">
          <img src={imgSrc} srcSet={`${imgSrc} 1x, ${retinaImg} 2x`} alt={alt} />

          <h4>{title}</h4>

          {hasButton && (
            <Button variant="success" onClick={() => history.push(path)} size="lg">
              {btnTitle}
            </Button>
          )}

          {subtitle && <span className="subtitle py-3 px-5">{subtitle}</span>}
        </div>
      </Col>
    </Row>
  );
};

CallToAction.defaultProps = {
  subtitle: '',
  hasButton: false,
  btnTitle: '',
  path: '',
};

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  hasButton: PropTypes.bool,
  btnTitle: PropTypes.string,
  path: PropTypes.string,
  retinaImg: PropTypes.string.isRequired,
};

export default CallToAction;
