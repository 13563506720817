export const getInitials = name => {
  const parts = name.split(' ');
  let initials = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials.toUpperCase();
};

export const getStatusColor = status => {
  if (status === 'in_development') {
    return 'primary';
  }
  if (status === 'scheduled') {
    return 'warning';
  }
  if (status === 'cancelled') {
    return 'danger';
  }

  return 'success';
};

export const getRoleColor = role => {
  if (role === 'podcaster') {
    return 'success';
  }

  if (role === 'account_manager' || role === 'admin') {
    return 'primary';
  }

  return 'secondary';
};
