import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useListenNotesGenres from 'hooks/useListeNotesGenres';
import 'styles/PodcastDetails.scss';

const PodcastDetails = ({ podcast, setPodcast }) => {
  const genres = useListenNotesGenres();

  if (!podcast) {
    return <div className="podcast-details" />;
  }

  const filteredGenres = genres.filter(genre => podcast.genre_ids.includes(genre.id));

  return (
    <div className="podcast-details opened">
      <h4 className="text-center mt-4">{podcast.title_original || podcast.title}</h4>

      <span className="close-icon" onClick={() => setPodcast(null)}>
        <FontAwesomeIcon icon="times" />
      </span>

      <div className="podcast-info">
        <img src={podcast.thumbnail} alt="Podcast thumbnail" />

        {(podcast.description || podcast.description_original) && (
          <div className="description">
            <h5>Description:</h5>
            <p className="description-field">
              {podcast.description || podcast.description_original}
            </p>
          </div>
        )}
      </div>

      {podcast.earliest_pub_date_ms && (
        <div className="date">
          <h5>Earliest published at:</h5>
          <span>{new Date(podcast.earliest_pub_date_ms).toLocaleDateString('en-US')}</span>
        </div>
      )}

      {podcast.latest_pub_date_ms && (
        <div className="date">
          <h5>Latest published at:</h5>
          <span>{new Date(podcast.latest_pub_date_ms).toLocaleDateString('en-US')}</span>
        </div>
      )}

      {podcast.total_episodes && (
        <div className="total-episodes">
          <h5>Total episodes:</h5>

          <Badge pill variant="success">
            {podcast.total_episodes}
          </Badge>
        </div>
      )}

      {filteredGenres.length > 0 && (
        <div className="genres">
          <h5>Genres:</h5>

          {filteredGenres.map(genre => (
            <Badge key={genre.id} variant="success">
              {genre.name}
            </Badge>
          ))}
        </div>
      )}

      {podcast.website && (
        <div className="website">
          <h5>Website:</h5>
          <a href={podcast.website} className="ml-2" target="_blank" rel="nofollow">
            Click here to go to the website
          </a>
        </div>
      )}
    </div>
  );
};

PodcastDetails.propTypes = {
  podcast: PropTypes.object,
  setPodcast: PropTypes.func.isRequired,
};

PodcastDetails.defaultProps = {
  podcast: null,
};

export default PodcastDetails;
