import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MobileOnly } from 'utils/responsive';
import ChatSidebar from './conversations/ChatSidebar';

const MenuMsg = ({ ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MobileOnly>
      <div>
        <FontAwesomeIcon
          icon="chevron-left"
          onClick={handleClickOpen}
          style={{ marginRight: '20px' }}
          size="lg"
        />

        <div id="menuMsg" className={isOpen ? 'opened' : 'closed'}>
          <ChatSidebar {...rest} handleClickOpen={handleClickOpen} />
        </div>
      </div>
    </MobileOnly>
  );
};

export default MenuMsg;
