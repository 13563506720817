import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash/get';

import { fetchResource } from 'utils/api';
import toast from 'utils/toast.jsx';
import successPayment from 'images/success_payment.png';
import successPaymentRetina from 'images/success_payment@2x.png';
import Loader from 'components/Loader';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const LaunchSection = ({ sponsorship, setStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    fetchResource({ endpoint: `sponsorships/${sponsorship.id}/client_secret` }).then(id =>
      setClientSecret(id)
    );
  }, [sponsorship.id]);

  const handleSubmit = async e => {
    setSubmitting(true);

    e.preventDefault();
    e.stopPropagation();

    if (!stripe) {
      setSubmitting(false);
      return;
    }

    const billingDetails = {};
    const podcasterName = get(sponsorship, 'podcaster.name');

    if (podcasterName) {
      billingDetails.name = podcasterName;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });

    setSubmitting(false);

    if (result.error) {
      toast('warn', result.error.message);
    } else if (result.paymentIntent.status === 'succeeded') {
      toast(
        'success',
        'Successfully processed your payment! The sponsorship will move to launched shortly.'
      );
      setStep(4);
      setTimeout(() => setShowForm(false), 1500);
    }
  };

  return (
    <>
      {showForm ? (
        <>
          <Alert variant="info" className="alert-content">
            Complete the payment to launch the sponsorship.
          </Alert>

          <form onSubmit={handleSubmit} noValidate>
            <CardElement className="form-control" options={CARD_ELEMENT_OPTIONS} />

            <Button
              disabled={submitting}
              variant="success"
              size="md"
              type="submit"
              style={{ marginTop: 30 }}
              block
            >
              {submitting && (
                <Loader
                  size="1x"
                  style={{ margin: '0 20px 0 0', color: '#fff', display: 'inline-block' }}
                />
              )}
              Complete payment and launch!
            </Button>
          </form>

          <hr />
        </>
      ) : (
        <>
          {sponsorship.status !== 'launched' && (
            <div className="payment">
              <Alert variant="warning">
                <FontAwesomeIcon icon="hourglass-half" size="lg" className="mr-2" />
                Waiting for the payment to be processed.
              </Alert>

              <img
                src={successPayment}
                srcSet={`${successPayment} 1x, ${successPaymentRetina} 2x`}
                width="300"
                alt="Success Payment"
                className="mb-2"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

LaunchSection.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default LaunchSection;
