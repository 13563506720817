import { useState, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { fetchResources } from 'utils/api';

const useListenNotesTypeahead = defaultSelectedGenres => {
  const [podcastResults, setPodcastResults] = useState([]);
  const [podcastsFocused, setPodcastsFocused] = useState(false);
  const [selectedPodcasts, setSelectedPodcasts] = useState([]);
  const [genreResults, setGenreResults] = useState([]);
  const [genresFocused, setGenresFocused] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState(defaultSelectedGenres);
  const [startDateFocused, setStartDateFocused] = useState(false);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const searchPodcastsRef = useRef();
  const searchGenresRef = useRef();

  const getPodcasts = useCallback(
    (overrideQuery = {}) => {
      setLoading(true);

      const q = searchPodcastsRef.current.value;
      const query = {
        q,
        show_podcasts: 1,
        endpoint: q.length === 0 ? 'best_podcasts' : 'search',
        type: 'podcast',
        ...overrideQuery,
      };

      fetchResources({
        type: 'listen_notes',
        query,
      })
        .then(response => {
          const { results, podcasts } = response;

          setResponse(response);
          setPodcastResults(results || podcasts);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [searchPodcastsRef]
  );

  const getGenres = () => {
    const q = searchGenresRef.current.value;

    const query = {
      q,
      show_genres: 1,
      endpoint: 'typeahead',
    };

    fetchResources({
      type: 'listen_notes',
      query,
    }).then(({ genres }) => {
      setGenreResults(genres);
    });
  };

  const debouncedGetPodcasts = debounce(getPodcasts, 300);
  const debouncedGetGenres = useCallback(debounce(getGenres, 300));

  const selectPodcast = podcast => {
    setSelectedPodcasts(prevPodcasts =>
      prevPodcasts.find(p => p.id === podcast.id) ? prevPodcasts : [...prevPodcasts, podcast]
    );
  };

  const removePodcast = podcast => {
    setSelectedPodcasts(state => {
      return state.filter(value => value !== podcast);
    });
  };

  const selectGenre = genre => {
    setSelectedGenres(prevGenres =>
      prevGenres.find(g => g.id === genre.id) ? prevGenres : [...prevGenres, genre]
    );
  };

  const removeGenre = genre => {
    setSelectedGenres(state => {
      return state.filter(value => value !== genre);
    });
  };

  return {
    response,
    podcastResults,
    podcastsFocused,
    setPodcastsFocused,
    genresFocused,
    setGenresFocused,
    selectedGenres,
    startDateFocused,
    setStartDateFocused,
    debouncedGetPodcasts,
    debouncedGetGenres,
    removePodcast,
    removeGenre,
    selectPodcast,
    selectGenre,
    searchPodcastsRef,
    searchGenresRef,
    genreResults,
    selectedPodcasts,
    setSelectedPodcasts,
    getGenres,
    getPodcasts,
    loading,
  };
};

export default useListenNotesTypeahead;
