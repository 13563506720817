import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import useAuthRedirect from 'hooks/useAuthRedirect';
import { Tablet, MobileOnly } from 'utils/responsive';
import logo from 'images/logo.png';
import retinaLogo from 'images/logo@2x.png';
import PodcasterForm from './PodcasterForm';
import AdvertiserForm from './AdvertiserForm';
import BackgroundImage from './BackgroundImage';
import 'styles/FormContainer.scss';

const SignUpPage = () => {
  useAuthRedirect();

  return (
    <Container>
      <Row noGutters>
        <Col>
          <MobileOnly>
            <div className="mobile-logo">
              <img src={logo} srcSet={`${logo} 1x, ${retinaLogo} 2x`} alt="Sponsored Logo" />
            </div>
          </MobileOnly>
        </Col>
      </Row>

      <Row className="row-container" noGutters>
        <Col className="form-container main-body" md={6} xl={5}>
          <div className="title">
            <h2>Don't have an account yet? Sign Up!</h2>

            <span>Sign up to find Podcast Sponsorship opportunities</span>
          </div>

          <Switch>
            <Route path="/sign_up" component={AdvertiserForm} exact />
            <Route path="/sign_up/podcaster" component={PodcasterForm} exact />
          </Switch>
        </Col>

        <Tablet>
          <BackgroundImage />
        </Tablet>
      </Row>
    </Container>
  );
};

export default SignUpPage;
