import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import startCase from 'lodash/startCase';

import Loader from 'components/Loader';
import usePaginatedData from 'hooks/usePaginatedData';
import { getRoleColor, getInitials } from 'utils/conversations';
import PaginationFooter from 'components/PaginationFooter';

const Users = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);

  const { data, isLoading, ...rest } = usePaginatedData({
    endpoint: '/api/v1/users',
  });

  useEffect(() => {
    setUsers(data);
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className="users-page">
      <Row noGutters>
        <Col className="main-body">
          {users.length > 0 && (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Avatar</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Role</th>
                    <th className="text-center">Confirmed?</th>
                  </tr>
                </thead>

                <tbody className="table-body">
                  {users.map(user => (
                    <tr
                      className="table-row users-row"
                      key={user.id}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (user.conversation_id) {
                          history.push(`/conversations/${user.conversation_id}`);
                        }
                      }}
                    >
                      <td>
                        {user.avatar_url ? (
                          <Image className="avatar" src={user.avatar_url} roundedCircle />
                        ) : (
                          <div className="initials-avatar">{getInitials(user.name)}</div>
                        )}
                      </td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        <Badge variant={getRoleColor(user.role)}>{startCase(user.role)}</Badge>
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={user.confirmed ? 'check' : 'times'}
                          size="lg"
                          style={{ color: user.confirmed ? '#007a87' : '#000' }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationFooter data={users} title="Users" {...rest} />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
