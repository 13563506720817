import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import Button from 'react-bootstrap/Button';

import { getPageViewPixel, getConversionPixel } from 'utils/analytics';
import { AuthContext } from 'contexts/index';

const PixelDetails = ({ setSponsorship }) => {
  const { user } = useContext(AuthContext);
  const [copied, setCopied] = useState({});
  const pageViewPixel = getPageViewPixel(user.id);
  const conversionPixel = getConversionPixel(user.id);

  return (
    <div className={setSponsorship ? 'pixel-details opened' : 'pixel-details'}>
      {setSponsorship && (
        <div className="header d-flex justify-content-between">
          <h5 className="mb-0">Pixel Details</h5>

          <span onClick={() => setSponsorship(null)} className="close-icon">
            <FontAwesomeIcon icon="times" />
          </span>
        </div>
      )}

      <Row>
        <Col sm={setSponsorship ? 12 : 6} className="page-view">
          <h5>Page View Pixel:</h5>
          <p>
            This is the page view tracking pixel. Copy the code below by clicking the button and add
            it before the closing <code>head</code> tag of your advertising landing page.
          </p>

          <CopyToClipboard
            text={pageViewPixel}
            onCopy={() => {
              setCopied(prev => ({ ...prev, pageView: true }));
            }}
          >
            <Button variant="success" size="sm" type="submit" style={{ marginTop: 10 }} block>
              <FontAwesomeIcon icon="copy" style={{ marginRight: 5 }} />
              <span>{get(copied, 'pageView', false) ? 'Copied' : 'Click to copy'}</span>
            </Button>
          </CopyToClipboard>

          <SyntaxHighlighter language="html">{pageViewPixel}</SyntaxHighlighter>
        </Col>

        <Col sm={setSponsorship ? 12 : 6} className="conversion-pixel">
          <h5 className={setSponsorship ? 'mt-4' : 'mt-0'}>Conversion Pixel:</h5>

          <p>
            This is the conversion tracking pixel. Copy the code below by clicking the button and
            add it before the closing <code>head</code> tag of your conversion/thank you page.
          </p>

          <CopyToClipboard
            text={conversionPixel}
            onCopy={() => {
              setCopied(prev => ({ ...prev, conversion: true }));
            }}
          >
            <Button variant="success" size="sm" type="submit" block>
              <FontAwesomeIcon icon="copy" style={{ marginRight: 5 }} />
              <span>{get(copied, 'conversion', false) ? 'Copied' : 'Click to copy'}</span>
            </Button>
          </CopyToClipboard>

          <SyntaxHighlighter language="html">{conversionPixel}</SyntaxHighlighter>
        </Col>
      </Row>
    </div>
  );
};

PixelDetails.propTypes = {
  setSponsorship: PropTypes.func,
};

PixelDetails.defaultProps = {
  setSponsorship: null,
};

export default PixelDetails;
