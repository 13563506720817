import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import isEmpty from 'lodash/isEmpty';
import Alert from 'react-bootstrap/Alert';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer } from 'react-toastify';

import Sidebar from 'components/Sidebar';
import ActionCableProvider from 'components/ActionCable/Provider';
import icons from 'utils/icons';
import { stripeKey } from 'utils/constants';
import withTracker from 'utils/withTracker';
import { AuthContext } from 'contexts/';
import { Desktop } from 'utils/responsive';
import { PodcastDetailsContextProvider } from './contexts/PodcastDetailsContext';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'styles/custom.scss';
import 'styles/main.scss';

const stripePromise = loadStripe(stripeKey);
library.add(fab, icons);
dom.watch();

const history = createBrowserHistory();

const Root = ({ currentUser }) => {
  const [user, setCurrentUser] = useState(currentUser);
  const isSignedIn = !isEmpty(user);

  return (
    <Elements stripe={stripePromise}>
      <AuthContext.Provider value={{ user, setCurrentUser }}>
        <ActionCableProvider>
          <ToastContainer />
          <PodcastDetailsContextProvider>
            <Router history={history}>
              {isSignedIn && (
                <Desktop>
                  <Sidebar />
                </Desktop>
              )}

              <main className={isSignedIn ? 'moved' : ''}>
                {isSignedIn && !user.confirmed && (
                  <Alert variant="warning" className="text-center mb-0">
                    Please check your email in order to confirm your account.
                  </Alert>
                )}

                <Route component={withTracker(App)} />
              </main>
            </Router>
          </PodcastDetailsContextProvider>
        </ActionCableProvider>
      </AuthContext.Provider>
    </Elements>
  );
};

Root.propTypes = {
  currentUser: PropTypes.object,
};

Root.defaultProps = {
  currentUser: {},
};

export default Root;
