/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

export default (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
