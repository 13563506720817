import React, { createContext, useState } from 'react';

import PixelDetails from 'components/sponsorships/PixelDetails';
import 'styles/PodcastDetails.scss';

export const PixelDetailsContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const PixelDetailsContextProvider = ({ children }) => {
  const [sponsorship, setSponsorship] = useState(null);

  return (
    <PixelDetailsContext.Provider value={{ setSponsorship }}>
      {children}

      {sponsorship && <PixelDetails sponsorship={sponsorship} setSponsorship={setSponsorship} />}
    </PixelDetailsContext.Provider>
  );
};
