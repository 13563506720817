import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';

import { TabletAndUp, MobileOnly } from 'utils/responsive';
import ScrollToTop from 'components/ScrollToTop';

const PaginationFooter = ({
  data,
  page,
  setPage,
  perPage,
  setPerPage,
  totalCount,
  perPageOptions,
  title,
  smallPagination,
}) => {
  const pageCount = Math.ceil(totalCount / perPage);
  const from = (page - 1) * perPage + 1;
  const to = page * perPage > totalCount ? totalCount : page * perPage;

  const handlePerPageChange = perPage => {
    setPerPage(perPage);
    setPage(1);
  };

  const pages = useMemo(() => {
    const count = pageCount === 0 ? 1 : pageCount;
    const pages = Array.from(new Array(count), (_, i) => i + 1);

    if (page < 3) {
      return pages.slice(0, 5);
    }

    if (pageCount - page < 3) {
      return pages.slice(-5);
    }

    return pages.slice(page - 3, page + 2);
  }, [page, pageCount]);

  const pagesMobile = useMemo(() => {
    const count = pageCount === 0 ? 1 : pageCount;
    const pages = Array.from(new Array(count), (_, i) => i + 1);

    if (page < 3) {
      return pages.slice(0, 3);
    }

    return pages.slice(page - 2, page + 1);
  }, [page, pageCount]);

  const goToPage = currentPage => {
    setPage(currentPage);
  };

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToLastPage = () => {
    setPage(pageCount);
  };

  const goToNextPage = () => {
    if (page !== pageCount) {
      setPage(page + 1);
    }
  };

  const goToPrevPage = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {data.length > 0 && totalCount > perPageOptions[0] && (
        <div className="pagination-field">
          <Dropdown className={smallPagination ? 'small-pagination' : ''}>
            <span>Per page:</span>

            <Dropdown.Toggle
              className={perPageOptions.length === 1 ? 'move-chevron' : ''}
              variant="success"
            >
              {perPage}
            </Dropdown.Toggle>

            {perPageOptions.length > 1 && (
              <Dropdown.Menu>
                {perPageOptions.map(option => (
                  <Dropdown.Item onSelect={() => handlePerPageChange(option)} key={option}>
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Dropdown>

          <div className="right-pagination">
            <div className={smallPagination ? 'text-right title' : 'title'}>
              {title}
              <span className="text-success font-weight-bold m-1">
                {from} - {to}
              </span>
              from <span className="text-success font-weight-bold m-1">{totalCount}</span>
            </div>

            {!smallPagination && (
              <Pagination>
                <Pagination.First onClick={goToFirstPage} disabled={page === 1} />
                <Pagination.Prev onClick={goToPrevPage} disabled={page === 1} />

                {!smallPagination && (
                  <TabletAndUp>
                    {pages.map(currentPage => (
                      <Pagination.Item
                        key={currentPage}
                        onClick={() => goToPage(currentPage)}
                        active={currentPage === page}
                      >
                        <ScrollToTop>{currentPage}</ScrollToTop>
                      </Pagination.Item>
                    ))}
                  </TabletAndUp>
                )}

                <MobileOnly>
                  {pagesMobile.map(currentPage => (
                    <Pagination.Item
                      key={currentPage}
                      onClick={() => goToPage(currentPage)}
                      active={currentPage === page}
                    >
                      <ScrollToTop>{currentPage}</ScrollToTop>
                    </Pagination.Item>
                  ))}
                </MobileOnly>

                <Pagination.Next onClick={goToNextPage} disabled={page === pageCount} />
                <Pagination.Last onClick={goToLastPage} disabled={page === pageCount} />
              </Pagination>
            )}

            {smallPagination && (
              <Pagination className="ml-auto">
                <Pagination.Prev onClick={goToPrevPage} disabled={page === 1} />

                {pagesMobile.map(currentPage => (
                  <Pagination.Item
                    key={currentPage}
                    onClick={() => goToPage(currentPage)}
                    active={currentPage === page}
                  >
                    <ScrollToTop>{currentPage}</ScrollToTop>
                  </Pagination.Item>
                ))}

                <Pagination.Next onClick={goToNextPage} disabled={page === pageCount} />
              </Pagination>
            )}
          </div>
        </div>
      )}
    </>
  );
};

PaginationFooter.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  perPageOptions: PropTypes.array,
  smallPagination: PropTypes.bool,
};

PaginationFooter.defaultProps = {
  perPageOptions: [15, 25, 50],
  smallPagination: false,
};

export default PaginationFooter;
