import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  BarChart,
  Bar,
} from 'recharts';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Chart } from 'react-google-charts';

import useListenNotesGenres from 'hooks/useListeNotesGenres';
import { PodcastDetailsContext } from 'contexts/PodcastDetailsContext';
import { fetchResource } from 'utils/api';
import { formatPrice } from 'utils/forms';
import { googleMapsKey } from 'utils/constants';
import 'styles/SponsorshipReports.scss';
import Loader from 'components/Loader';

const SponsorshipReport = () => {
  const [sponsorship, setSponsorship] = useState(null);
  const [podcast, setSelectedPodcast] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [group, setGroup] = useState('Day');
  const [chartType, setChartType] = useState('line');
  const { setPodcast } = useContext(PodcastDetailsContext);
  const { id } = useParams();
  const genres = useListenNotesGenres();

  useEffect(() => {
    fetchResource({
      type: 'sponsorships',
      id,
      query: { metrics: true, charts: true },
    }).then(sponsorship => {
      const id = sponsorship.podcast_id;

      fetchResource({
        type: 'podcasts',
        id,
      }).then(podcast => {
        setSelectedPodcast(podcast);
        setSponsorship(sponsorship);
        setChartData(sponsorship.chart_data);
      });
    });
  }, [id, setSponsorship, setSelectedPodcast]);

  useEffect(() => {
    fetchResource({
      endpoint: `sponsorships/${id}/chart_data`,
      query: { group: group.toLowerCase() },
    }).then(data => {
      setChartData(data);
    });
  }, [id, group, setGroup]);

  const generateCardData = sponsorship => {
    const visits = get(sponsorship, 'total_metrics.pageView', 0);
    const conversions = get(sponsorship, 'total_metrics.conversion', 0);
    let conversionRate = 0;

    if (conversions > 0 && visits > 0) {
      const rate = (conversions / visits) * 100;
      conversionRate = rate.toFixed(2);
    }

    const costPerVisit = visits === 0 ? 0 : sponsorship.price / visits;
    const costPerConversion = conversions === 0 ? 0 : sponsorship.price / conversions;

    return { visits, conversions, conversionRate, costPerVisit, costPerConversion };
  };

  if (!sponsorship || !podcast) {
    return <Loader />;
  }

  const { visits, conversions, conversionRate, costPerVisit, costPerConversion } = generateCardData(
    sponsorship
  );

  const genreIds = get(podcast, 'podcast.genre_ids', []);
  const filteredGenres = genres.filter(genre => genreIds.includes(genre.id));

  return (
    <Container className="sponsorship-reports sponsorship-details-page">
      <Row className="header pl-0 pr-0 mb-5" noGutters>
        <div className="details">
          <div className="img-field">
            <img
              src={podcast.podcast.thumbnail}
              onClick={() => setPodcast(podcast.podcast)}
              alt="Podcast thumbnail"
            />
            <span onClick={() => setPodcast(podcast.podcast)}>Learn more</span>
          </div>

          <div className="title">
            <span className="font-weight-bold">{sponsorship.name}</span>

            <div className="status">
              <Badge variant="success">{startCase(sponsorship.status)}</Badge>
            </div>

            <div className="podcast-name">
              Podcast: <span>{podcast.podcast.title}</span>
            </div>
          </div>
        </div>

        {filteredGenres.length > 0 && (
          <div className="genres">
            Genres:
            <div>
              {filteredGenres.map(genre => (
                <Badge key={genre.id} variant="success">
                  {genre.name}
                </Badge>
              ))}
            </div>
          </div>
        )}

        <div className="price">
          <span className="mr-1">Price:</span>
          <strong>{formatPrice(sponsorship.price)}</strong>
        </div>
      </Row>

      <Row>
        <Col sm={6}>
          <Card>
            <Card.Body>
              <Card.Title>Total Visits</Card.Title>
              <Card.Text>{visits}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6}>
          <Card>
            <Card.Body>
              <Card.Title>Total Conversions</Card.Title>
              <Card.Text>{conversions}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="row second-row">
        <Col sm={4}>
          <Card>
            <Card.Body>
              <Card.Title>Cost per visit (CPV)</Card.Title>
              <Card.Text>{formatPrice(costPerVisit)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={4}>
          <Card>
            <Card.Body>
              <Card.Title>Conversion Rate</Card.Title>
              <Card.Text>{conversionRate}%</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={4}>
          <Card>
            <Card.Body>
              <Card.Title>Cost per conversion (CPC)</Card.Title>
              <Card.Text>{formatPrice(costPerConversion)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 30,
            }}
          >
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary">{group}</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="Day" onSelect={setGroup}>
                  Day
                </Dropdown.Item>
                <Dropdown.Item eventKey="Week" onSelect={setGroup}>
                  Week
                </Dropdown.Item>
                <Dropdown.Item eventKey="Month" onSelect={setGroup}>
                  Month
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div>
              Line
              <Form.Check
                custom
                type="switch"
                id="custom-switch"
                label="Bar"
                style={{ marginLeft: 25, display: 'inline-block' }}
                checked={chartType === 'bar'}
                onChange={() => setChartType(type => (type === 'line' ? 'bar' : 'line'))}
              />
            </div>
          </div>

          <ResponsiveContainer width="100%" height={300}>
            {chartType === 'line' ? (
              <LineChart
                width={1200}
                height={300}
                data={chartData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Line type="monotone" dataKey="Page View" stroke="#ff5a5f" />
                <Line type="monotone" dataKey="Conversion" stroke="#007a87" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
              </LineChart>
            ) : (
              <BarChart
                width={1200}
                height={300}
                data={chartData}
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Page View" fill="#ff5a5f" />
                <Bar dataKey="Conversion" fill="#007a87" />
              </BarChart>
            )}
          </ResponsiveContainer>
        </Col>
      </Row>

      <hr className="mt-5 mb-5" />

      <Row>
        <Col sm={12}>
          <h3 className="text-center" style={{ marginBottom: 40 }}>
            Visits per country
          </h3>

          <Chart
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={sponsorship.geo_data}
            mapsApiKey={googleMapsKey}
            options={{
              colorAxis: { colors: ['#d3d6e2', '#007a87'] },
              backgroundColor: '#fff',
              datalessRegionColor: '#f1f2f6',
              defaultColor: '#f1f2f6',
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SponsorshipReport;
